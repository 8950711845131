import { useState } from 'react';
import { keyBy } from 'lodash';
import { Link, useLoaderData } from 'react-router-dom';
import { Layer, Source } from '@redzone/map';
import { PolygonCollector, Dropdown } from '@redzone/shared';
import { CloseButtonIcon } from '../../../../../icons/CloseButtonIcon.jsx';
import './NewAssignment.scss';
import { queryLoader } from '../../../../../../hooks/use-query.js';

const newAssignmentQuery = {
  type: 'organizations',
  properties: {
    id: 'id',
    dispatch: 'dispatch',
    displayName: 'displayName',
    engines: {
      properties: {
        id: 'id',
        engineName: 'engineName',
      },
    },
    policyLayers: {
      properties: {
        layerId: 'layerId',
        mapboxLayer: 'mapboxLayer',
        mapboxSource: 'mapboxSource',
      },
    },
  },
  // filters: { dispatch: true },
};

export async function loader() {
  return queryLoader(newAssignmentQuery);
}

export function NewAssignment() {
  const rawOrganizations = useLoaderData();
  const organizations = rawOrganizations.filter((o) => o.dispatch);
  const [assignment, setAssignment] = useState({});

  const organizationsById = keyBy(organizations, (o) => o.id);
  const organization =
    assignment.organization && organizationsById[assignment.organization];
  const organizationPolicyLayers = assignment.organization
    ? organizationsById[assignment.organization].policyLayers
    : [];

  const policyLayerIds = organizationPolicyLayers.map((l) => l.layerId);

  const handleAssignmentAreaChange = (polygon, features) => {
    setAssignment({
      ...assignment,
      assignmentArea: polygon,
      policies: features,
    });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    alert('here is where the API will be called and the assignment created');
  };

  return (
    <>
      <div className="new-assignment scroll">
        <header>
          <h2>Create New Assignment</h2>
          <Link className="standard" to="../">
            <CloseButtonIcon />
          </Link>
        </header>

        <form className="standard" onSubmit={handleSubmit}>
          <div className="input-row">
            <fieldset>
              <legend>Organization</legend>

              <Dropdown
                hasBlank
                options={organizations.map((org) => ({
                  value: org.id,
                  label: org.displayName,
                }))}
                onChange={(item) => {
                  setAssignment({ organization: item });
                }}
                value={assignment.organization}
              />
            </fieldset>
          </div>

          {assignment.organization && (
            <div className="input-row">
              <fieldset>
                <legend>Engine</legend>
                <Dropdown
                  hasBlank
                  value={assignment.engine}
                  onChange={(e) => {
                    setAssignment({ ...assignment, engine: e });
                  }}
                  options={organization.engines.map((e) => ({
                    value: e.id,
                    label: e.engineName,
                  }))}
                />
              </fieldset>
            </div>
          )}

          {assignment.organization && (
            <div className="input-row">
              <fieldset>
                <legend>Assignment Area</legend>
                <PolygonCollector
                  key={assignment.organization} // force a re-render on org change
                  id="new-assignment-polygon-collector"
                  value={assignment.assignmentArea}
                  onChange={handleAssignmentAreaChange}
                  featureLayers={policyLayerIds}
                />
              </fieldset>
            </div>
          )}

          {assignment.assignmentArea && (
            <div className="policies-count">
              Selected Policies: {assignment.policies.length}
            </div>
          )}

          <button
            className="standard standard-border"
            type="submit"
            disabled={
              !assignment.assignmentArea ||
              assignment.policies?.length === 0 ||
              !assignment.engine
            }
          >
            Submit
          </button>
        </form>
      </div>

      {organizationPolicyLayers.map((pl) => (
        <Source key={pl.layerId} {...pl.mapboxSource}>
          <Layer id={pl.layerId} mapboxLayer={pl.mapboxLayer} />
        </Source>
      ))}
    </>
  );
}
