import { useRef, useState } from 'react';

export function useStateList(init, uniquenessFn) {
  const [items, setItems] = useState(init ?? []);
  const itemsRef = useRef();

  itemsRef.current = items;

  const eqItem = uniquenessFn
    ? (item) =>
        itemsRef.current.find(
          (curItem) => uniquenessFn(item) === uniquenessFn(curItem),
        )
    : (item) => itemsRef.current.find((curItem) => item === curItem);

  const add = (item) => {
    if (!eqItem(item)) {
      const nextItems = [...itemsRef.current, item];
      itemsRef.current = nextItems;
      setItems(nextItems);
    }
  };

  const clear = () => {
    const nextItems = [];
    itemsRef.current = nextItems;
    setItems(nextItems);
  };

  const remove = (item) => {
    const itemToRemove = eqItem(item);
    if (itemToRemove) {
      const nextItems = itemsRef.current.filter(
        (curItem) => curItem !== itemToRemove,
      );
      itemsRef.current = nextItems;
      setItems(nextItems);
    }
  };

  const toggle = (item) => {
    if (eqItem(item)) {
      remove(item);
    } else {
      add(item);
    }
  };

  return [items, { add, clear, remove, set: setItems, toggle }];
}
