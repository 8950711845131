import { Children, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { renderToString } from 'react-dom/server';
import { useMapEffect } from '../hooks/use-map-effect.js';

export function Popup(props) {
  const { coordinates, children, onClose } = props;
  const popup = useRef();

  useMapEffect(
    (map) => {
      if (popup.current) {
        popup.current.setHTML(renderToString(children));
      } else {
        popup.current = new mapboxgl.Popup().setHTML(renderToString(children));

        if (onClose) {
          popup.current.on('close', (e) => {
            onClose(e, map);
          });
        }
      }

      const attachEvents = (element) => {
        if (!(typeof element === 'object')) return;

        const { props: elementProps } = element;

        if (elementProps.onClick) {
          if (!elementProps.id) {
            throw new Error(
              'popup elements with an `onClick` handler must have a unique id',
            );
          }

          const elt = document.getElementById(elementProps.id);

          if (elt) {
            elt.addEventListener('click', elementProps.onClick);
          }
        }

        if (children) {
          Children.toArray(elementProps.children).forEach(attachEvents);
        }
      };

      setTimeout(() => Children.toArray(children).forEach(attachEvents), 0);
    },
    () => {},
    [coordinates, children],
  );

  useMapEffect(
    (map) => {
      popup.current.setLngLat(coordinates).addTo(map);
    },
    () => {},
    [coordinates],
  );

  useEffect(
    () => () => {
      popup.current.remove();
      popup.current = null;
    },
    [],
  );

  return null;
}
