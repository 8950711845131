import { round } from './utils.js';

const mph = new Intl.NumberFormat('en', {
  style: 'unit',
  unit: 'mile-per-hour',
});

const latLngFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

export const schemaTypeFormatters = {};

export const schemaFormatFormatters = {
  point: (value) =>
    `[${value.coordinates.map(latLngFormatter.format).join(', ')}]`,
};

export const schemaFormatters = {
  hurricanes: {
    latnow: (value) => latLngFormatter.format(round(Number(value), 3)),
    lonnow: (value) => latLngFormatter.format(round(Number(value), 3)),
    windnow: (value) => mph.format(Math.round(Number(value))),
  },
};
