import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useMap } from '@redzone/map';
import { RouteTabs, Tab } from '../../../shared/Tabs.jsx';
import { getExposureCssColors } from '../../../../utils/exposure-colors.js';
import { queryLoader } from '../../../../hooks/use-query.js';
import { useDashboard } from '../../../../context/DashboardContext.jsx';
import '../Peril.scss';

const query = {
  type: 'earthquakes',
  id: { $var: 'id' },
  properties: {
    incidentId: 'incidentId',
    incidentName: 'incidentName',
    magnitude: 'magnitude',
    occurredAt: 'occurredAt',
    lastScrape: 'lastScrape',
    tsunami: 'tsunami',
    shindo: 'shindo',
    latitude: 'latitude',
    longitude: 'longitude',
    portfolioExposures: {
      properties: {
        id: 'id',
        rating: 'rating',
        policyExposures: {
          properties: {
            policy: {
              properties: {
                tiv: 'tiv',
                id: 'id',
              },
            },
          },
        },
      },
    },
  },
};

export async function loader({ params }) {
  const vars = { id: params.perilId };
  return queryLoader(query, { vars });
}

export function Earthquake() {
  const earthquakeData = useLoaderData();
  const { addPerilTab } = useDashboard();
  const { map } = useMap();

  const earthquake = {
    ...earthquakeData,
  };

  const rating = earthquake.portfolioExposures.reduce(
    (max, e) => Math.max(max, e.rating ?? 0),
    0,
  );

  useEffect(() => {
    const { longitude, latitude } = earthquake ?? {};
    const offset = [200, 0];

    if (latitude && longitude && map) {
      map.flyTo({ center: [longitude, latitude], offset, zoom: 9 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earthquake.incidentId, map]);

  useEffect(() => {
    addPerilTab({ path: 'earthquakes', peril: earthquake, exposure: rating });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [earthquake.incidentId]);

  return (
    <div
      className="peril-details earthquake-details drawer"
      style={rating > 0 ? getExposureCssColors(rating) : {}}
    >
      <header>
        <h1 className="title earthquake-name">{earthquake.incidentName}</h1>
      </header>

      <RouteTabs className="dark-theme" context={{ earthquake }}>
        <Tab label="Feed" path="feed" />
        <Tab label="Overview" path="overview" />
        <Tab label="Metrics" path="metrics" />
      </RouteTabs>
    </div>
  );
}
