import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import './Drawer.scss';

export function Drawer(props) {
  const { children, className, hasToggleButton, open, position, setOpen } =
    props;
  const toggleDirections = {
    open: position,
    closed: position === 'left' ? 'right' : 'left',
  };

  return (
    <section
      className={`drawer-container light-theme ${position} ${
        open ? 'open' : 'closed'
      }${className ? ` ${className}` : ''}`}
    >
      {hasToggleButton && (
        <button
          className="standard drawer-toggle-button"
          onClick={() => setOpen(!open)}
          type="button"
          aria-label={open ? 'close left drawer' : 'open left drawer'}
        >
          <ChevronIcon
            orientation={open ? toggleDirections.open : toggleDirections.closed}
          />
        </button>
      )}
      <div className="drawer-wrapper">
        <div className="drawer">{children}</div>
      </div>
    </section>
  );
}

Drawer.defaultProps = {
  loading: false,
  error: false,
  children: [],
};
