import React, { useState } from 'react';
import { Portal } from './Portal.jsx';
import './HoverTooltip.scss';

export function HoverTooltip(props) {
  const { children, title } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    left: 0,
    top: 0,
  });

  const toggleTooltip = (e) => {
    const rect = e.target.getBoundingClientRect();

    setTooltipPosition({
      left: rect.x + rect.width / 2,
      top: rect.y + window.scrollY,
    });

    setShowTooltip((prev) => !prev);
  };

  return (
    <div
      className="hover-tooltip"
      onMouseEnter={(e) => toggleTooltip(e)}
      onMouseLeave={(e) => toggleTooltip(e)}
    >
      <div className="tooltip-title">{title}</div>
      {showTooltip && (
        <Portal theme="light-theme">
          <div
            className="tooltip-body-wrapper"
            style={{
              left: tooltipPosition.left,
              top: tooltipPosition.top,
            }}
          >
            <div className="tooltip-body">{children}</div>
          </div>
        </Portal>
      )}
    </div>
  );
}
