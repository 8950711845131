import './RulerIcon.scss';

export function RulerIcon() {
  return (
    <svg
      className="ruler-icon"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Alert-Dashboard">
        <polygon id="Bounds" points="0 0 24 0 24 24 0 24" fill="none" />
        <path
          d="M21,6 L3,6 C1.9,6 1,6.9 1,8 L1,16 C1,17.1 1.9,18 3,18 L21,18 C22.1,18 23,17.1 23,16 L23,8 C23,6.9 22.1,6 21,6 L21,6 Z M21,16 L3,16 L3,8 L5,8 L5,12 L7,12 L7,8 L9,8 L9,12 L11,12 L11,8 L13,8 L13,12 L15,12 L15,8 L17,8 L17,12 L19,12 L19,8 L21,8 L21,16 L21,16 Z"
          id="Shape"
          // fill="#FFFFFF"
        />
      </g>
    </svg>
  );
}
