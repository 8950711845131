// the triangle will have a base of 1 and a height of √3/2
const b = 1;
const h = 3 ** 0.5 / 2; // √3/2 -- the correct height for an equilateral triangle

// this is the size of the gap between triangles
const gap = 0.25;
const halfGap = gap / 2.0;

const trianglePts = [
  [-b / 2, halfGap],
  [b / 2, halfGap],
  [0, halfGap + h],
];

// working with a view box with ±100 bounds
const toSvgPoint = ([x, y]) => [x, -y];

const upArrow = trianglePts.map((p) => toSvgPoint(p).join(',')).join(' ');
const downArrow = trianglePts
  .map(([x, y]) => toSvgPoint([x, -y]).join(','))
  .join(' ');

export function SortArrowsIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`-1 ${-(halfGap + h)} ${b * 2} ${(halfGap + h) * 2}`}
    >
      <polygon className="arrow up-arrow" points={upArrow} />
      <polygon className="arrow down-arrow" points={downArrow} />
    </svg>
  );
}
