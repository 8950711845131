/*
  MMI 
  mmi is how we determine the intensity of an earthquake.
  feels like the current way we do metrics does not really work.

  if we rethink how we want to show that data is:
  we want to take MMI level V and up. (https://www.usgs.gov/programs/earthquake-hazards/modified-mercalli-intensity-scale#:~:text=The%20effect%20of%20an%20earthquake,%2C%20and%20finally%20%2D%20total%20destruction.)

  so we want to get data that wil give us policy count and tiv in each of the MMI level.
  we also want the data split per org.
*/
export const EARTHQUAKE_METRICS = {
  aig: [
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 2,
      assetType: 'com',
      tiv: 448989988,
      policyCount: 1896,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 2,
      assetType: 'ho',
      tiv: 1657786912,
      policyCount: 9151,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 3,
      assetType: 'com',
      tiv: 9073051858,
      policyCount: 27854,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 3,
      assetType: 'ho',
      tiv: 26161266634,
      policyCount: 139912,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 4,
      assetType: 'com',
      tiv: 36036600809,
      policyCount: 135766,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 4,
      assetType: 'ho',
      tiv: 107686174320,
      policyCount: 818792,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 5,
      assetType: 'com',
      tiv: 6051756817,
      policyCount: 24804,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 5,
      assetType: 'ho',
      tiv: 25975594980,
      policyCount: 195946,
    },
  ],
  lex: [
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 2,
      assetType: 'com',
      tiv: 0,
      policyCount: 0,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 2,
      assetType: 'ho',
      tiv: 1657786912,
      policyCount: 9151,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 3,
      assetType: 'com',
      tiv: 9073051858,
      policyCount: 27854,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 3,
      assetType: 'ho',
      tiv: 26161266634,
      policyCount: 139912,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 4,
      assetType: 'com',
      tiv: 36036600809,
      policyCount: 135766,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 4,
      assetType: 'ho',
      tiv: 107686174320,
      policyCount: 818792,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 5,
      assetType: 'com',
      tiv: 6051756817,
      policyCount: 24804,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 5,
      assetType: 'ho',
      tiv: 25975594980,
      policyCount: 195946,
    },
  ],
  pcg: [
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 2,
      assetType: 'com',
      tiv: 3,
      policyCount: 9,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 2,
      assetType: 'ho',
      tiv: 1657786912,
      policyCount: 9151,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 3,
      assetType: 'com',
      tiv: 9073051858,
      policyCount: 27854,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 3,
      assetType: 'ho',
      tiv: 26161266634,
      policyCount: 139912,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 4,
      assetType: 'com',
      tiv: 36036600809,
      policyCount: 135766,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 4,
      assetType: 'ho',
      tiv: 107686174320,
      policyCount: 818792,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 5,
      assetType: 'com',
      tiv: 6051756817,
      policyCount: 24804,
    },
    {
      incidentId: 'us6000fsl6',
      geomType: 'mmi',
      category: 5,
      assetType: 'ho',
      tiv: 25975594980,
      policyCount: 195946,
    },
  ],
};
