const random = (min, max) => Math.random() * (min + max) + min;
const randomInt = (min, max) => Math.floor(Math.random() * (min + max)) + min;

const nTimes = (times, fn) => {
  const out = [];
  for (let i = 0; i < times; i += 1) {
    out.push(fn(i));
  }
  return out;
};

export const logTimes = (exp, fn) => {
  const seed = Math.random();
  const times = Math.floor(10 ** (seed * exp));

  return nTimes(times, fn);
};

export const rangeTimes = ({ min, max }, fn) => {
  const seed = Math.random();
  const times = Math.floor(seed * (max + 1 - min)) + min;

  return nTimes(times, fn);
};

function hslToRgb(hue, saturation, lightness) {
  const h = (hue < 0 ? 360 - hue : hue) % 360;
  const a = saturation * Math.min(lightness, 1 - lightness);
  const f = (n) => {
    const k = (n + h / 30) % 12;
    return lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  };

  return [f(0), f(8), f(4)];
}

// a wannabe hashing function that'll do for this
const weakHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) * i + ((hash << 5) - hash); // eslint-disable-line no-bitwise
    hash &= hash; // eslint-disable-line no-bitwise
  }
  return Math.abs(hash);
};

export function getColorFromString(str) {
  const hash = weakHash(str);
  const hue = hash % 360;
  const [lightnessMin, lightnessMax] = [0.3, 0.65];
  const lightness =
    ((hash / 360) % Math.abs(lightnessMin - lightnessMax)) + lightnessMin;
  const rgb = hslToRgb(hue, 1, lightness);

  const hexStr = rgb
    .map((n) => Math.floor(n * 255))
    .map((n) => (n > 15 ? n.toString(16) : `0${n.toString(16)}`))
    .join('');
  return `#${hexStr}`;
}

// generates a random polygon near the point of interest
const KMS_PER_DEGREE = 111.139; // rough estimate from a quick search
export function nearbyPolygon(coordinates, options = {}) {
  const [baseLng, baseLat] = coordinates;
  const { maxDistance = 50 } = options;
  const degreeMaxDistance = maxDistance / KMS_PER_DEGREE;

  const initialVertexBearing = random(0, Math.PI * 2);
  const initialVertexMagnitude = Math.random() * degreeMaxDistance;
  const initialVertex = [
    baseLng + initialVertexMagnitude * Math.cos(initialVertexBearing),
    baseLat + initialVertexMagnitude * Math.sin(initialVertexBearing),
  ];
  const initialBearing = random(0, Math.PI * 2);

  const numVertices = randomInt(3, 12);
  const regularVertexAngle = ((numVertices - 2) * 2 * Math.PI) / numVertices;
  const regularVertexMagnitude = random(0.0001, degreeMaxDistance * 0.1);

  const init = {
    lastVertex: initialVertex,
    lastBearing: initialBearing,
    vertices: [initialVertex],
  };
  const { vertices } = Array(numVertices - 1)
    .fill(null)
    .reduce((acc) => {
      const { lastVertex, lastBearing, vertices } = acc;
      const nextBearing = lastBearing + regularVertexAngle;
      const nextVertex = [
        lastVertex[0] + Math.cos(nextBearing) * regularVertexMagnitude,
        lastVertex[1] + Math.sin(nextBearing) * regularVertexMagnitude,
      ];

      return {
        lastVertex: nextVertex,
        lastBearing: nextBearing,
        vertices: [...vertices, nextVertex],
      };
    }, init);

  return {
    type: 'Polygon',
    coordinates: [[...vertices, vertices[0]]],
  };
}
