import { HistoricalFiresPerimeterPopup } from '../popups/HistoricalFiresPerimeterPopup.jsx';

const colors = [
  `rgb(255, 191, 191)`,
  `rgb(254, 128, 128)`,
  `rgb(254, 64, 64)`,
  `rgb(254, 2, 0)`,
];
export const historicalFiresPerimeterLayer = {
  id: 'historical_fires_perimeter',
  layerId: 'historical_fires_perimeter',
  label: 'Historical Fire Perimeters',
  source: {
    id: 'redzonesoftware.historical_fires_perimeter',
    type: 'vector',
    url: 'mapbox://redzonesoftware.historical_fires_perimeter',
  },
  mapboxLayer: {
    id: 'redzonesoftware.historical_fires_perimeter',
    type: 'fill',
    source: 'redzonesoftware.historical_fires_perimeter',
    'source-layer': 'fire-perimeter',
    filter: [
      'any',
      ['>', 'FIRE_YEAR_INT', 1973],
      // TODO: verify with Clark about filter small fires
      ['>', 'GIS_ACRES', 100],
    ],
    paint: {
      'fill-color': [
        'case',
        ['>', 2000, ['get', 'FIRE_YEAR_INT']],
        colors[0],

        ['>', 2010, ['get', 'FIRE_YEAR_INT']],
        colors[1],

        ['>', 2020, ['get', 'FIRE_YEAR_INT']],
        colors[2],
        colors[3],
      ],
      'fill-opacity': 0.7,
      'fill-outline-color': '#292524',
    },
  },
  onClick: (e, fn) =>
    fn('historical_fires_perimeter', {
      center: {
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      features: e.features,
    }),
  popup: HistoricalFiresPerimeterPopup,
};
