import { useState } from 'react';
import { BarChart, XAxis, YAxis, Bar } from 'recharts';
import { Dropdown, HoverTooltip } from '@redzone/shared';
import {
  localizeNumber,
  shortCompactLocalizeNumber,
} from '../../utils/formatter.js';
import './PerilMetrics.scss';

// Note(Baad): are these dynamic?
const availableProperties = [
  { value: 'policyCount', label: 'Count' },
  { value: 'tiv', label: 'TIV' },
];

const stringArrayToOptions = (arr) => arr.map((i) => ({ value: i, label: i }));

export function PerilMetrics(props) {
  const { data, tableClasses } = props;
  const organizations = Object.keys(data);
  const [selectedOrganization, setSelectedOrganization] = useState(
    organizations[0],
  );
  const assets = [
    ...new Set(data[selectedOrganization].map((item) => item.assetType)),
  ];
  const [selectedAsset, setSeletedAsset] = useState(assets[0]);

  const geomTypes = [
    ...new Set(data[selectedOrganization].map((item) => item.geomType)),
  ];

  const [selectedGeomType, setSelectedGeomType] = useState(geomTypes[0]);
  const [selectedProperty, setSelectedProperty] = useState(
    availableProperties[0],
  );

  const filteredMetricsData = data[selectedOrganization]
    .filter((entry) => entry.assetType === selectedAsset)
    .filter((entry) => entry.geomType === selectedGeomType);

  return (
    <div className="peril-metrics scrollable">
      <div className="metrics-select">
        <Dropdown
          namespace="organization"
          header="Organization"
          options={stringArrayToOptions(organizations)}
          value={selectedOrganization}
          onChange={(item) => setSelectedOrganization(item)}
          withBorder
        />
        <Dropdown
          namespace="assets"
          header="Assets"
          options={stringArrayToOptions(assets)}
          value={selectedAsset}
          onChange={(item) => setSeletedAsset(item)}
          withBorder
        />
        <Dropdown
          namespace="geomtype"
          header="Geom Type"
          options={stringArrayToOptions(geomTypes)}
          value={selectedGeomType}
          onChange={(item) => setSelectedGeomType(item)}
          withBorder
          shouldFloat
        />
      </div>

      <BarChart
        width={500}
        height={250}
        data={filteredMetricsData}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <XAxis
          dataKey="category"
          label={{
            value: 'Category',
            position: 'insideBottom',
            offset: 0,
            fill: 'rgb(74 74 74)',
          }}
        />
        <YAxis tickFormatter={(value) => shortCompactLocalizeNumber(value)} />
        <HoverTooltip formatter={(value) => localizeNumber(value)} />
        <Bar dataKey={selectedProperty.value} fill="#203A8E" />
      </BarChart>

      <div className="metrics-properties">
        {availableProperties.map((prop) => (
          <button
            className={`standard metrics-property-button  ${
              selectedProperty.value === prop.value && 'active'
            }`}
            key={prop.value}
            type="button"
            onClick={() => setSelectedProperty(prop)}
          >
            {prop.label}
          </button>
        ))}
      </div>

      <table className="standard metrics-table">
        <thead>
          <tr>
            <td>Category</td>
            <td>Count</td>
            <td>TIV</td>
          </tr>
        </thead>
        <tbody>
          {filteredMetricsData.map((entry) => (
            <tr key={entry.category}>
              <td className={`${tableClasses} color-mmi-${entry.category}`}>
                {entry.category}
              </td>
              <td>{localizeNumber(entry.policyCount)}</td>
              <td>${localizeNumber(entry.tiv)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
