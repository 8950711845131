import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useMapEffect } from '../hooks/use-map-effect.js';
import { useInternalMap } from '../contexts/MapContext.jsx';

const SourceContext = createContext();

export function useSource() {
  return useContext(SourceContext);
}

export function Source(props) {
  const {
    children,
    featureStates = {},
    icons = [],
    id,
    passive,
    ...rest
  } = props;
  const { data } = props;

  if (!id) {
    throw new Error('each source must have an id');
  }

  const [currentFeatureStates, setCurrentFeatureStates] = useState({});
  const { registerSource, deregisterSource, scheduleRefresh } =
    useInternalMap();
  const registeredLayers = useRef({});

  const registerLayer = (layerId, layer) => {
    registeredLayers.current[layerId] = { source: id, ...layer, id: layerId };
    scheduleRefresh();
  };

  const deregisterLayer = (layerId) => {
    delete registeredLayers.current[layerId];
    scheduleRefresh();
  };

  useEffect(() => {
    registerSource(id, !passive, {
      icons,
      mapboxSource: rest,
      layers: registeredLayers.current,
    });
    return () => {
      deregisterSource(id, !passive);
    };
  }, []);

  useMapEffect(
    (map) => {
      const source = map.getSource(id);
      if (source && data) {
        source.setData(data);
      }
    },
    [data],
  );

  useMapEffect(
    (map) => {
      const nextFeatureStates = { ...currentFeatureStates };

      Object.entries(featureStates).forEach(([state, featureIds]) => {
        const existing = currentFeatureStates[state] ?? [];

        existing.forEach((featureId) => {
          map.setFeatureState(
            { source: id, id: featureId },
            { [state]: false },
          );
        });

        featureIds.forEach((featureId) => {
          map.setFeatureState({ source: id, id: featureId }, { [state]: true });
        });

        nextFeatureStates[state] = featureIds;
      });

      setCurrentFeatureStates(nextFeatureStates);
    },
    [JSON.stringify(featureStates)],
  );

  const value = useMemo(() => ({
    registerLayer,
    deregisterLayer,
    updateLayer: registerLayer,
  }));

  return (
    <SourceContext.Provider value={value}>{children}</SourceContext.Provider>
  );
}
