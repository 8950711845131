import { useState, useEffect } from 'react';
import { Layer, Map as RedzoneMap, Source } from '@redzone/map';
import { groupBy, mapValues, pick } from 'lodash';
import { useDashboardState } from '../../context/DashboardContext.jsx';
import { pipeThru } from '../../utils.js';
import { ImportedLayers } from './ImportedLayers.jsx';
import './Map.scss';
import { PolicyLayers } from './PolicyLayers.jsx';
import { PUBLIC_LAYERS } from './public-layers.js';
import { popupComponents } from './popups/popup-components.js';
import { redFlagWarningMockData } from '../../../fixtures/redflag.data.js';

const dataByLayerId = {
  /* Q(Baad):
    This Data will have to be fetched from the API.
    Wodering where is the right place to fetch it and map it to the right layer.
   */
  red_flag_warning: redFlagWarningMockData,
};

export function Map() {
  const [selectedLayers] = useDashboardState('selectedLayers');
  const [popup, setPopup] = useState(null);
  const publicLayers = pipeThru(PUBLIC_LAYERS, [
    (layers) => pick(layers, selectedLayers.public),
    (layers) =>
      mapValues(layers, (layer) => ({
        ...layer,
        mapboxSource: layer.source.mapboxSource,
      })),
    (layers) => groupBy(Object.values(layers), (layer) => layer.source.id),
  ]);

  const handleLayerClick = (layerId, ev) => {
    const popupComponent = popupComponents[layerId];
    const { features, sourceType } = ev;

    if (popupComponent && features?.length > 0) {
      setPopup({
        componentProps: {
          features,
          center: ev.center,
          sourceType,
          data: dataByLayerId[layerId],
        },
        component: popupComponent,
        layerId,
      });
    }
  };

  useEffect(() => {
    const layerIds = Object.keys(publicLayers);
    if (!layerIds.includes(popup?.layerId)) {
      setPopup(null);
    }
  }, [JSON.stringify(publicLayers)]);

  return (
    <div className="map-container">
      <RedzoneMap />
      <PolicyLayers />
      <ImportedLayers />
      {Object.entries(publicLayers).map(([sourceId, layers]) => (
        <Source key={sourceId} id={sourceId} passive {...layers[0].source}>
          {layers.map((layer) => (
            <Layer
              key={layer.id}
              id={layer.id}
              filter={
                typeof layer.filter === 'function'
                  ? layer.filter(dataByLayerId[layer.id])
                  : layer.filters
              }
              mapboxLayer={
                typeof layer.mapboxLayer === 'function'
                  ? layer.mapboxLayer()
                  : layer.mapboxLayer
              }
              onClick={(ev) => {
                if (!layer.onClick) return;
                layer.onClick(ev, handleLayerClick);
              }}
            />
          ))}
        </Source>
      ))}
      {popup ? (
        <popup.component {...popup.componentProps} setPopup={setPopup} />
      ) : null}
    </div>
  );
}
