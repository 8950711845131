import { useEffect, useState } from 'react';
import { Popup } from '@redzone/map';
import { shortCompactLocalizeNumber } from '../../utils/formatter.js';

export function HistoricalFiresPerimeterPopup(props) {
  const { features, center } = props;
  const [selectedFireIdx, setSelectedFireIdx] = useState(0);
  const selectedFire = features[selectedFireIdx];

  useEffect(() => {
    setSelectedFireIdx(0);
  }, [features]);

  if (!features || !selectedFire) return null;

  return (
    <Popup coordinates={center.coordinates}>
      <div className="popup standard">
        <h3>
          {features.length > 1 && (
            <>
              <button
                type="button"
                className="prev-button"
                aria-label="previous fire"
                id="prev-fire-button"
                onClick={() => {
                  setSelectedFireIdx(
                    selectedFireIdx === 0
                      ? features.length - 1
                      : selectedFireIdx - 1,
                  );
                }}
              >
                &lt;
              </button>
              <div className="title">
                Fire {selectedFireIdx + 1} of {features.length}
              </div>
              <button
                type="button"
                className="next-button"
                aria-label="next fire"
                id="next-fire-button"
                onClick={() => {
                  setSelectedFireIdx((selectedFireIdx + 1) % features.length);
                }}
              >
                &gt;
              </button>
            </>
          )}
        </h3>
        <table>
          <tbody>
            <tr>
              <td className="label">Incident Name</td>
              <td>{selectedFire.properties.INCIDENT}</td>
            </tr>
            <tr>
              <td className="label">Incident Year</td>
              <td>{selectedFire.properties.FIRE_YEAR_INT}</td>
            </tr>
            <tr>
              <td className="label">Acres</td>
              <td>
                {!selectedFire.properties.GIS_ACRES ??
                Number.isNaN(selectedFire.properties.GIS_ACRES)
                  ? 'N/A'
                  : shortCompactLocalizeNumber(
                      selectedFire.properties.GIS_ACRES,
                    )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Popup>
  );
}
