export const MAP_STYLES = {
  standard: {
    name: 'layers.standard',
    label: 'Standard',
    layer: 'streets-v12',
    url: 'mapbox://styles/mapbox/streets-v12',
  },
  standardFlat: {
    name: 'layers.standard',
    label: 'Standard Flat',
    layer: 'streets-v11',
    url: 'mapbox://styles/mapbox/streets-v11',
  },
  satellite: {
    name: 'layers.satellite',
    label: 'Satellite',
    layer: 'satellite-v9',
    url: 'mapbox://styles/mapbox/satellite-v9',
  },
  hybrid: {
    name: 'layers.hybrid',
    label: 'Hybrid',
    layer: 'satellite-streets-v11',
    url: 'mapbox://styles/mapbox/satellite-streets-v11',
  },
  light: {
    name: 'layers.light',
    label: 'Light',
    layer: 'light-v10',
    url: 'mapbox://styles/mapbox/light-v10',
  },
};

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';
