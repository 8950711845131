import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  useRouteError,
} from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { RedwallLayout } from '@redzone/shared';
import { Dashboard } from '../dashboard/Dashboard.jsx';
import { Fire, loader as fireLoader } from '../dashboard/perils/fires/Fire.jsx';
import {
  FireList,
  loader as fireListLoader,
} from '../dashboard/perils/fires/FireList.jsx';
// Hide history tab for now
// import {
//   FireHistory,
//   loader as fireHistoryLoader,
// } from '../dashboard/perils/fires/tabs/FireHistory.jsx';
import { FireOverview } from '../dashboard/perils/fires/tabs/FireOverview.jsx';
import { FireExposure } from '../dashboard/perils/fires/tabs/FireExposure.jsx';
import { FireDownloads } from '../dashboard/perils/fires/tabs/FireDownloads.jsx';
import {
  FireTriage,
  loader as triageLoader,
} from '../dashboard/perils/fires/tabs/FireTriage.jsx';
import { TriageSummary } from '../dashboard/perils/fires/tabs/triage/TriageSummary.jsx';
import {
  TriageAssignments,
  loader as triageAssignmentsLoader,
} from '../dashboard/perils/fires/tabs/triage/TriageAssignments.jsx';
import {
  TriagePolicies,
  loader as triagePoliciesLoader,
} from '../dashboard/perils/fires/tabs/triage/TriagePolicies.jsx';
import {
  TriageEngines,
  loader as triageEnginesLoader,
} from '../dashboard/perils/fires/tabs/triage/TriageEngines.jsx';
import { App } from './App.jsx';
import { RequireLogin } from './Login.jsx';
import {
  Earthquake,
  loader as earthquakeLoader,
} from '../dashboard/perils/earthquakes/Earthquake.jsx';
import { EarthquakeOverview } from '../dashboard/perils/earthquakes/tabs/EarthquakeOverview.jsx';
import { EarthquakeMetrics } from '../dashboard/perils/earthquakes/tabs/EarthquakeMetrics.jsx';
import { HurricaneOverview } from '../dashboard/perils/hurricanes/tabs/HurricaneOverview.jsx';
import { HurricaneMetrics } from '../dashboard/perils/hurricanes/tabs/HurricaneMetrics.jsx';
import {
  HurricaneList,
  loader as hurricaneListLoader,
} from '../dashboard/perils/hurricanes/HurricaneList.jsx';
import {
  Hurricane,
  loader as hurricaneLoader,
} from '../dashboard/perils/hurricanes/Hurricane.jsx';
import {
  EarthquakeList,
  loader as earthquakeListLoader,
} from '../dashboard/perils/earthquakes/EarthquakeList.jsx';
import {
  FireRoc,
  loader as fireRocLoader,
} from '../dashboard/perils/fires/tabs/FireRoc.jsx';
import { DashboardProvider } from '../../context/DashboardContext.jsx';
import {
  TriageVisitations,
  loader as triageVisitationsLoader,
} from '../dashboard/perils/fires/tabs/triage/TriageVisitations.jsx';
import {
  NewAssignment,
  loader as newAssignmentLoader,
} from '../dashboard/perils/fires/tabs/triage/NewAssignment.jsx';
import { FireExposureSummary } from '../dashboard/perils/fires/tabs/exposure/FireExposureSummary.jsx';
import { FireExposurePolicies } from '../dashboard/perils/fires/tabs/exposure/FireExposurePolicies.jsx';
import { useUser } from '../../context/UserContext.jsx';
import { IconsPreview } from '../icons/IconsPreview.jsx';

function TopError() {
  const error = useRouteError();
  console.error(error);

  return (
    <div>
      <div>Error</div>
      <div>{JSON.stringify(error?.message ?? error)}</div>
    </div>
  );
}

export function AuthenticationGuard({ Component }) {
  if (process.env.REACT_APP_USE_LOCAL_DATA === 'true') return <Component />;

  const AuthedComponent = withAuthenticationRequired(Component);
  return <AuthedComponent />;
}

function PermissionGuard(props) {
  const { permission } = props;
  const { hasPermission } = useUser();
  const firstValidPeril = hasPermission('rz:alertdashboard:fires:view')
    ? 'fires'
    : hasPermission('rz:alertdashboard:earthquakes:view')
    ? 'earthquakes'
    : 'hurricanes';

  return hasPermission(permission) ? (
    <Outlet />
  ) : (
    <Navigate to={`/dashboard/${firstValidPeril}`} replace />
  );
}

const routes = (
  <Route
    path="/"
    element={<AuthenticationGuard Component={App} />}
    errorElement={<TopError />}
  >
    <Route index element={<Navigate to="dashboard" replace />} />
    <Route
      path="dashboard"
      element={
        <RequireLogin>
          <RedwallLayout className="map">
            <DashboardProvider>
              <Dashboard />
            </DashboardProvider>
          </RedwallLayout>
        </RequireLogin>
      }
    >
      <Route index element={<Navigate to="fires" replace />} />
      <Route
        path="fires"
        element={<PermissionGuard permission="rz:alertdashboard:fires:view" />}
        handle={{ perilType: 'fires' }}
      >
        <Route index element={<FireList />} loader={fireListLoader} />
        <Route path=":perilId" element={<Fire />} loader={fireLoader}>
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="overview" element={<FireOverview />} />
          {/* <Route
            path="history"
            element={<FireHistory />}
            loader={fireHistoryLoader}
          /> */}
          <Route path="exposure" element={<FireExposure />}>
            <Route index element={<Navigate to="summary" replace />} />
            <Route path="summary" element={<FireExposureSummary />} />
            <Route path="policies" element={<FireExposurePolicies />} />
          </Route>
          <Route path="roc" element={<FireRoc />} loader={fireRocLoader} />
          <Route path="triage" element={<FireTriage />} loader={triageLoader}>
            <Route index element={<Navigate to="summary" replace />} />
            <Route path="summary" element={<TriageSummary />} />
            <Route path="assignments">
              <Route
                index
                element={<TriageAssignments />}
                loader={triageAssignmentsLoader}
              />
              <Route
                path="new"
                element={<NewAssignment />}
                loader={newAssignmentLoader}
              />
            </Route>
            <Route
              path="policies"
              element={<TriagePolicies />}
              loader={triagePoliciesLoader}
            />
            <Route
              path="visitations"
              element={<TriageVisitations />}
              loader={triageVisitationsLoader}
            />
            <Route
              path="engines"
              element={<TriageEngines />}
              loader={triageEnginesLoader}
            />
            {/* </Route> */}
          </Route>
          <Route path="downloads" element={<FireDownloads />} />
        </Route>
      </Route>
      <Route
        path="earthquakes"
        element={
          <PermissionGuard permission="rz:alertdashboard:earthquakes:view" />
        }
        handle={{ perilType: 'earthquakes' }}
      >
        <Route
          index
          element={<EarthquakeList />}
          loader={earthquakeListLoader}
        />
        <Route
          path=":perilId"
          element={<Earthquake />}
          loader={earthquakeLoader}
        >
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="feed" element={<div>feed</div>} />
          <Route path="overview" element={<EarthquakeOverview />} />
          <Route path="metrics" element={<EarthquakeMetrics />} />
        </Route>
      </Route>
      <Route
        path="hurricanes"
        element={
          <PermissionGuard permission="rz:alertdashboard:hurricanes:view" />
        }
        handle={{ perilType: 'hurricanes' }}
      >
        <Route index element={<HurricaneList />} loader={hurricaneListLoader} />
        <Route path=":perilId" element={<Hurricane />} loader={hurricaneLoader}>
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="feed" element={<div>feed</div>} />
          <Route path="overview" element={<HurricaneOverview />} />
          <Route path="metrics" element={<HurricaneMetrics />} />
        </Route>
      </Route>
    </Route>
    <Route path="*" element={<Navigate to="/dashboard" />} />
    {/* Helper for displaying icons */}
    {process.env.NODE_ENV === 'development' && (
      <Route path="/icons" element={<IconsPreview />} />
    )}
  </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(routes));
