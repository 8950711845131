import { useUser } from '../../../../../../context/UserContext.jsx';
import { FireExposureSummaryMultiOrg } from './FireExposureSummaryMultiOrg.jsx';
import { FireExposureSummarySingleOrg } from './FireExposureSummarySingleOrg.jsx';

export function FireExposureSummary() {
  const { currentUser } = useUser();

  return currentUser.organizations.length > 1 ? (
    <FireExposureSummaryMultiOrg />
  ) : (
    <FireExposureSummarySingleOrg />
  );
}
