import { historicalFiresPerimeterLayer } from '@redzone/shared';
import { FirePointPopup } from './FirePointPopup.jsx';
import { ModisViirsPopup } from './ModisViirsPopup.jsx';
import { RedFlagWarningPopup } from './RedFlagWarningPopup.jsx';

export const popupComponents = {
  wf_point: FirePointPopup,
  historical_fires_perimeter: historicalFiresPerimeterLayer.popup,
  modis_viirs: ModisViirsPopup,
  red_flag_warning: RedFlagWarningPopup,
};
