import { FormattedMessage } from 'react-intl';
import { Link, useLoaderData } from 'react-router-dom';
import { getExposureCssColors } from '../../../../utils/exposure-colors.js';
import { PerilBadges, PerilList } from '../PerilList.jsx';
import { queryLoader } from '../../../../hooks/use-query.js';
import './EarthquakeList.scss';
import { EarthquakeIcon } from '../../../icons/EarthquakeIcon.jsx';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const magnitudeFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const badgeDefinitions = {
  exposed: {
    test: (earthquake) => earthquake.portfolioExposures.some((e) => e.rating > 0),
    text: 'exposed',
  },
  new: {
    test: (eq) => Date.now() - Number(eq.time) < 86400 * 1000,
    text: 'new',
  },
  tsunami: { test: (eq) => eq.tsunami, text: 'tsunami' },
};

const columnDefinitions = {
  incidentId: { value: (earthquake) => earthquake.incidentId },
  incidentName: {
    title: 'Incident Name',
    searchable: true,
    sortable: true,
    value: (earthquake) => earthquake.incidentName,
  },
  lastScrapeTs: {
    title: 'Last Updated',
    dataType: 'date-time',
    sortable: true,
    filterable: true,
    value: (earthquake) => earthquake.lastScrapeTs,
  },
  time: {
    title: 'Time Occurred',
    dataType: 'date-time',
    sortable: true,
    filterable: true,
    value: (earthquake) => earthquake.time,
  },
  mag: {
    title: 'Magnitude',
    dataType: 'number',
    sortable: true,
    filterable: true,
    value: (earthquake) => earthquake.mag,
  },
};

const query = {
  type: 'earthquakes',
  properties: {
    incidentId: 'incidentId',
    incidentName: 'incidentName',
    magnitude: 'magnitude',
    occurredAt: 'occurredAt',
    lastScrape: 'lastScrape',
    tsunami: 'tsunami',
    portfolioExposures: { properties: { rating: 'rating' } },
  },
};

export function loader() {
  return queryLoader(query);
}

export function Earthquake(props) {
  const { peril: earthquake } = props;

  return (
    <li
      key={earthquake.incidentId}
      className="peril earthquake"
      style={
        earthquake.maxExposure > 0
          ? getExposureCssColors(earthquake.maxExposure)
          : {}
      }
    >
      <div className="peril-name-container earthquake-name-container earthquake-row">
        <h1 className="peril-name earthquake-name">
          <Link to={earthquake.incidentId} className="standard">
            {earthquake.incidentName}
          </Link>
        </h1>
        <PerilBadges peril={earthquake} badgeDefinitions={badgeDefinitions} />
      </div>
      <div className="stats">
        <div className="updated">
          <div className="label">
            <FormattedMessage id="dashboard.perils.earthquakes.updated" />{' '}
          </div>
          <div className="data">
            {dateFormatter.format(earthquake.lastScrapeTs)}
          </div>
        </div>
        <div className="magnitude">
          <div className="label">Magnitude</div>
          <div className="data">
            <div className="icon">
              <EarthquakeIcon />
            </div>
            <div>{magnitudeFormatter.format(earthquake.magnitude)}</div>
          </div>
        </div>
      </div>
    </li>
  );
}

export function EarthquakeList() {
  const data = useLoaderData();

  return (
    <PerilList
      badgeDefinitions={badgeDefinitions}
      columnDefinitions={columnDefinitions}
      perilElement={<Earthquake />}
      perilType="earthquake"
      perils={data}
    />
  );
}
