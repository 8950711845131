export function EarthquakeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className="standard"
    >
      <path
        d="m11 3c-4.418278 0-8 3.581722-8 8 0 4.418278 3.581722 8 8 8 4.418278 0 8-3.581722 8-8 0-4.418278-3.581722-8-8-8m3.273438 9.302734l3.501953-1.75.447265.894532-4.496094 2.248046-2.441406-6.509765-.958984 9.583984-3.513672-7.02539-2.554688 1.80664-.578125-.816406 3.507813-2.478516 2.486328 4.97461 1.080962-10.226638c0 0 3.519664 9.299329 3.518648 9.298903"
        className="earthquake-icon"
      />
    </svg>
  );
}
