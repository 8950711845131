import { useOutletContext } from 'react-router-dom';
import {
  DataCollectionTable,
  Column,
} from '../../../../../shared/tables/DataCollectionTable.jsx';

const formatTotalTiv = (totalTiv) =>
  (totalTiv ?? 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export function TriageSummarySingleOrg() {
  const { fire } = useOutletContext();

  const { assignments } = fire;
  const visitations = assignments.flatMap((a) => a.visitations);
  const policies = visitations.map((v) => v.policy);

  const openAssignmentCount = assignments.filter(
    (a) => a.status === 'open',
  ).length;
  const policyCount = policies.length;
  const visitationCount = visitations.length;
  const totalTiv = policies.reduce((acc, p) => acc + p.tiv, 0);

  return (
    <div className="substack scrollable">
      <table className="standard resource-table">
        <tbody>
          <tr>
            <td className="title">Open Assignments</td>
            <td className="value">
              <div className="value">{openAssignmentCount}</div>
            </td>
          </tr>
          <tr>
            <td className="title">Visitations</td>
            <td className="value">{visitationCount}</td>
          </tr>
          <tr>
            <td className="title">Policies</td>
            <td className="value">{policyCount ?? 0}</td>
          </tr>
          <tr>
            <td className="title">Total TIV</td>
            <td className="value">{formatTotalTiv(totalTiv)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
