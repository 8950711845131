import { useState } from 'react';
import { useUser } from '../../context/UserContext.jsx'; // eslint-disable-line
import { StaticImage } from '../shared/StaticImage.jsx';
import './Login.scss';
import { Spinner } from '../icons/Spinner.jsx';

export function Login() {
  const { loading, login } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (ev) => {
    ev.preventDefault();
    login(email, password);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div
      className="login-layout dark-theme basis"
      style={{
        backgroundImage: `url("${process.env.REACT_APP_STATIC_ASSETS}/images/backgrounds/colorado-springs-cropped.jpg")`,
      }}
    >
      <div className="hero-container">
        <StaticImage
          className="redzone-logo"
          src="/images/logos/rz_logo_200_wt.png"
          alt="RedZone Logo"
        />
        <form className="login-form" onSubmit={handleLogin}>
          <div className="form-row">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              value={email}
              onInput={(ev) => setEmail(ev.target.value)}
              required
            />
          </div>

          <div className="form-row">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              value={password}
              onInput={(ev) => setPassword(ev.target.value)}
              required
            />
          </div>

          <div className="form-row">
            <button className="login-button standard" type="submit">
              Login
            </button>
          </div>
        </form>
        <footer className="sample-users">
          <h2>Sample Users</h2>
          <ul>
            <button
              type="button"
              onClick={() => {
                setEmail('analyst@redzone.example.co');
                setPassword('backing-skater-tripod3');
              }}
            >
              analyst@redzone.example.co
            </button>
            <button
              type="button"
              onClick={() => {
                setEmail('multi.org@redzone.example.co');
                setPassword('backing-skater-tripod3');
              }}
            >
              multi.org@redzone.example.co
            </button>
            <button
              type="button"
              onClick={() => {
                setEmail('single.org@redzone.example.co');
                setPassword('backing-skater-tripod3');
              }}
            >
              single.org@redzone.example.co
            </button>
          </ul>
          <p>
            Click the above buttons to autofill the form and try out various
            roles in the application. All emails use the password
            &quot;backing-skater-tripod3&quot;.
          </p>
        </footer>

        <footer>
          <div className="forgot-password">Forgot Your Password?</div>
          <ul className="legalese standard">
            <li>Terms of Service</li>
            <li>Privacy Policy</li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export function RequireLogin(props) {
  const { children } = props;
  const { currentUser } = useUser();

  return currentUser ? children : <Login />;
}
