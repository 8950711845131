import { IntlProvider } from 'react-intl';
import { Outlet, useNavigation } from 'react-router-dom';
import { MapProvider } from '@redzone/map';
import { UserProvider, useUser } from '../../context/UserContext.jsx';
import enLocale from '../../i18n/en.json';
import jaLocale from '../../i18n/ja.json';
import './App.scss';

// TODO: we may wish to move this into users/orgs
export const locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  'en-US';
const language = locale?.split('-')?.[0] ?? 'en';

const localeMessages = {
  en: enLocale,
  ja: jaLocale,
};

const messages = localeMessages[language];

const addAuthToken = (accessToken) => (url) => {
  const isPrivateTarget = url.startsWith('https://cartogram');

  return accessToken && isPrivateTarget
    ? {
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
        url,
      }
    : { url };
};

function AppWithUser() {
  const navigation = useNavigation();
  const { userToken } = useUser();

  return (
    <MapProvider transformRequest={addAuthToken(userToken)}>
      <IntlProvider messages={messages} locale={locale}>
        <div
          className={`app-root${
            navigation.state === 'loading' ? ' loading' : ''
          }`}
        >
          <Outlet />
        </div>
      </IntlProvider>
    </MapProvider>
  );
}

export function App() {
  return (
    <UserProvider>
      <AppWithUser />
    </UserProvider>
  );
}
