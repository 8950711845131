import { useState } from 'react';
import getLength from '@turf/length';
import { FormattedMessage } from 'react-intl';
import { LineCollector, ToolDrawerHeader } from '@redzone/shared';
import './MeasureDistance.scss';

export function MeasureDistance(props) {
  const { close } = props;
  const [geojson, setGeojson] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  const reset = () => {
    setGeojson({
      type: 'FeatureCollection',
      features: [],
    });
  };

  const distance = getLength(geojson);

  return (
    <div>
      <ToolDrawerHeader
        title={<FormattedMessage id="tool_drawers.measure_distance.title" />}
        close={close}
      />
      <div className="measure-distance-wrapper">
        <p>
          Mark at least two points on the map to calculate distance and double
          click to finish.
        </p>
        <p className="distance">{(distance ?? 0).toLocaleString()} Miles</p>
        <div className="buttons">
          <LineCollector
            type="line"
            value={geojson}
            onChange={setGeojson}
            className="redwall secondary"
            showValue
          >
            Begin
          </LineCollector>
          <button className="redwall secondary" type="button" onClick={reset}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}
