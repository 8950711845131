import './ToolDrawerHeader.scss';

export function ToolDrawerHeader(props) {
  const { close, title } = props;

  return (
    <div className="drawer-header standard">
      <h1 className="title">{title}</h1>
      <button className="standard close-button" type="button" onClick={close}>
        <svg viewBox="0 0 24 24">
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </button>
    </div>
  );
}
