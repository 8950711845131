import { Layer, Source } from '@redzone/map';
import { uniq } from 'lodash';
import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { queryLoader } from '../../../../../../hooks/use-query.js';
import { AddIcon } from '../../../../../icons/AddIcon.jsx';
import { DataCollection } from '../../../../../shared/tables/DataCollection.jsx';
import { DataTable } from '../../../../../shared/tables/DataTable.jsx';
import { NewAssignment } from './NewAssignment.jsx';
import './TriageAssignments.scss';

const createdFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const assignmentsQuery = {
  type: 'assignments',
  properties: {
    id: 'id',
    peril: {
      properties: {
        id: 'id',
      },
    },
    createdAt: 'createdAt',
    status: 'status',
    assignmentArea: 'assignmentArea',
    engine: {
      properties: {
        engineName: 'engineName',
        organization: { properties: { id: 'id' } },
      },
    },
  },
  filters: {
    'peril.id': { $var: 'perilId' },
  },
};

export async function loader({ params }) {
  const vars = { perilId: params.perilId };
  return queryLoader(assignmentsQuery, { vars });
}

export function TriageAssignments() {
  const allAssignments = useLoaderData();
  const navigate = useNavigate();
  const [newAssignment, setNewAssignment] = useState(false);
  const [hoveredFeatures, setHoveredFeatures] = useState([]);

  const sourceId = 'triage-assignment-source';
  const layerId = 'triage-assignment-areas';

  const layer = {
    id: layerId,
    mapboxLayer: {
      id: layerId,
      type: 'fill',
      paint: {
        'fill-color': '#0077ff',
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0.3,
        ],
      },
    },
  };

  // TODO: memoizing would be good here
  const source = {
    mapboxSource: {
      id: sourceId,
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: allAssignments.map((a, idx) => ({
          type: 'Feature',
          geometry: a.assignmentArea,
          properties: a,
          id: idx,
        })),
      },
    },
    layers: [layer],
  };

  const columnDefinitions = {
    created: {
      id: 'created',
      title: 'Created',
      dataType: 'date-time',
      filterable: true,
      sortable: true,
      value: (row) => row.createdAt,
      format: (val) => createdFormatter.format(new Date(val)),
    },
    status: {
      id: 'status',
      title: 'Status',
      dataType: 'enum',
      options: ['open', 'closed'],
      filterable: true,
      sortable: true,
      value: (row) => row.status,
    },
    engine: {
      id: 'engine',
      title: 'Engine',
      dataType: 'enum',
      options: uniq(allAssignments.map((row) => row.engine.engineName)),
      filterable: true,
      sortable: true,
      value: (row) => row.engine.engineName,
    },
  };

  if (newAssignment)
    return <NewAssignment setNewAssignment={setNewAssignment} />;

  const hoveredFeatureIdSet = new Set(hoveredFeatures);

  return (
    <DataCollection
      data={allAssignments}
      columns={columnDefinitions}
      className="triage-assignments"
      actions={[
        {
          icon: <AddIcon />,
          label: 'New Assignment',
          handler: () => {
            navigate('./new');
          },
        },
      ]}
    >
      {(assignments) => (
        <>
          <DataTable
            data={assignments}
            columns={columnDefinitions}
            rowProps={(row, idx) => ({
              className: hoveredFeatureIdSet.has(row.id) ? 'hovered' : '',
              onMouseEnter: () => {
                setHoveredFeatures([idx]);
              },
              onMouseLeave: () => {
                setHoveredFeatures([]);
              },
            })}
          />
          <Source
            {...source.mapboxSource}
            featureStates={{ hover: hoveredFeatures }}
          >
            <Layer
              id={layerId}
              mapboxLayer={source.layers[0].mapboxLayer}
              filter={[
                'in',
                ['get', 'id'],
                ['literal', assignments.map((a) => a.id)],
              ]}
              onMouseEnter={(e) => {
                setHoveredFeatures(e.features.map((f) => f.id));
              }}
              onMouseLeave={() => {
                setHoveredFeatures([]);
              }}
            />
          </Source>
        </>
      )}
    </DataCollection>
  );
}
