// this could be expanded to a more general layout that took children
import './Nav.scss';

export function Nav(props) {
  const { children } = props;

  return (
    <div className="nav-container dark-theme">
      {children}
    </div>
  );
}
