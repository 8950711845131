import { FormattedMessage } from 'react-intl';
import { useMap } from '@redzone/map';
import { ToolDrawerHeader } from '@redzone/shared';
import { BaseLayer } from './BaseLayer.jsx';
import './LayerDrawer.scss';
import { MemoryTabs, Tab } from '../../shared/Tabs.jsx';
import { PublicLayers } from './PublicLayers.jsx';
import { PolicyLayers } from './PolicyLayers.jsx';
import { ImportedLayers } from './ImportedLayers.jsx';

export function LayerDrawerInner(props) {
  const {
    baseLayer,
    basemaps,
    close,
    map,
    setBaseLayer,
    selectedPolicyLayers,
    importedLayers,
    importedLayerSetters,
    toggleSelectedPolicyLayer,
  } = props;

  return (
    <div className="layer-drawer">
      <ToolDrawerHeader
        title={<FormattedMessage id="tool_drawers.layer_drawer.title" />}
        close={close}
      />
      <MemoryTabs>
        <Tab
          id="base"
          label={<FormattedMessage id="tool_drawers.layer_drawer.tabs.base" />}
          default
        >
          <div className="drawer-body">
            <BaseLayer
              baseLayer={baseLayer}
              basemaps={basemaps}
              map={map}
              setBaseLayer={setBaseLayer}
            />
          </div>
        </Tab>
        <Tab
          id="policy"
          label={
            <FormattedMessage id="tool_drawers.layer_drawer.tabs.policy" />
          }
        >
          <PolicyLayers
            selectedPolicyLayers={selectedPolicyLayers}
            toggleSelectedPolicyLayer={toggleSelectedPolicyLayer}
          />
        </Tab>
        <Tab
          id="public"
          label={
            <FormattedMessage id="tool_drawers.layer_drawer.tabs.public" />
          }
        >
          <div className="drawer-body">
            <PublicLayers />
          </div>
        </Tab>
        <Tab
          id="imported"
          label={
            <FormattedMessage id="tool_drawers.layer_drawer.tabs.imported" />
          }
        >
          <ImportedLayers
            importedLayers={importedLayers}
            importedLayerSetters={importedLayerSetters}
          />
        </Tab>
      </MemoryTabs>
    </div>
  );
}

export function LayerDrawer(props) {
  const { map } = useMap();
  return map ? <LayerDrawerInner {...props} /> : null;
}

LayerDrawer.defaultProps = {
  basemaps: null,
};
