import { createMemoryStore } from './memory-store.js';
import dashboardSchema from '../dashboard-schema.json';
import publicData from '../../fixtures/public.data.json';

export { dashboardSchema };

export const store = createMemoryStore(dashboardSchema);

const storedUsername = localStorage.getItem('currentUser');

if (storedUsername) {
  const user = publicData.users[storedUsername];
  store.seedOrganizations(user.organizations);
}
