import { useOutletContext } from 'react-router-dom';
import { useUser } from '../../../../../../context/UserContext.jsx';
import { sortByAll } from '../../../../../../utils.js';
import { getExposureCssColors } from '../../../../../../utils/exposure-colors.js';

const listFormatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'disjunction',
});

const formattedTotalTiv = (totalTiv) =>
  (totalTiv ?? 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const formatPolicy = {
  tiv: (t) => formattedTotalTiv(t),
  distance: (d) =>
    Intl.NumberFormat('en', {
      maximumFractionDigits: 2,
    }).format(d),
};

export function FireExposureSummaryMultiOrg() {
  const { currentUser } = useUser();
  const { fire } = useOutletContext();
  const { portfolioExposures } = fire;
  const expandedExposures = portfolioExposures.map((e) => ({
    ...e,
    org: e.organization.id,
    shortName: e.organization.shortName,
    closestDistance: e.policyExposures.reduce(
      (min, p) => Math.min(min, p.distance),
      Infinity,
    ),
    totalTiv: e.policyExposures.reduce((sum, p) => sum + p.policy.tiv, 0),
    policyCount: e.policyExposures.length,
  }));

  const sortedExposures = sortByAll(expandedExposures, [
    (exp) => -exp.rating,
    (exp) => exp.shortName,
  ]);

  const nonZeroExposures = sortedExposures.filter(({ rating }) => rating > 0);
  const nonZeroOrgs = nonZeroExposures.map((e) => e.org);
  const zeroExposures = currentUser.organizations.filter(
    (org) => !nonZeroOrgs.includes(org.id),
  );

  return (
    <div className="fire-exposure-tab peril-exposure-tab substack scroll">
      {nonZeroExposures.length > 0 && (
        <table className="standard">
          <thead>
            <tr>
              <th data-cy="org-header">Organization</th>
              <th className="exposure">Exposure</th>
              <th className="numeric">TIV</th>
              <th className="numeric">Policy Count</th>
              <th className="numeric">Nearest Policy</th>
            </tr>
          </thead>
          <tbody>
            {nonZeroExposures.map(
              ({
                org,
                rating,
                totalTiv,
                closestDistance,
                policyCount,
                shortName,
              }) => (
                <tr
                  key={org}
                  className="exposure-row"
                  style={getExposureCssColors(rating)}
                >
                  <td className="org-name" data-cy="org-name">
                    {shortName}
                  </td>
                  <td className="exposure-score-container">
                    <div className="exposure-score">
                      {rating ? Math.round(rating) : '0'}
                    </div>
                  </td>
                  <td className="currency">{formattedTotalTiv(totalTiv)}</td>
                  <td className="numeric">{policyCount ?? 0}</td>
                  {/* BUG: this is coming back as null from Apollo, not sure if its worth debugging now */}
                  <td className="numeric">
                    {formatPolicy.distance(closestDistance)}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      )}
      {zeroExposures.length > 0 && (
        <div className="zero-exposures">
          <p>
            No exposures for{' '}
            {listFormatter.format(
              [...zeroExposures.map((org) => org.shortName)].sort(),
            )}
            .
          </p>
        </div>
      )}
    </div>
  );
}
