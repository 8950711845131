/* eslint-disable no-bitwise */

export function hashString(str) {
  if (str.length === 0) return 0;

  let hash = 0;
  let chr;

  for (let i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
}
