import React, { useState, useEffect } from 'react';
import '../layer-drawer/ImportedLayers.scss';
import './AddSource.scss';
import { SourcesForm } from './SourcesForm.jsx';

const sourceObj = {
  type: 'Feature',
  properties: '', // fill with form data
  geometry: {
    type: 'Point',
    coordinates: '', // fill with pin data
  },
};

export function EditSource(props) {
  const {
    isEditing,
    setIsEditing,
    editFormValues,
    sources,
    setSources,
    setSelectedTab,
  } = props;
  const [formValues, setFormValues] = useState(editFormValues?.properties);

  const [pin, setPin] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setFormValues(null);
    setPin(null);
    setError(false);
    setLoading(false);
    setIsEditing(false);
    setSelectedTab('trusted');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const preppedSourceObj = {
      ...sourceObj,
      properties: { ...formValues },
      geometry: {
        ...sourceObj.geometry,
        coordinates:
          pin?.features[0]?.geometry?.coordinates ||
          editFormValues.geometry.coordinates,
      },
    };

    setError(false);
    setLoading(true);

    // fake loading for now
    setTimeout(() => {
      setLoading(false);
      setSources({
        ...sources,
        data: {
          ...sources.data,
          features: [
            ...sources.data.features.filter(
              (item) => item.properties.id !== editFormValues.properties.id,
            ),
            preppedSourceObj,
          ],
        },
      }); // uses updateSources from SourcesDrawer.jsx
      setIsEditing(false);
      setSelectedTab('trusted');
    }, 2000);

    // try {
    //   // post the giant json
    //   const response = await axios.post('/api/trusted/sources/data', data);
    //   return response;
    // } catch (e) {
    //   setError(true);
    //   throw new Error(e);
    // } finally {
    //   setLoading(false);
    // }
  };

  // removes edit tab from view when user clicks away
  useEffect(
    () => () => {
      setIsEditing(false);
    },
    [setIsEditing],
  );

  // break it out into a separate component
  return (
    <SourcesForm
      isEditing={isEditing}
      formValues={formValues}
      setFormValues={setFormValues}
      pin={pin}
      setPin={setPin}
      error={error}
      loading={loading}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
}
