import { useMapEffect, useMap, Source, Layer } from '@redzone/map';
import { useState, useEffect, useMemo } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export function PointCollector(props) {
  const { onChange, showValue, value, ...rest } = props;
  const [collecting, setCollecting] = useState(false);
  const { map } = useMap();

  const draw = useMemo(
    () =>
      new MapboxDraw({
        controls: {
          line_string: false,
          point: true,
          polygon: false,
          trash: true,
          combine_features: false,
          uncombine_features: false,
        },
        defaultMode: 'draw_point',
      }),
    [],
  );

  useMapEffect(
    (readyMap) => {
      if (collecting) readyMap.addControl(draw);
    },
    [collecting],
  );

  const handleFinish = () => {
    const point = draw.getAll();

    onChange(point);
    setCollecting(false);
  };

  useEffect(
    () => () => {
      if (collecting) {
        map.removeControl(draw);
      }
    },
    [collecting, draw, map],
  );

  return collecting ? (
    <button {...rest} type="button" onClick={handleFinish}>
      Finish
    </button>
  ) : (
    <>
      <button
        type="button"
        {...rest}
        onClick={() => {
          setCollecting(true);
        }}
      />
      {value && (
        <Source id="point-collector-src" type="geojson" data={value}>
          <Layer
            id="point"
            mapboxLayer={{
              id: 'point',
              type: 'circle',
              source: 'point-collector-src',
              paint: {
                'circle-radius': 3,
                'circle-color': '#d13239',
              },
            }}
          />
        </Source>
      )}
    </>
  );
}
