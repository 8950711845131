import { useRef, useState } from 'react';
import { SlidersIcon } from '../icons/SlidersIcon.jsx';
import './ActionMenu.scss';
import { ClickOutside } from './ClickOutside.jsx';

export function ActionMenu(props) {
  const { actions, position = 'bottom-right', title } = props;
  const [isOpen, setIsOpen] = useState(false);
  const menuBodyRef = useRef();

  ClickOutside(menuBodyRef, () => setIsOpen(false));

  return (
    <div
      className={`action-menu ${position} ${isOpen ? 'open' : 'closed'}`}
      ref={menuBodyRef}
    >
      <button
        type="button"
        className="action-menu-title action-menu-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </button>
      <div className="action-menu-body-wrapper">
        <div className="action-menu-body">
          {actions.map((action) => (
            <button
              key={action.label}
              type="button"
              onClick={action.handler}
              className="action"
            >
              <div className="action-icon">
                {action.icon ?? <SlidersIcon />}
              </div>
              <div className="action-label">{action.label}</div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
