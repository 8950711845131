import React, { cloneElement, useState } from 'react';
import { Drawer } from './Drawer.jsx';
import './ToolDrawer.scss';
import './ToolDrawerLabels.scss';

export function ToolDrawer(props) {
  const { setOpen, children, close } = props;
  const child = React.Children.only(children);

  return cloneElement(child, {
    className: child.props?.className
      ? `${child.props.className} tool-drawer`
      : 'tool-drawer',
    setOpen,
    close,
  });
}

export function ToolDrawersContainer(props) {
  const { children, labelClassName, open, setOpen } = props;
  const childArray = React.Children.toArray(children);
  const [selected, setSelected] = useState(null);

  if (!childArray.every((child) => child.type === ToolDrawer)) {
    throw new Error(
      'All children of a `ToolDrawerContainer` must be a `ToolDrawer` component.',
    );
  }

  const fullLabelClassName = ['tool-drawer-labels', labelClassName]
    .filter(Boolean)
    .join(' ');

  const selectOrNullifyTab = (nextTab) => {
    if (nextTab !== selected) {
      setSelected(nextTab);
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };

  const labels = childArray.map((child) => {
    const { id, icon, helpText } = child.props;

    const tabClassName = ['tab-label', id === selected ? 'selected' : null]
      .filter(Boolean)
      .join(' ');

    return (
      <li className={tabClassName} key={id}>
        <button
          type="button"
          className="standard tab-label-button"
          onClick={() => selectOrNullifyTab(id)}
        >
          {icon}
          <div className="help-text">{helpText}</div>
        </button>
      </li>
    );
  });

  const selectedChild =
    childArray.find((child) => child.props.id === selected) ?? null;
  const selectedChildWithProps =
    selectedChild &&
    cloneElement(selectedChild, {
      close: () => setSelected(null),
    });

  return (
    <>
      <div className={fullLabelClassName}>
        <ul className="standard-unordered-list tab-labels-list">{labels}</ul>
      </div>
      <Drawer
        position="right"
        className={selectedChild ? 'tool-drawer' : 'closed tool-drawer'}
        open={open}
      >
        {selectedChild ? cloneElement(selectedChildWithProps) : null}
      </Drawer>
    </>
  );
}
