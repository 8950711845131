import { Children, cloneElement, useState } from 'react';
import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import './Accordion.scss';

export function AccordionLabelTitleChevron(props) {
  const { label, onClick } = props;
  return (
    <div className="accordion-label title-chevron">
      <h2 className="label">{label}</h2>
      <button
        type="button"
        className="standard toggle-holder"
        onClick={onClick}
      >
        <ChevronIcon orientation="right" />
      </button>
    </div>
  );
}

export function AccordionSection(props) {
  const { className, label, onClick, open, ...rest } = props;
  const fullClassName = [className, 'accordion-body', 'stacked']
    .filter(Boolean)
    .join(' ');

  return (
    <div className={`accordion-section ${open ? 'open stacked' : 'closed'}`}>
      <button
        type="button"
        className="accordion-label-container"
        onClick={onClick}
      >
        <div className="accordion-label">{label}</div>
        <div className="accordion-chevron">
          <ChevronIcon orientation="down" />
        </div>
      </button>
      {open && (
        <div className="accordion-body-container stacked">
          <div {...rest} className={fullClassName} />
        </div>
      )}
    </div>
  );
}

export function Accordion(props) {
  const { children, expanded, setExpanded, label, onClick, ...rest } = props;
  const internalExpandedIdState = useState(0);

  const [_expanded, _setExpanded] =
    expanded == null ? internalExpandedIdState : [expanded, setExpanded];

  const childArray = Children.toArray(children);
  const className = [props.className, 'accordion'].filter(Boolean).join(' ');

  return (
    <div {...rest} className={className}>
      {childArray.map((child, idx) => {
        const id = child.props.id ?? idx;

        return cloneElement(child, {
          ...child.props,
          open: _expanded === id,
          onClick: () => {
            if (onClick) onClick(id);
            _setExpanded(id === _expanded ? null : id);
          },
        });
      })}
    </div>
  );
}

Accordion.defaultProps = { className: null };
