import { groupBy } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import {
  DataCollectionTable,
  Column,
} from '../../../../../shared/tables/DataCollectionTable.jsx';
import './Triage.scss';
import { useUser } from '../../../../../../context/UserContext.jsx';
import { TriageSummarySingleOrg } from './TriageSummarySingleOrg.jsx';

export function TriageSummary() {
  const { fire } = useOutletContext();
  const { currentUser } = useUser();

  if (currentUser.organizations.length === 1) return <TriageSummarySingleOrg />;

  const assignmentsByOrganization = groupBy(
    fire.assignments,
    (a) => a.organization.id,
  );

  const summary = Object.entries(assignmentsByOrganization).map(
    ([organizationId, assignments]) => {
      const visitations = assignments.flatMap((a) => a.visitations);
      const policies = visitations.map((v) => v.policy);

      return {
        key: organizationId,
        organization: currentUser.organizations.find(
          (o) => o.id === organizationId,
        ).shortName,
        openAssignmentCount: assignments.filter((a) => a.status === 'open')
          .length,
        policies: policies.length,
        visitationCount: visitations.length,
        totalTiv: policies.reduce((acc, p) => acc + p.tiv, 0),
      };
    },
  );

  return (
    <DataCollectionTable data={summary}>
      <Column title="Organization" value={(row) => row.organization} />
      <Column
        id="openAssignmentCount"
        title="Open Assignments"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.openAssignmentCount}
      />
      <Column
        id="visitations"
        title="Visitations"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.visitationCount}
      />
      <Column
        id="policies"
        title="Policies"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.policies}
      />
      <Column
        id="totalTiv"
        title="Total TIV"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) =>
          row.totalTiv.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        }
      />
    </DataCollectionTable>
  );
}
