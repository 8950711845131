import { useEffect } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { merge } from 'lodash';
import { useMap } from '@redzone/map';
import { Tab, TabLabels } from '../../../shared/Tabs.jsx';
import { getExposureCssColors } from '../../../../utils/exposure-colors.js';
import './Fire.scss';
import { query as fireOverviewQuery } from './tabs/FireOverview.jsx';
import { queryLoader } from '../../../../hooks/use-query.js';
import { useDashboard } from '../../../../context/DashboardContext.jsx';
import { FireLayers } from './FireLayers.jsx';
import { useUser } from '../../../../context/UserContext.jsx';

const baseQuery = {
  type: 'fires',
  id: { $var: 'id' },
  properties: {
    incidentId: 'incidentId',
    incidentName: 'incidentName',
    center: 'center',
    portfolioExposures: {
      properties: {
        id: 'id',
        organization: { properties: { id: 'id', shortName: 'shortName' } },
        rating: 'rating',
        policyExposures: {
          properties: {
            distance: 'distance',
            policy: {
              properties: {
                id: 'id',
                organization: {
                  properties: { id: 'id', shortName: 'shortName' },
                },
                location: 'location',
                tiv: 'tiv',
                enrollments: 'enrollments',
                latitude: 'latitude',
                longitude: 'longitude',
              },
            },
          },
        },
      },
    },
    reports: {
      order: [{ property: 'createdOnDateTime', direction: 'desc' }],
      properties: {
        id: 'id',
        createdOnDateTime: 'createdOnDateTime',
        acreage: 'acreage',
        containment: 'containment',
        source: 'source',
      },
    },
  },
};

export const query = merge(baseQuery, fireOverviewQuery);

export async function loader({ params }) {
  const vars = { id: params.perilId };
  return queryLoader(query, { vars });
}

export function Fire() {
  const data = useLoaderData();
  const { addPerilTab } = useDashboard();
  const { map } = useMap();
  const { hasPermission } = useUser();

  const fire = {
    history: [],
    maxExposure: data.portfolioExposures.reduce(
      (x, y) => Math.max(x, y.rating ?? 0),
      0,
    ),
    ...data,
  };

  useEffect(() => {
    if (!fire?.center?.coordinates) return;

    const [lng, lat] = fire.center.coordinates;
    const offset = [200, 0];

    if (lat && lng && map) {
      map.flyTo({ center: [lng, lat], offset, zoom: 9 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire.incidentId, map]);

  useEffect(() => {
    addPerilTab({ path: 'fires', peril: fire, exposure: fire.maxExposure });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fire.incidentId]);

  return (
    <>
      <div
        className="peril-details fire-details drawer"
        style={
          fire.maxExposure > 0 ? getExposureCssColors(fire.maxExposure) : {}
        }
      >
        <header>
          <h1 className="title fire-name">{fire.incidentName}</h1>
        </header>
        <TabLabels className="dark-theme">
          <Tab label="Overview" path="overview" />
          {/* <Tab label="History" path="history" /> */}
          <Tab label="Exposure" path="exposure" />
          <Tab label="Triage" path="triage" />
          {hasPermission('rz:alertdashboard:fires:update') && (
            <Tab label="ROC" path="roc" />
          )}
        </TabLabels>
        <Outlet context={{ fire }} />
      </div>
      <FireLayers perils={[fire]} />
    </>
  );
}
