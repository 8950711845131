import { addHours, format } from 'date-fns';

const colors = ['#8B0000', '#ff0000', '#FFA500', '#FFFF00'];
const sixHoursAgo = format(addHours(new Date(), -6), 'yyyyMMddHHmm');
const twelvehHoursAgo = format(addHours(new Date(), -12), 'yyyyMMddHHmm');
const twentyFourHoursAgo = format(addHours(new Date(), -24), 'yyyyMMddHHmm');
const layerPaint = {
  'circle-color': [
    'case',
    ['<', sixHoursAgo, ['concat', ['get', 'ACQ_DATE'], ['get', 'ACQ_TIME']]],
    colors[0],

    [
      '<',
      twelvehHoursAgo,
      ['concat', ['get', 'ACQ_DATE'], ['get', 'ACQ_TIME']],
    ],
    colors[1],

    [
      '<',
      twentyFourHoursAgo,
      ['concat', ['get', 'ACQ_DATE'], ['get', 'ACQ_TIME']],
    ],
    colors[2],
    colors[3],
  ],
  'circle-blur': 1,
  'circle-opacity': 0.6,
  'circle-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    // zoom is 5 (or less)
    5,
    6,
    // zoom is 10 (or greater)
    10,
    8,
  ],
};

export const modisLayer = {
  id: 'modis',
  layerId: 'modis',
  source: {
    id: 'redzonesoftware.3nq40h06',
    type: 'vector',
    url: 'mapbox://redzonesoftware.3nq40h06',
  },
  mapboxLayer: () => ({
    id: 'redzonesoftware.3nq40h06',
    type: 'circle',
    source: 'redzonesoftware.3nq40h06',
    'source-layer': 'MODIS_C6_1_USA_contiguous_and-1mpb36',
    paint: layerPaint,
  }),
  onClick: (e, fn) =>
    fn('modis_viirs', {
      center: {
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      features: e.features,
      sourceType: 'MODIS',
    }),
};

export const viirsNoaaLayer = {
  id: 'viirs_NOAA_20',
  layerId: 'viirs-NOAA-20',
  source: {
    id: 'redzonesoftware.1w8q528y',
    type: 'vector',
    url: 'mapbox://redzonesoftware.1w8q528y',
  },
  mapboxLayer: () => ({
    id: 'redzonesoftware.1w8q528y',
    type: 'circle',
    source: 'redzonesoftware.1w8q528y',
    'source-layer': 'J1_VIIRS_C2_USA_contiguous_an-705fme',
    paint: layerPaint,
  }),
  onClick: (e, fn) =>
    fn('modis_viirs', {
      center: {
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      features: e.features,
      sourceType: 'Viirs NOAA-20',
    }),
};

export const viirsSnppLayer = {
  id: 'viirs_S_NPP',
  layerId: 'viirs-S-NPP',
  source: {
    id: 'redzonesoftware.dhru0vyw',
    type: 'vector',
    url: 'mapbox://redzonesoftware.dhru0vyw',
  },
  mapboxLayer: () => ({
    id: 'redzonesoftware.dhru0vyw',
    type: 'circle',
    source: 'redzonesoftware.dhru0vyw',
    'source-layer': 'SUOMI_VIIRS_C2_USA_contiguous-66yctg',
    paint: layerPaint,
  }),
  onClick: (e, fn) =>
    fn('modis_viirs', {
      center: {
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      features: e.features,
      sourceType: 'Viirs S-NPP',
    }),
};
