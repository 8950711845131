import { Layer, Source } from '@redzone/map';
import { useDashboardState } from '../../context/DashboardContext.jsx';

export function ImportedLayers() {
  const [selectedLayers] = useDashboardState('selectedLayers');
  const [allImportedLayers] = useDashboardState('importedLayers');

  const importedLayers = allImportedLayers.filter((l) =>
    selectedLayers.imported.includes(l.id),
  );

  return importedLayers.map(({ args, id, value }) => (
    <Source
      key={JSON.stringify(args)}
      id={JSON.stringify(args)}
      type="geojson"
      data={value}
    >
      <Layer
        id={id}
        mapboxLayer={{
          type: 'fill',
          paint: {
            'fill-color': args.color,
            'fill-opacity': 0.3,
          },
        }}
      />
    </Source>
  ));
}
