import { Outlet, useOutletContext } from 'react-router-dom';
import './FireExposure.scss';
import { Tab, TabLabels } from '../../../../shared/Tabs.jsx';

export function FireExposure() {
  const { fire } = useOutletContext();

  return (
    <>
      <TabLabels className="secondary">
        <Tab label="Summary" path="summary" />
        <Tab label="Policies" path="policies" />
      </TabLabels>
      <div className="stacked">
        <Outlet context={{ fire }} />
      </div>
    </>
  );
}

