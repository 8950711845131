import { Outlet } from 'react-router-dom';
import { LayersIcon, ToolDrawer, ToolDrawersContainer } from '@redzone/shared';
import { Nav } from '../nav/Nav.jsx';
import { Drawer } from '../app/Drawer.jsx';
import { Header } from '../app/Header.jsx';
import { SelectPolicies } from '../tool-drawers/SelectPolicies.jsx';
import { MeasureDistance } from '../tool-drawers/MeasureDistance.jsx';
import { RulerIcon } from '../icons/RulerIcon.jsx';
import { PerilTabs } from '../nav/PerilTabs.jsx';
import { LayerDrawer } from '../tool-drawers/layer-drawer/LayerDrawer.jsx';
import { useDashboard } from '../../context/DashboardContext.jsx';
import { Spinner } from '../icons/Spinner.jsx';
import { Map } from '../map/Map.jsx';
import { PolygonIcon } from '../icons/PolygonIcon.jsx';
import { AddIcon } from '../icons/AddIcon.jsx';
import { SourcesDrawer } from '../tool-drawers/sources-drawer/SourcesDrawer.jsx';
import { useUser } from '../../context/UserContext.jsx';
import './Dashboard.scss';

export function Dashboard() {
  const {
    isContentDrawerOpen,
    setIsContentDrawerOpen,
    isToolDrawerOpen,
    setIsToolDrawerOpen,
  } = useDashboard();

  const { hasPermission } = useUser();

  return (
    <>
      <Header />
      <Nav>
        <PerilTabs />
      </Nav>
      <Drawer
        hasToggleButton
        position="left"
        className="content-drawer left-drawer"
        open={isContentDrawerOpen}
        setOpen={setIsContentDrawerOpen}
      >
        <div className="loading-indicator">
          <Spinner />
        </div>
        <Outlet />
      </Drawer>
      <ToolDrawersContainer
        labelClassName="dark-theme"
        open={isToolDrawerOpen}
        setOpen={setIsToolDrawerOpen}
      >
        <ToolDrawer
          id="select-policies"
          icon={<PolygonIcon />}
          helpText="Select Policies"
          setOpen={setIsToolDrawerOpen}
        >
          <SelectPolicies />
        </ToolDrawer>
        <ToolDrawer id="ruler" icon={<RulerIcon />} helpText="Measure Distance">
          <MeasureDistance />
        </ToolDrawer>
        <ToolDrawer id="layer" icon={<LayersIcon />} helpText="Layers">
          <LayerDrawer />
        </ToolDrawer>
        {hasPermission('rz:alertdashboard:fires:update') && (
          <ToolDrawer
            id="sources"
            icon={<AddIcon color="#FFF" />}
            helpText="Trusted Sources"
          >
            <SourcesDrawer />
          </ToolDrawer>
        )}
      </ToolDrawersContainer>
      <Map />
    </>
  );
}
