import { Layer, Source } from '@redzone/map';
import { Popup } from '@redzone/map/src/components/Popup.jsx';
import distance from '@turf/distance';
import { keyBy, mapValues, uniq } from 'lodash';
import { useLoaderData, useOutletContext } from 'react-router-dom';
import { useUser } from '../../../../../../context/UserContext.jsx';
import { queryLoader } from '../../../../../../hooks/use-query.js';
import { DataCollection } from '../../../../../shared/tables/DataCollection.jsx';
import { DataTable } from '../../../../../shared/tables/DataTable.jsx';
import './Triage.scss';

const distanceFormatter = new Intl.NumberFormat('en', {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

const formattedStatuses = {
  inProgress: 'in progress',
  needsVisitation: 'needs visitation',
  revisitNeeded: 'revisit needed',
};

const formatters = {
  distance: (val) => distanceFormatter.format(val),
  status: (val) => formattedStatuses[val] ?? val,
};

const policyQuery = {
  type: 'visitations',
  properties: {
    id: 'id',
    location: 'location',
    status: 'status',
    assignment: {
      properties: {
        id: 'id',
        engine: {
          properties: {
            engineName: 'engineName',
          },
        },
        organization: {
          properties: {
            id: 'id',
            shortName: 'shortName',
          },
        },
        peril: {
          properties: {
            id: 'id',
            center: 'center',
            latitude: 'latitude',
            longitude: 'longitude',
          },
        },
      },
    },
    policy: {
      properties: {
        id: 'id',
        location: 'location',
        latitude: 'latitude',
        longitude: 'longitude',
      },
    },
  },
  filters: {
    'assignment.peril.id': { $var: 'perilId' },
  },
};

export async function loader({ params }) {
  const vars = { perilId: params.perilId };
  return queryLoader(policyQuery, { vars });
}

export function TriagePolicies() {
  const { fire } = useOutletContext();
  const unfilteredVisitations = useLoaderData();
  const { currentUser, policyLayers } = useUser();

  // TODO: This is bad, and should be centeralized into a complete triage query
  // at the `FireTriage.jsx` level. It can be refocused zipper-style at that
  // point.
  const fireAssignmentIds = fire.assignments.map((a) => a.id);
  const allVisitations = unfilteredVisitations.filter((v) =>
    fireAssignmentIds.includes(v.assignment.id),
  );

  const policyLayerObj = mapValues(
    keyBy(policyLayers, (l) => l.mapboxSource.id),
    (s, id) => ({
      id,
      mapboxSource: s.mapboxSource,
      layers: [{ id: s.layerId, mapboxLayer: s.mapboxLayer }],
    }),
  );

  const getDistance = (center) =>
    distance(center, fire.center, { units: 'miles' });

  const columns = {
    ...(currentUser.organizations.length > 1
      ? {
          organization: {
            id: 'organization',
            title: 'Organization',
            dataType: 'enum',
            options: uniq(
              allVisitations
                .map((row) => row.assignment.organization.shortName)
                .toSorted(),
            ),
            sortable: true,
            filterable: true,
            value: (visitation) => visitation.assignment.organization.shortName,
          },
        }
      : {}),
    address: {
      id: 'address',
      title: 'Address',
      value: (visitation) => visitation.policy.location,
    },
    status: {
      id: 'status',
      title: 'Status',
      dataType: 'enum',
      options: Object.keys(formattedStatuses),
      sortable: true,
      filterable: true,
      value: (visitation) => visitation.status,
      format: formatters.status,
    },
    engine: {
      id: 'engine',
      title: 'Engine',
      dataType: 'enum',
      options: uniq(
        allVisitations.map((row) => row.assignment.engine.engineName),
      ),
      filterable: true,
      sortable: true,
      value: (visitation) => visitation.assignment.engine.engineName,
    },
    distance: {
      id: 'distance',
      title: 'Distance',
      dataType: 'number',
      sortable: true,
      filterable: true,
      value: (visitation) =>
        getDistance([visitation.policy.longitude, visitation.policy.latitude]),
      format: formatters.distance,
    },
  };

  const filter = (vs) => [
    'in',
    ['get', 'policy_uuid'],
    ['literal', vs.map((v) => v.policy.id)],
  ];

  return (
    <DataCollection data={allVisitations} columns={columns}>
      {(visitations) => (
        <>
          <Popup coordinates={[-140, 40]} />
          <div className="scroll">
            <DataTable data={visitations} columns={columns} />
          </div>
          {Object.entries(policyLayerObj).map(([id, source]) => (
            <Source key={id} {...source.mapboxSource}>
              {source.layers.map((layer) => (
                <Layer
                  id={layer.id}
                  key={layer.id}
                  mapboxLayer={layer.mapboxLayer}
                  filter={filter(visitations)}
                />
              ))}
            </Source>
          ))}
        </>
      )}
    </DataCollection>
  );
}
