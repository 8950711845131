import { Source, Layer } from '@redzone/map';

export function CollectorValue(props) {
  const { id, value } = props;
  if (!value) return null;

  return (
    <Source id={id} type="geojson" data={value}>
      <Layer
        id={`${id}-layer`}
        mapboxLayer={{
          id: 'layer',
          type: 'fill',
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.4,
            'fill-outline-color': 'blue',
          },
        }}
      />
    </Source>
  );
}
