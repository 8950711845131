import { useOutletContext } from 'react-router-dom';
import { getExposureCssColors } from '../../../../../../utils/exposure-colors.js';
import './FireExposureSummarySingleOrg.scss';

const formattedTotalTiv = (totalTiv) =>
  (totalTiv ?? 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const formatPolicy = {
  tiv: (t) => formattedTotalTiv(t),
  distance: (d) =>
    Intl.NumberFormat('en', {
      style: 'unit',
      unit: 'mile',
      unitDisplay: 'long',
      maximumFractionDigits: 2,
    }).format(d),
};

export function FireExposureSummarySingleOrg() {
  const { fire } = useOutletContext();
  const { portfolioExposures } = fire;
  const exposure = portfolioExposures[0];

  const { rating } = exposure;
  const closestDistance = exposure.policyExposures.reduce(
    (min, p) => Math.min(min, p.distance),
    Infinity,
  );
  const totalTiv = exposure.policyExposures.reduce(
    (sum, p) => sum + p.policy.tiv,
    0,
  );
  const policyCount = exposure.policyExposures.length;

  return (
    <div className="component fire-exposure-tab peril-exposure-tab fire-exposure-summary-single-org substack scrollable">
      <ul className="standard summary-container" data-cy="single-org-summary">
        <li className="exposure" style={getExposureCssColors(rating)}>
          <div className="stat">
            <div className="exposure-score">
              {rating ? Math.round(rating) : '0'}
            </div>
          </div>
          <div className="label">exposure rating</div>
        </li>
        <li>
          <div className="stat">{formattedTotalTiv(totalTiv)}</div>
          <div className="label">TIV</div>
        </li>
        <li>
          <div className="stat">{policyCount ?? 0}</div>
          <div className="label">Policy Count</div>
        </li>
        <li>
          <div className="stat">{formatPolicy.distance(closestDistance)}</div>
          <div className="label">Nearest Policy</div>
        </li>
      </ul>
    </div>
  );
}
