import { useState } from 'react';
import { filterObj } from '../../../utils.js';
import './SortCollector.scss';

export function SortCollector(props) {
  const { columns, sort, setSort } = props;
  const sortableColumns = filterObj(
    columns,
    (col, colName) => col.sortable && !sort.some((s) => s.property === colName),
  );

  const [formProperty, setFormProperty] = useState(
    Object.keys(sortableColumns)[0],
  );
  const [formDirection, setFormDirection] = useState('asc');

  const allColumnsUsed = Object.keys(sortableColumns).length === 0;

  const addSort = () => {
    setSort([...sort, { property: formProperty, direction: formDirection }]);
    setFormProperty(
      Object.keys(sortableColumns).find((k) => k !== formProperty),
    );
    setFormDirection('asc');
  };

  const removeSort = (prop) => {
    setSort(sort.filter((s) => s.property !== prop));
    setFormProperty(prop);
  };

  return (
    <div className="component control-body sort-collector">
      <div className="selection">
        <div className="input-row">
          <label htmlFor="sort-property">Property</label>
          <select
            id="sort-property"
            value={formProperty}
            onChange={(e) => {
              setFormProperty(e.target.value);
            }}
            disabled={allColumnsUsed}
          >
            {Object.entries(sortableColumns).map(([prop, column]) => {
              const { title } = column;

              return (
                <option key={prop} value={prop}>
                  {title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input-row">
          <label htmlFor="sort-direction">Direction</label>
          <select
            id="sort-property"
            value={formDirection}
            onChange={(e) => {
              setFormDirection(e.target.value);
            }}
            disabled={allColumnsUsed}
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
        <button type="button" onClick={addSort} disabled={allColumnsUsed}>
          Add Sorting
        </button>
      </div>
      <div className="display">
        <ul className="standard">
          {sort.map(({ property, direction }) => {
            const { title } = columns[property];
            const dirStr = direction === 'asc' ? 'Asc' : 'Desc';

            return (
              <div className="standard lozenge sort-item" key={property}>
                {title} ({dirStr}){' '}
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => removeSort(property)}
                >
                  ✖
                </button>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
