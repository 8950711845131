import { mapValues } from 'lodash';

const everyValue = (obj, fn) => Object.entries(obj).every(([k, v]) => fn(v, k));

const filterFunctionsByType = {
  boolean(params) {
    if (params.length === 0) return () => false;
    if (params.length === 2) return () => true;

    return params[0] === 'yes' ? (val) => val : (val) => !val;
  },
  'date-time': function (params) {
    const { start: startTime, end: endTime } = params;

    if (!startTime && !endTime) return () => true;

    const startFn = startTime && ((val) => new Date(val) >= startTime);
    const endFn = endTime && ((val) => new Date(val) <= endTime);

    return startFn && endFn
      ? (val) => startFn(val) && endFn(val)
      : startFn ?? endFn;
  },
  enum(params) {
    return params && params.length > 0
      ? (val) => params.includes(val)
      : () => true;
  },
  number(params) {
    // numberRange
    const { min, max } = params ?? {};

    if (!min && !max) return () => true;
    if (min && max)
      return (val) => {
        const n = Number(val);
        return n >= min && n <= max;
      };

    return min ? (val) => Number(val) >= min : (val) => Number(val) <= max;
  },
  numberString(params) {
    if (!params) return () => true;

    // form of `8+` or `12 +`
    if (params.substr(-1, 1) === '+') {
      const n = Number(params.split('+')[0].trim());
      return (val) => Number(val) >= n;
    }

    // form of `>=3` or `>= 8`
    if (params.substr(0, 2) === '>=') {
      const n = Number(params.split('>=')[1].trim());
      return (val) => Number(val) >= n;
    }

    // form of `>3` or `> 8`
    if (params[0] === '>') {
      const n = Number(params.substr(1).trim());
      return (val) => Number(val) > n;
    }

    // form of `<=3` or `<= 8`
    if (params.substr(0, 2) === '<=') {
      const n = Number(params.substr(2).trim());
      return (val) => Number(val) <= n;
    }

    // form of `<3` or `< 8`
    if (params[0] === '<') {
      const n = Number(params.substr(1).trim());
      return (val) => Number(val) < n;
    }

    // form of `2-7` or `3 - 9`
    const rangeSplit = params.split('-');
    if (rangeSplit.length === 2) {
      const [min, max] = rangeSplit.map((n) => Number(n.trim()));
      return (val) => Number(val) >= min && Number(val) <= max;
    }

    const paramAsNumber = Number(params);
    return (val) => Number(val) === paramAsNumber;
  },
};

export function filterWithCollectors(rows, filters, columns) {
  // The goal here is to run each row of data through all of the filters that
  // have been provided. The way an item is filtered depends on its data type.
  // Filters come in as an object of [column] => [filterConfig]. The
  // filterConfig depends on the data type.

  // This takes the filters and returns a predicate function for each one.
  // Items will be put through all of the predicate functions to produce the
  // final filtered result.
  const filterFns = mapValues(filters, ({ type, params }) =>
    filterFunctionsByType[type](params),
  );

  return rows.filter((row) => {
    const vals = mapValues(columns, (col) => col.value(row));
    return everyValue(filterFns, (fn, colName) => fn(vals[colName]));
  });
}
