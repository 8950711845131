import { useOutletContext } from 'react-router-dom';
import { EnvelopeIcon } from '../../../../icons/EnvelopeIcon.jsx';
import { DownloadIcon } from '../../../../icons/DownloadIcon.jsx';
import { shortCompactLocalizeNumber } from '../../../../../utils/formatter.js';
import './EarthquakeOverview.scss';

export function EarthquakeOverview() {
  const { earthquake } = useOutletContext();
  /* NOTE(Baad): defensive coding since the data is not coming in right.
  will fix when the API shows up. */
  const exposedPolicies = earthquake.portfolioExposures.flatMap((e) =>
    e.policyExposures.map((pe) => pe.policy),
  );
  const tivTotal = earthquake.portfolioExposures.reduce(
    (x, y) => Math.max(x, y.totalTiv ?? 0),
    0,
  );
  const maxTiv = exposedPolicies.reduce(
    (max, p) => Math.max(max, p.tiv ?? 0),
    0,
  );
  const minTiv = exposedPolicies.reduce(
    (min, p) => Math.min(min, p.tiv ?? 0),
    0,
  );

  return (
    <>
      <div className="eathquake-header">
        <button
          className="standard eathquake-overview-button"
          type="button"
          onClick={() => alert('send email!')}
        >
          <EnvelopeIcon /> <span>email report</span>
        </button>
      </div>

      <div className="earthquake-summary">
        <ul className="standard summary-container">
          <li className="mag">
            <div className="stat">{earthquake.mag}</div>
            <div className="label">magnitude</div>
          </li>
          <li className="depth">
            <div className="stat">{earthquake.depth ?? '-'}</div>
            <div className="label">depth</div>
          </li>
          <li className="tsunami">
            <div className="stat">{earthquake.tsunami ? 'Yes' : 'No'}</div>
            <div className="label">tsunami</div>
          </li>
          <li className="policyCount">
            <div className="stat">
              {earthquake.portfolioExposures?.policyCount ?? 0}
            </div>
            <div className="label">policy count</div>
          </li>
          <li className="tiv">
            <div className="stat">{tivTotal}</div>
            <div className="label">tiv</div>
          </li>
          <li className="tiv-range">
            <div className="stat">
              ${shortCompactLocalizeNumber(minTiv)} - $
              {shortCompactLocalizeNumber(maxTiv)}
            </div>
            <div className="label">TIV range</div>
          </li>
        </ul>
      </div>

      <div className="earthquake-downloads">
        <button
          className="standard eathquake-overview-button"
          type="button"
          onClick={() => alert('download Tariff')}
        >
          <DownloadIcon /> <span>Tariff</span>
        </button>
        <button
          className="standard eathquake-overview-button"
          type="button"
          onClick={() => alert('download Exposure')}
        >
          <DownloadIcon /> <span>Exposure</span>
        </button>
      </div>
    </>
  );
}
