import { merge, pick } from 'lodash';
import { createMemoryStore as createDPStore } from '@data-prism/memory-store';
import publicData from '../../fixtures/public.data.json';
import organizationData from '../../fixtures/organization.data.json';

export function createMemoryStore(schema) {
  const store = createDPStore(schema);

  const allData = { ...publicData };
  Object.values(organizationData).forEach((orgData) => merge(allData, orgData));
  store.seed(allData);

  return {
    ...store,

    // reseeding lets the store mimic the restricted data for users who have
    // have access to a limited number of organizations
    seedOrganizations(organizationIds) {
      const availableOrganizationData = pick(organizationData, organizationIds);
      const limitedOrgData = { ...publicData };
      Object.values(availableOrganizationData).forEach((orgData) =>
        merge(limitedOrgData, orgData),
      );
      store.setState(limitedOrgData);
    },
  };
}
