import { uniq } from 'lodash';
import { useLoaderData } from 'react-router-dom';
import { queryLoader } from '../../../../../../hooks/use-query.js';
import { ExpandIcon } from '../../../../../icons/ExpandIcon.jsx';
import {
  Column,
  DataCollectionTable,
} from '../../../../../shared/tables/DataCollectionTable.jsx';
import './TriageVisitations.scss';
import { useUser } from '../../../../../../context/UserContext.jsx';

const createdFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const visitationQuery = {
  type: 'visitations',
  properties: {
    id: 'id',
    createdAt: 'createdAt',
    location: 'location',
    assignment: {
      properties: {
        id: 'id',
        createdAt: 'createdAt',
        address: 'address',
        engine: { properties: { engineName: 'engineName' } },
        organization: {
          properties: {
            id: 'id',
            shortName: 'shortName',
          },
        },
        peril: {
          properties: {
            id: 'id',
          },
        },
      },
    },
    policy: {
      properties: {
        location: 'location',
      },
    },
  },
  filters: {
    'assignment.peril.id': { $var: 'perilId' },
  },
};

export async function loader({ params }) {
  const vars = { perilId: params.perilId };
  return queryLoader(visitationQuery, { vars });
}

export function TriageVisitations() {
  const visitations = useLoaderData();
  const { currentUser } = useUser();

  const allEngines = uniq(
    visitations.flatMap((v) => v.assignment.engine.engineName),
  );
  const tableData = visitations.map((visitation) => ({
    key: visitation.id,
    organization: visitation.assignment.organization.shortName,
    created: new Date(visitation.createdAt),
    address: visitation.policy.location,
    engine: visitation.assignment.engine.engineName,
    visitCount: 1,
  }));

  return (
    <DataCollectionTable
      className="triage-visitations stackable"
      data={tableData}
      title="visitations"
      rowActions={[
        {
          icon: <ExpandIcon />,
          label: 'Expand',
          handler: () => {
            alert('expanding');
          },
        },
      ]}
    >
      {currentUser.organizations.length > 1 && (
        <Column
          id="organization"
          title="Organization"
          dataType="enum"
          filterable
          sortable
          options={uniq(tableData.map((row) => row.organization))}
          value={(row) => row.organization}
        />
      )}
      <Column
        id="created"
        title="Created"
        dataType="date-time"
        filterable
        sortable
        value={(row) => row.created}
        formattedValue={(row) => createdFormatter.format(row.created)}
      />
      <Column
        id="address"
        title="Address"
        dataType="string"
        value={(row) => row.address}
      />
      <Column
        id="engine"
        title="Engine"
        dataType="enum"
        options={allEngines}
        filterable
        sortable
        value={(row) => row.engine}
      />
      <Column
        id="visitCount"
        title="Visit Count"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.visitCount}
      />
    </DataCollectionTable>
  );
}
