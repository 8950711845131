import './PolygonIcon.scss';

export function PolygonIcon() {
  return (
    <svg
      className="polygon-icon"
      viewBox="0 0 20 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Draw Icon</title>
      <g
        id="Alert-Dashboard"
        fillRule="evenodd"
      >
        <path
          d="M14.5997179,2.90682187 L16.4881571,17.672335 L3.06355438,15.2386705 L3.06355438,5.45886215 L14.5997179,2.90682187 Z"
          id="Rectangle"
          fill="none"
        />
        <rect
          id="Rectangle-Copy"
          x="12.9043864"
          y="0.405967452"
          width="4"
          height="4"
        />
        <rect
          id="Rectangle-Copy-4"
          x="0.563554384"
          y="3.60546134"
          width="4"
          height="4"
        />
        <rect
          id="Rectangle-Copy-2"
          x="14.9043864"
          y="15.926362"
          width="4"
          height="4"
        />
        <rect
          id="Rectangle-Copy-3"
          x="0.563554384"
          y="13.3825586"
          width="4"
          height="4"
        />
      </g>
    </svg>
  );
}
