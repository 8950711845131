export const HURRICANE_METRICS = {
  aig: [
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 2,
      assetType: 'com',
      tiv: 448989988,
      policyCount: 1896,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 2,
      assetType: 'ho',
      tiv: 1657786912,
      policyCount: 9151,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 3,
      assetType: 'com',
      tiv: 9073051858,
      policyCount: 27854,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 3,
      assetType: 'ho',
      tiv: 26161266634,
      policyCount: 139912,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 4,
      assetType: 'com',
      tiv: 36036600809,
      policyCount: 135766,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 4,
      assetType: 'ho',
      tiv: 107686174320,
      policyCount: 818792,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 5,
      assetType: 'com',
      tiv: 6051756817,
      policyCount: 24804,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 5,
      assetType: 'ho',
      tiv: 25975594980,
      policyCount: 195946,
    },
  ],
  lex: [
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 2,
      assetType: 'com',
      tiv: 0,
      policyCount: 0,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 2,
      assetType: 'ho',
      tiv: 1657786912,
      policyCount: 9151,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 3,
      assetType: 'com',
      tiv: 9073051858,
      policyCount: 27854,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 3,
      assetType: 'ho',
      tiv: 26161266634,
      policyCount: 139912,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 4,
      assetType: 'com',
      tiv: 36036600809,
      policyCount: 135766,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 4,
      assetType: 'ho',
      tiv: 107686174320,
      policyCount: 818792,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 5,
      assetType: 'com',
      tiv: 6051756817,
      policyCount: 24804,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 5,
      assetType: 'ho',
      tiv: 25975594980,
      policyCount: 195946,
    },
  ],
  pcg: [
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 2,
      assetType: 'com',
      tiv: 3,
      policyCount: 9,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 2,
      assetType: 'ho',
      tiv: 1657786912,
      policyCount: 9151,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 3,
      assetType: 'com',
      tiv: 9073051858,
      policyCount: 27854,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 3,
      assetType: 'ho',
      tiv: 26161266634,
      policyCount: 139912,
    },
    {
      incidentId: 'WP122022',
      geomType: 'impact_past',
      category: 4,
      assetType: 'com',
      tiv: 36036600809,
      policyCount: 135766,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 4,
      assetType: 'ho',
      tiv: 107686174320,
      policyCount: 818792,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 5,
      assetType: 'com',
      tiv: 6051756817,
      policyCount: 24804,
    },
    {
      incidentId: 'WP122022',
      geomType: 'rain_past',
      category: 5,
      assetType: 'ho',
      tiv: 25975594980,
      policyCount: 195946,
    },
  ],
};
