import { groupBy, mapValues } from 'lodash';
import { cloneElement, useState } from 'react';
import { classStr } from '../../../helpers/helpers.js';
import { DataCollection } from '../../shared/tables/DataCollection.jsx';
import './PerilList.scss';

// perils are broken into five groups for styling purposes:
// 0, 1-25, 26-50, 51-75, 76+
const perilRatingGroups = [4, 3, 2, 1];

const plurals = {
  earthquake: 'Earthquakes',
  fire: 'Fires',
  hurricane: 'Hurricanes',
};

export function PerilListHeader(props) {
  const { perilList, setPerilList, setSort, perils, perilType } = props;
  const exposedPerils = perils.filter((h) => h.exposed);
  const exposureGroupCounts = groupBy(exposedPerils, (f) =>
    Math.ceil(f.maxExposure / 25),
  );

  return (
    <header>
      <button
        type="button"
        className={classStr({
          'peril-type': true,
          redwall: true,
          secondary: true,
          selected: perilList === 'EXPOSED_PERILS',
        })}
        onClick={() => {
          setPerilList('EXPOSED_PERILS');
          setSort([{ property: 'maxExposure', direction: 'desc' }]);
        }}
        data-cy="exposed-perils-button"
      >
        Current Exposures ({exposedPerils.length})
      </button>
      <button
        type="button"
        className={classStr({
          'peril-type': true,
          redwall: true,
          secondary: true,
          selected: perilList === 'SHOW_ALL_PERILS',
        })}
        onClick={() => {
          setPerilList('SHOW_ALL_PERILS');
          setSort([{ property: 'acreage', direction: 'desc' }]);
        }}
        data-cy="all-perils-button"
      >
        All {plurals[perilType]} ({perils.length})
      </button>
      <div className="alert-counts">
        {perilRatingGroups.map((groupId) => (
          <button
            type="button"
            key={groupId}
            className={`exposure-severity-group group-${groupId} ${
              perilList === `RATING_GROUP_${groupId}` && `selected`
            }`}
            onClick={() => {
              setPerilList(`RATING_GROUP_${groupId}`);
              setSort([{ property: 'maxExposure', direction: 'desc' }]);
            }}
            data-cy={`exposure-severity-group-${groupId}`}
          >
            {exposureGroupCounts[groupId]?.length ?? 0}
          </button>
        ))}
      </div>
    </header>
  );
}

export function PerilBadges(props) {
  const { badgeDefinitions, peril } = props;

  return (
    <div className="badges">
      {Object.entries(badgeDefinitions).map(([badgeProp, badgeDef], idx) =>
        peril[badgeProp] ? (
          <div key={badgeProp} className={`redwall badge color-idx-${idx}`}>
            {badgeDef.element ? badgeDef.element(peril) : badgeDef.text}
          </div>
        ) : (
          ''
        ),
      )}
    </div>
  );
}

export function PerilList(props) {
  const {
    badgeDefinitions,
    columnDefinitions,
    layersElement,
    perilElement,
    perilType,
    perils: rawPerils,
  } = props;

  const PERIL_LISTS = {
    SHOW_ALL_PERILS: 'SHOW_ALL_PERILS',
    EXPOSED_PERILS: 'EXPOSED_PERILS',
    RATING_GROUP_1: 'RATING_GROUP_ONE',
    RATING_GROUP_2: 'RATING_GROUP_TWO',
    RATING_GROUP_3: 'RATING_GROUP_THREE',
    RATING_GROUP_4: 'RATING_GROUP_FOUR',
  };

  const [perilList, setPerilList] = useState(PERIL_LISTS.EXPOSED_PERILS);

  const [sort, setSort] = useState([
    { property: 'maxExposure', direction: 'desc' },
  ]);

  const expandedColumnDefinitions = {
    ...columnDefinitions,
    ...mapValues(badgeDefinitions, ({ title }, key) => ({
      dataType: 'boolean',
      filterable: true,
      title,
      value: (v) => v[key],
    })),
    maxExposure: {
      title: 'Exposure',
      dataType: 'number',
      sortable: true,
      filterable: true,
      value: (peril) =>
        peril.portfolioExposures.reduce((max, e) => Math.max(max, e.rating), 0),
    },
  };

  const perils = rawPerils.map((peril) => ({
    ...peril,
    ...mapValues(badgeDefinitions, (b) => b.test(peril)),
    maxExposure: peril.portfolioExposures.reduce(
      (max, exposure) => Math.max(max, exposure.rating ?? 0),
      0,
    ),
  }));

  const exposedPerils = perils.filter((h) => h.exposed);
  const groupedPerils = [1, 2, 3, 4].reduce((acc, i) => {
    const filteredPerils = perils.filter(
      (h) => i === Math.ceil(h.maxExposure / 25),
    );
    return [...acc, filteredPerils ?? []];
  }, []);

  let perilsToShow;
  switch (perilList) {
    case 'SHOW_ALL_PERILS':
      perilsToShow = perils;
      break;
    case 'EXPOSED_PERILS':
      perilsToShow = exposedPerils;
      break;
    case 'RATING_GROUP_1':
      // eslint-disable-next-line prefer-destructuring
      perilsToShow = groupedPerils[0];
      break;
    case 'RATING_GROUP_2':
      // eslint-disable-next-line prefer-destructuring
      perilsToShow = groupedPerils[1];
      break;
    case 'RATING_GROUP_3':
      // eslint-disable-next-line prefer-destructuring
      perilsToShow = groupedPerils[2];
      break;
    case 'RATING_GROUP_4':
      // eslint-disable-next-line prefer-destructuring
      perilsToShow = groupedPerils[3];
      break;
    default:
      perilsToShow = perils;
  }

  return (
    <div
      className={classStr({
        'peril-list-container': true,
        [`${perilType}-list-container`]: true,
        stacked: true,
      })}
    >
      <PerilListHeader
        perilType={perilType}
        perils={perils}
        perilList={perilList}
        setPerilList={setPerilList}
        setSort={setSort}
      />
      <DataCollection
        data={perilsToShow}
        columns={expandedColumnDefinitions}
        sort={sort}
        setSort={setSort}
      >
        {(preppedPerils) => (
          <>
            <div className="table-positioner scroll">
              <ul
                className={classStr({
                  'peril-list': true,
                  stacked: true,
                  [`${perilType}-list`]: true,
                })}
                data-cy={`${perilType}-list`}
              >
                {preppedPerils.map((peril) =>
                  cloneElement(perilElement, { peril, key: peril.incidentId }),
                )}
              </ul>
            </div>
            {layersElement &&
              cloneElement(layersElement, {
                perils: preppedPerils,
              })}
          </>
        )}
      </DataCollection>
    </div>
  );
}
