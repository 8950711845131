import {
  schemaFormatFormatters,
  schemaFormatters,
  schemaTypeFormatters,
} from './schema-formatters.js';

export function schemaLabel(schema, property) {
  const { title } = schema.properties[property];
  return title ?? property;
}

export function schemaValue(schema, property, resource) {
  const formatter =
    schemaFormatters[schema.$id]?.[property] ??
    schemaFormatFormatters[schema.properties[property].format] ??
    schemaTypeFormatters[schema.properties[property].type];

  if (resource[property] && formatter) return formatter(resource[property]);
  if (resource[property]) return resource[property];

  return schema.properties[property]?.default ?? null;
}
