export const redFlagWarningLayer = {
  id: 'red_flag_warning',
  layerId: 'red_flag_warning',
  source: {
    id: 'redzonesoftware.4ymvapwy',
    type: 'vector',
    url: 'mapbox://redzonesoftware.4ymvapwy',
  },
  mapboxLayer: {
    id: 'redzonesoftware.4ymvapwy',
    type: 'fill',
    source: 'redzonesoftware.4ymvapwy',
    'source-layer': 'fire_warning_boundaries-09ke4y',
    paint: {
      'fill-color': '#B61B0F',
      'fill-opacity': 0.6,
    },
  },
  filter: (data) => {
    const RedFlagWarningZones = data
      .map((alert) => [...new Set(alert['Target Area'])])
      .flat();
    return ['in', ['get', 'NAME'], ['literal', RedFlagWarningZones]];
  },
  onClick: (e, fn) =>
    fn('red_flag_warning', {
      center: {
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      features: e.features,
    }),
};
