import { sortBy } from 'lodash';
import './FireOverviewFeed.scss';

const interestingFields = ['acreage', 'containment'];

const fieldFormatters = {
  acreage: (val) => (val ? Intl.NumberFormat('en').format(val) : 'unknown'),
  containment: (val) => (val ? `${val}%` : 'unknown'),
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

export function FireOverviewFeed(props) {
  const { reports } = props;

  const sortedReports = sortBy(reports, (r) => r.createdOnDateTime);
  sortedReports.reverse();

  const initialReport = sortedReports[sortedReports.length - 1];

  return (
    <ul className="component fire-overview-feed standard stacked">
      {sortedReports.map((report, idx) => {
        if (idx === sortedReports.length - 1) return null; // handle this later
        const prevReport = sortedReports[idx + 1];

        const changedFields = interestingFields.filter(
          (field) => report[field] !== prevReport[field],
        );

        if (changedFields.length === 0) return null;

        return (
          <li key={report.id}>
            <div className="time">
              {dateFormatter.format(new Date(report.createdOnDateTime))}{' '}
            </div>

            <ul className="changes">
              {changedFields.map((field) => (
                <li className="change" key={field}>
                  <span className="field">{field}</span> went from{' '}
                  <span className="old">
                    {fieldFormatters[field](prevReport[field])}
                  </span>{' '}
                  to{' '}
                  <span className="new">
                    {fieldFormatters[field](report[field])}
                  </span>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
      <li>
        <div className="time">
          {dateFormatter.format(new Date(initialReport.createdOnDateTime))}
        </div>
        <ul className="changes">
          {interestingFields.map((field) => (
            <li className="change" key={field}>
              <span className="field">{field}</span> is{' '}
              <span className="new">{initialReport[field] ?? 'unknown'}</span>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}
