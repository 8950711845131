export const redFlagWarningMockData = [
  {
    Message: [
      'NOAA-NWS-ALERTS-CA126666B60C54.BeachHazardsStatement.126666C39D60CA.EKACFWEKA.4b826638f9d410853ee2935ffcbb8846 from w-nws.webmaster@noaa.gov',
    ],
    Sent: ['10:09 PDT on 10-19-2023'],
    Effective: ['10:09 PDT on 10-19-2023'],
    Expires: ['23:00 PDT on 10-19-2023'],
    Event: ['Beach Hazards Statement'],
    Alert: [
      '...BEACH HAZARDS STATEMENT REMAINS IN EFFECT THROUGH THIS EVENING...  * WHAT...Increased threat of sneaker waves expected.  * WHERE...Coastal Del Norte, Northern Humboldt Coast, Southwestern Humboldt and Mendocino Coast Counties.  * WHEN...Through this evening.. .  * IMPACTS...Large, unexpected waves can sweep across the beach without warning, sweeping people into the sea from rocks, jetties, and beaches. These sneaker waves can also move large objects such as logs, crushing anyone caught underneath.',
    ],
    Instructions: [
      'Don`t be fooled by an ocean that looks calm. There can be30 minutes of small waves before a sneaker wave strikes. Avoidrocks and jetties. Avoid steep beaches. Stay much farther backfrom the water and never turn your back on the ocean.',
    ],
    'Target Area': [
      'Coastal Del Norte Mendocino Coast Northern Humboldt Coast Southwestern Humboldt',
      'Coastal Del Norte Mendocino Coast Northern Humboldt Coast Southwestern Humboldt',
    ],
    'Forecast Office': ['NWS Eureka (Northwest California Coast)'],
  },
  {
    Message: [
      'NOAA-NWS-ALERTS-CA126666B525DC.BeachHazardsStatement.126666D29180CA.LOXCFWLOX.1d3e57ce19df71920cae4234a6253637 from w-nws.webmaster@noaa.gov',
    ],
    Sent: ['04:19 PDT on 10-19-2023'],
    Effective: ['09:00 PDT on 10-19-2023'],
    Expires: ['21:00 PDT on 10-20-2023'],
    Event: ['Beach Hazards Statement'],
    Alert: [
      '...BEACH HAZARDS STATEMENT IN EFFECT FROM 9 AM PDT THIS MORNING THROUGH FRIDAY EVENING...  * WHAT...Dangerous rip currents are expected later today through Friday due to elevated surf of 3 to 6 feet with local sets to 7 feet. Surf will slowly subside Friday afternoon and evening. There is a high risk of dangerous rip currents through Fri evening.  * WHERE...Catalina and Santa Barbara Islands, Malibu Coast and Los Angeles County Beaches.  * WHEN...From 9 AM PDT this morning through Friday evening.  * IMPACTS...There is an increased risk of ocean drowning. Rip currents can pull swimmers and surfers out to sea. Waves can wash people off beaches and rocks, and capsize small boats nearshore.  * ADDITIONAL DETAILS...Surf will be highest on west and northwest facing beaches. Nuisance spillover is possible in parking lots and walkways around the high tide near noon today, with slightly less of a chance early Friday afternoon.',
    ],
    Instructions: [
      'Remain out of the water due to hazardous swimming conditions, orstay near occupied lifeguard towers. Rock jetties can be deadlyin such conditions, so stay off the rocks.',
    ],
    'Target Area': [
      'Catalina and Santa Barbara Islands Los Angeles County Beaches Malibu Coast',
      'Catalina and Santa Barbara Islands Los Angeles County Beaches Malibu Coast',
    ],
    'Forecast Office': ['NWS Oxnard (The Los Angeles Area)'],
  },
  {
    Message: [
      'NOAA-NWS-ALERTS-CA126666B525DC.BeachHazardsStatement.126666D29180CA.LOXCFWLOX.5ac3725b728b2f91c6714994e2264ca0 from w-nws.webmaster@noaa.gov',
    ],
    Sent: ['04:19 PDT on 10-19-2023'],
    Effective: ['09:00 PDT on 10-19-2023'],
    Expires: ['21:00 PDT on 10-20-2023'],
    Event: ['Beach Hazards Statement'],
    Alert: [
      '...BEACH HAZARDS STATEMENT IN EFFECT FROM 9 AM PDT THIS MORNING THROUGH FRIDAY EVENING...  * WHAT...Dangerous rip currents are expected later today through Friday due to elevated surf of 3 to 6 feet with local sets to 7 feet on west facing beaches. Surf will slowly subside Friday afternoon and evening. There is a high risk of dangerous rip currents through Fri evening.  * WHERE...Santa Barbara County Southwestern Coast and Santa Barbara County Southeastern Coast.  * WHEN...From 9 AM PDT this morning through Friday evening.  * IMPACTS...There is an increased risk of ocean drowning. Rip currents can pull swimmers and surfers out to sea. Waves can wash people off beaches and rocks, and capsize small boats nearshore.  * ADDITIONAL DETAILS...Surf will be highest on west facing beaches. Nuisance spillover is possible in parking lots and walkways around the high tide near noon today, with slightly less of a chance early Friday afternoon.',
    ],
    Instructions: [
      'Remain out of the water due to hazardous swimming conditions, orstay near occupied lifeguard towers. Rock jetties can be deadlyin such conditions, so stay off the rocks.',
    ],
    'Target Area': [
      'Santa Barbara County Southeastern Coast Santa Barbara County Southwestern Coast',
      'Santa Barbara County Southeastern Coast Santa Barbara County Southwestern Coast',
    ],
    'Forecast Office': ['NWS Oxnard (The Los Angeles Area)'],
  },
  {
    Message: [
      'NOAA-NWS-ALERTS-CA126666B4E630.BeachHazardsStatement.126666E185A0CA.SGXCFWSGX.32ea5be81199bbcfbc6dec3ab7c8298c from w-nws.webmaster@noaa.gov',
    ],
    Sent: ['02:56 PDT on 10-19-2023'],
    Effective: ['10:00 PDT on 10-19-2023'],
    Expires: ['19:00 PDT on 10-21-2023'],
    Event: ['Beach Hazards Statement'],
    Alert: [
      '...BEACH HAZARDS STATEMENT REMAINS IN EFFECT FROM 10 AM PDT THIS MORNING THROUGH SATURDAY EVENING...  * WHAT...Surf 3-6 feet. Sets 7 to 8 feet in southern San Diego County.  * WHERE...San Diego County Coastal Areas.  * WHEN...From this morning through Saturday evening.  * IMPACTS...Increased surf will create strong and hazardous rip currents. Increased surf may bring localized minor tidal overflow into flood-prone beach parking lots during high tide on Friday.',
    ],
    Instructions: [
      'A beach hazards statement is issued when threats such as strongrip currents and other hazards are probable. Caution should beused when in or near the water.',
    ],
    'Target Area': [
      'San Diego County Coastal Areas',
      'San Diego County Coastal Areas',
    ],
    'Forecast Office': ['NWS SanDiego (Southwestern California)'],
  },
  {
    Message: [
      'NOAA-NWS-ALERTS-OR126666B50318.BeachHazardsStatement.126666C487C0OR.PQRCFWPQR.3c783725d57c0cf30db0cb8138f9855d from w-nws.webmaster@noaa.gov',
    ],
    Sent: ['03:30 PDT on 10-19-2023'],
    Effective: ['03:30 PDT on 10-19-2023'],
    Expires: ['05:00 PDT on 10-20-2023'],
    Event: ['Beach Hazards Statement'],
    Alert: [
      '...BEACH HAZARDS STATEMENT REMAINS IN EFFECT THROUGH LATE TONIGHT...  * WHAT...Very high threat of sneaker waves along the coast.  * WHERE...In Oregon, North Oregon Coast and Central Oregon Coast. In Washington, South Washington Coast.  * WHEN...Through late Thursday night.  * IMPACTS...Waves will run up significantly farther on a beach than normal, including over rocks and jetties. These sneaker waves can suddenly knock people off of their feet and quickly pull them into the cold ocean waters, resulting in serious injury or death. Waves may also lift driftwood logs, trapping anyone caught underneath.  * ADDITIONAL DETAILS...Elevated threat of sneaker waves will continue through Thursday as a very long period and energetic swell arrives from a distant Pacific storm.',
    ],
    Instructions: [
      'Remain out of the water to avoid hazardous swimming conditions.',
    ],
    'Target Area': [
      'Central Oregon Coast North Oregon Coast',
      'Central Oregon Coast North Oregon Coast',
    ],
    'Forecast Office': [
      'NWS Portland (Northwest Oregon and Southwest Washington)',
    ],
  },
  {
    Message: [
      'NOAA-NWS-ALERTS-WA126666B50318.BeachHazardsStatement.126666C487C0WA.PQRCFWPQR.f33f9b92b3da88ca4650ae32eec40620 from w-nws.webmaster@noaa.gov',
    ],
    Sent: ['03:30 PDT on 10-19-2023'],
    Effective: ['03:30 PDT on 10-19-2023'],
    Expires: ['05:00 PDT on 10-20-2023'],
    Event: ['Beach Hazards Statement'],
    Alert: [
      '...BEACH HAZARDS STATEMENT REMAINS IN EFFECT THROUGH LATE TONIGHT...  * WHAT...Very high threat of sneaker waves along the coast.  * WHERE...In Oregon, North Oregon Coast and Central Oregon Coast. In Washington, South Washington Coast.  * WHEN...Through late Thursday night.  * IMPACTS...Waves will run up significantly farther on a beach than normal, including over rocks and jetties. These sneaker waves can suddenly knock people off of their feet and quickly pull them into the cold ocean waters, resulting in serious injury or death. Waves may also lift driftwood logs, trapping anyone caught underneath.  * ADDITIONAL DETAILS...Elevated threat of sneaker waves will continue through Thursday as a very long period and energetic swell arrives from a distant Pacific storm.',
    ],
    Instructions: [
      'Remain out of the water to avoid hazardous swimming conditions.',
    ],
    'Target Area': ['South Washington Coast', 'South Washington Coast'],
    'Forecast Office': [
      'NWS Portland (Northwest Oregon and Southwest Washington)',
    ],
  },
];
