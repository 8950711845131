import React from 'react';
import { partition } from 'lodash';
import { useMap } from '@redzone/map';
import './SourcesList.scss';

function sortSourcesByRegion(sources) {
  const [nationalSources, otherSources] = partition(
    sources.data.features,
    (source) =>
      ['national', 'nationwide'].includes(
        source.properties.region?.toLowerCase(),
      ),
  );

  return {
    data: {
      features: [...nationalSources, ...otherSources],
    },
  };
}

export function SourcesList(props) {
  const { sources, checkedTypes, onEdit, onDelete } = props;
  const { map } = useMap();

  return (
    <ul className="sources-list">
      {sortSourcesByRegion(sources)
        .data.features.filter((f) => checkedTypes[f.properties.type])
        .map((source) => (
          <li className="sources-list-item" key={source.properties.id}>
            <div className="source-name">
              <a target="_blank" rel="noreferrer" href={source.properties.url}>
                {source.properties.name}
              </a>
              {source.properties.altUrl && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={source.properties.altUrl}
                >
                  {`Alt link: ${source.properties.altUrl}`}
                </a>
              )}
            </div>
            <div className="source-locale">
              <span>Region: </span>
              <span>{source.properties.region || '----'}</span>
            </div>
            <div className="source-locale">
              <span>State: </span>
              <span>{source.properties.state || '----'}</span>
            </div>
            <div className="source-locale">
              <span>County: </span>
              <span>{source.properties.county || '----'}</span>
            </div>
            {source.properties.notes && (
              <div className="source-notes">
                Notes: {source.properties.notes}
              </div>
            )}

            <div className="source-actions">
              {source.geometry.coordinates.length > 0 && (
                <button
                  className="standard"
                  type="button"
                  onClick={() => {
                    map.flyTo({
                      center: source.geometry.coordinates,
                      zoom: 12,
                    });
                  }}
                >
                  Show on map
                </button>
              )}
              <button
                className="standard"
                type="button"
                onClick={() => onEdit(source)}
              >
                Edit
              </button>
              <button
                className="standard"
                type="button"
                onClick={() => onDelete(source)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
    </ul>
  );
}
