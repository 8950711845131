/**
 * This keeps track of all the tokens and other stuff for BE integration at the
 * HTTP level.
 */

import axios from 'axios';

export function setAuth0IdToken(newToken) {
  localStorage.setItem('auth0IdToken', newToken);
  // window.location.reload();
}

export const authTransport = axios.create({
  baseURL: `${process.env.REACT_APP_USER_DOMAIN}/RZUSER00/v1/api`,
  withCredentials: true,
});

export const facadeTransport = axios.create({
  baseURL: `${process.env.REACT_APP_FACADE_DOMAIN}/v1/api`,
  withCredentials: true,
});
