const RATING_COLOR_HUES = [95, 0];
const RATING_COLOR_LIGHTNESSES = [0.4, 0.9];

function hslToRgb(hue, saturation, lightness) {
  const h = (hue < 0 ? 360 - hue : hue) % 360;
  const a = saturation * Math.min(lightness, 1 - lightness);
  const f = (n) => {
    const k = (n + h / 30) % 12;
    return lightness - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  };

  return [f(0), f(8), f(4)];
}

const getLuminance = ([r, g, b]) => r * 0.2126 + g * 0.7152 + b * 0.0722;

const getColorValues = (hue, lightness) => {
  const lightnessPct = lightness * 100;
  const rgb = hslToRgb(hue, 1, lightness);
  const luminance = getLuminance(rgb);

  const out = {
    background: `hsl(${hue}, 100%, ${lightnessPct}%)`,
    backgroundHsl: `${hue}, 100%, ${lightnessPct}%`,
    color: luminance < 0.55 ? '#ffffff' : '#000000',
    hue,
    lightness: lightnessPct,
    luminance,
    saturation: '100%',
  };

  return out;
};

export function getExposureColors(rating) {
  const ratingRatio = rating / 100.0;
  const [hueMin, hueMax] = RATING_COLOR_HUES;
  const [lightnessMin, lightnessMax] = RATING_COLOR_LIGHTNESSES;
  const hue = (hueMax - hueMin) * ratingRatio + hueMin;
  const lightness =
    lightnessMax - Math.abs(lightnessMax - lightnessMin) * ratingRatio;

  return getColorValues(hue, lightness);
}

export function getExposureCssColors(rating) {
  const exposureColors = getExposureColors(rating ?? 0);

  return {
    '--exposure-background-color': exposureColors.background,
    '--exposure-color': exposureColors.color,
    '--exposure-hue': exposureColors.hue.toString(),
    '--exposure-lightness': `${exposureColors.lightness}%`,
    '--exposure-saturation': exposureColors.saturation,
  };
}

// a wannabe hashing function that'll do for this
const weakHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) * i + ((hash << 5) - hash); // eslint-disable-line no-bitwise
    hash &= hash; // eslint-disable-line no-bitwise
  }
  return Math.abs(hash);
};

function getColorsFromString(str) {
  const hash = weakHash(str);
  const hue = hash % 360;
  const [lightnessMin, lightnessMax] = RATING_COLOR_LIGHTNESSES;
  const lightness =
    ((hash / 360) % Math.abs(lightnessMin - lightnessMax)) + lightnessMin;

  return getColorValues(hue, lightness);
}

export function getNewFireColors(fire) {
  return getColorsFromString(fire.incidentId);
}

export function getCssColorsFromString(str) {
  const exposureColors = getColorsFromString(str);

  return {
    '--exposure-background-color': exposureColors.background,
    '--exposure-color': exposureColors.color,
    '--exposure-hue': exposureColors.hue.toString(),
    '--exposure-lightness': `${exposureColors.lightness}%`,
    '--exposure-saturation': exposureColors.saturation,
  };
}
