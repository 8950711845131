import { Layer, Source } from '@redzone/map';
import distance from '@turf/distance';
import { keyBy, mapValues, uniq } from 'lodash';
import { useOutletContext } from 'react-router-dom';
import { useUser } from '../../../../../../context/UserContext.jsx';
import { DataCollection } from '../../../../../shared/tables/DataCollection.jsx';
import { DataTable } from '../../../../../shared/tables/DataTable.jsx';

const formatters = {
  tiv: (totalTiv) =>
    (totalTiv ?? 0).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  distance: (d) =>
    Intl.NumberFormat('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(d),
};

export function FireExposurePolicies() {
  const { fire } = useOutletContext();
  const { currentUser, policyLayers } = useUser();

  const policyLayerObj = mapValues(
    keyBy(policyLayers, (l) => l.mapboxSource.id),
    (s, id) => ({
      id,
      mapboxSource: s.mapboxSource,
      layers: [{ id, mapboxLayer: s.mapboxLayer }],
    }),
  );

  const { portfolioExposures } = fire;
  const allPolicies = portfolioExposures.flatMap((e) =>
    e.policyExposures.map((pe) => ({ ...pe.policy, distance: pe.distance })),
  );

  const getDistance = (center) =>
    distance(center, fire.center, { units: 'miles' });

  const columns = {
    ...(currentUser.organizations.length > 1
      ? {
          organization: {
            id: 'organization',
            title: 'Organization',
            dataType: 'enum',
            options: uniq(allPolicies.map((row) => row.organization.shortName).toSorted()),
            sortable: true,
            filterable: true,
            value: (row) => row.organization.shortName,
          },
        }
      : {}),
    address: {
      id: 'address',
      title: 'Address',
      value: (row) => row.location,
    },
    tiv: {
      id: 'tiv',
      title: 'TIV',
      dataType: 'number',
      sortable: true,
      filterable: true,
      value: (row) => row.tiv,
      format: formatters.tiv,
    },
    distance: {
      id: 'distance',
      title: 'Distance',
      dataType: 'number',
      sortable: true,
      filterable: true,
      value: (row) => getDistance([row.longitude, row.latitude]),
      format: formatters.distance,
    },
  };

  return (
    <DataCollection data={allPolicies} columns={columns}>
      {(policies) => (
        <>
          <div className="scroll">
            <DataTable data={policies} columns={columns} />
          </div>
          {Object.entries(policyLayerObj).map(([id, source]) => (
            <Source key={id} {...source.mapboxSource}>
              {source.layers.map((layer) => (
                <Layer
                  id={layer.id}
                  key={layer.id}
                  mapboxLayer={layer.mapboxLayer}
                  filter={[
                    'in',
                    ['get', 'id'],
                    ['literal', policies.map((p) => p.id)],
                  ]}
                />
              ))}
            </Source>
          ))}
        </>
      )}
    </DataCollection>
  );
}
