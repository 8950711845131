import { useEffect, useState } from 'react';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import { Popup } from '@redzone/map';
import './ModisViirsPopup.scss';

const formatDate = (d, devider) =>
  [d.slice(0, 4), devider, d.slice(4, 6), devider, d.slice(6)].join('');
const formatHours = (h, devider) =>
  [h.slice(0, 2), devider, h.slice(2)].join('');
const getValidDate = (d, h) =>
  parseISO([formatDate(d, '-'), 'T', formatHours(h, ':')].join(''));
const calcTimeAgo = (d, h) => {
  const sourceDate = getValidDate(d, h);
  return formatDistanceToNow(new Date(sourceDate));
};

export function ModisViirsPopup(props) {
  const { features, center, sourceType } = props;
  const [selectedFeatureIdx, setSelectedFeatureIdx] = useState(0);
  const selectedFeature = features[selectedFeatureIdx];

  useEffect(() => {
    setSelectedFeatureIdx(0);
  }, [features]);

  if (!features || !selectedFeature) return null;

  return (
    <Popup coordinates={center.coordinates}>
      <div className="popup standard modis-viirs-popup">
        <h3>
          {features.length > 1 && (
            <>
              <button
                type="button"
                className="prev-button"
                aria-label="previous fire"
                id="prev-fire-button"
                onClick={() => {
                  setSelectedFeatureIdx(
                    selectedFeatureIdx === 0
                      ? features.length - 1
                      : selectedFeatureIdx - 1,
                  );
                }}
              >
                &lt;
              </button>
              <div className="title">
                Source {selectedFeatureIdx + 1} of {features.length}
              </div>
              <button
                type="button"
                className="next-button"
                aria-label="next fire"
                id="next-fire-button"
                onClick={() => {
                  setSelectedFeatureIdx(
                    (selectedFeatureIdx + 1) % features.length,
                  );
                }}
              >
                &gt;
              </button>
            </>
          )}
        </h3>
        <h4>{`Satellite Hot Spot ${sourceType}`}</h4>
        <h4 className="secondary-header">{`Hot spot detected ${calcTimeAgo(
          selectedFeature.properties.ACQ_DATE,
          selectedFeature.properties.ACQ_TIME,
        )} ago`}</h4>
        <p className="header">{`Data Source: NASA ${sourceType}`}</p>
        <table>
          <tbody>
            <tr>
              <td className="label">Date</td>
              <td>
                {format(
                  getValidDate(selectedFeature.properties.ACQ_DATE, '0000'),
                  'P',
                )}
              </td>
            </tr>
            <tr>
              <td className="label">Time</td>
              <td>{formatHours(selectedFeature.properties.ACQ_TIME, ':')}</td>
            </tr>
            <tr>
              <td className="label">Confidence</td>
              <td>
                {Number(selectedFeature.properties.CONFIDENCE)
                  ? `${selectedFeature.properties.CONFIDENCE}%`
                  : selectedFeature.properties.CONFIDENCE}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Popup>
  );
}
