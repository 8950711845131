const orientations = {
  up: '-90deg',
  right: 0,
  down: '90deg',
  left: '180deg',
};

export function ChevronIcon(props) {
  const { className, orientation, ...rest } = props;
  const fullClassName = className
    ? `${className} chevron-icon standard`
    : 'cheveron-icon standard';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      className={fullClassName}
      style={{
        transform: `rotate(${orientations[orientation]})`,
      }}
      {...rest}
    >
      <g>
        <path d="M20 12l-2.83 2.83 9.17 9.17-9.17 9.17 2.83 2.83 12-12z" />
        <path d="M0 0h48v48h-48z" fill="none" />
      </g>
    </svg>
  );
}
