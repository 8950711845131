import { useEffect, useRef, useState, useMemo } from 'react';
import getCircle from '@turf/circle';
import getBbox from '@turf/bbox';
import intersects from '@turf/boolean-intersects';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useMapEffect, useMap, Source, Layer } from '@redzone/map';
import { CollectorValue } from './CollectorValue.jsx';
import './PolygonCollector.scss';

function InnerPolygonCollector(props) {
  const { collecting, toggleCollecting, onChange } = props;
  const { map } = useMap();

  const draw = useMemo(
    () =>
      new MapboxDraw({
        controls: {
          line_string: false,
          point: false,
          polygon: true,
          trash: true,
          combine_features: false,
          uncombine_features: false,
        },
        defaultMode: 'draw_polygon',
      }),
    [],
  );

  useEffect(() => {
    if (collecting) {
      map.addControl(draw);
    }

    return () => {
      if (collecting) {
        map.removeControl(draw);
      }
    };
  }, [collecting, draw, map]);

  const handleFinish = () => {
    const polygon = draw.getAll();
    onChange(polygon);
  };

  return (
    <div className="collector radius-collector">
      {collecting ? (
        <>
          <button type="button" className="redwall secondary" onClick={handleFinish}>
            Finish
          </button>
          <button type="button" className="redwall secondary" onClick={toggleCollecting}>
            Cancel
          </button>
          <div className="draw-instructions">
            Draw a polygon on the map, then finish by pressing enter and
            clicking the &quot;Finish&quot; button above.
          </div>
        </>
      ) : (
        <button type="button" className="redwall secondary" onClick={toggleCollecting}>
          Draw Polygon
        </button>
      )}
    </div>
  );
}

function InnerCircleCollector(props) {
  const { id, collecting, toggleCollecting, onChange } = props;
  const [radius, setRadius] = useState(10);
  const { map } = useMap();
  const radiusRef = useRef(10);

  radiusRef.current = radius;

  const sourceId = `${id}-circle-source`;
  const layerId = `${id}-circle-layer`;

  const source = {
    id: sourceId,
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  const handleMove = (e) => {
    const mapboxSource = map.getSource(sourceId);
    if (mapboxSource) {
      const center = [e.lngLat.lng, e.lngLat.lat];
      const circlePolygon = getCircle(center, radius, { units: 'miles' });
      const sourceData = {
        type: 'FeatureCollection',
        features: circlePolygon ? [circlePolygon] : [],
      };

      mapboxSource.setData(sourceData);
    }
  };

  const handleFinish = (e) => {
    const center = [e.lngLat.lng, e.lngLat.lat];
    const circlePolygon = getCircle(center, radiusRef.current, {
      units: 'miles',
    });
    const polygonFeatureSet = {
      type: 'FeatureCollection',
      features: [circlePolygon],
    };

    onChange(polygonFeatureSet);
  };

  useMapEffect(
    () => {
      map.on('mousemove', handleMove);
    },
    () => {
      map.off('mousemove', handleMove);
    },
    [radius],
  );

  return (
    <div className="collector radius-collector">
      <button
        type="button"
        className="redwall secondary collector-toggle-button"
        onClick={toggleCollecting}
      >
        {collecting ? 'Cancel' : 'Draw Circle'}
      </button>
      <label htmlFor="select-policies-radius">Radius (miles)</label>
      <input
        id="select-policies-radius"
        className="redwall secondary select-policies-radius"
        type="number"
        min="0"
        value={radius}
        onChange={(e) => setRadius(e.target.value)}
      />

      {collecting && (
        <Source {...source}>
          <Layer
            id={layerId}
            mapboxLayer={{
              id: layerId,
              type: 'fill',
              paint: {
                'fill-color': '#088',
                'fill-opacity': 0.2,
                'fill-outline-color': 'blue',
              },
            }}
            onClick={handleFinish}
          />
        </Source>
      )}
    </div>
  );
}

export function PolygonCollector(props) {
  const { id, onChange, featureLayers, value } = props;
  const [collector, setCollector] = useState(null);
  const { map } = useMap();

  if (!map) return null;

  const handleChange = (polygon) => {
    const bbox = getBbox(polygon);
    const mapboxBbox = [
      map.project([bbox[0], bbox[1]]),
      map.project([bbox[2], bbox[3]]),
    ];

    const boundedFeatures = featureLayers
      ? map.queryRenderedFeatures(mapboxBbox, {
          layers: featureLayers,
        })
      : [];
    const features = boundedFeatures.filter((f) => intersects(polygon, f));

    setCollector(null);
    onChange(polygon, features);
  };

  return (
    <div className="map-multi-collector">
      <InnerPolygonCollector
        id={`pc-polygon-${id}`}
        collecting={collector === 'polygon'}
        toggleCollecting={() => {
          setCollector(collector === 'polygon' ? null : 'polygon');
        }}
        onChange={handleChange}
      />
      <InnerCircleCollector
        id={`pc-circle-${id}`}
        collecting={collector === 'circle'}
        toggleCollecting={() => {
          setCollector(collector === 'circle' ? null : 'circle');
        }}
        onChange={handleChange}
      />

      {!collector && <CollectorValue id={`pc-value-${id}`} value={value} />}
    </div>
  );
}
