import { useState } from 'react';
import { Popup } from '@redzone/map';
import { useQuery } from '../../hooks/use-query.js';
import './PolicyPopup.scss';

const query = {
  type: 'policies',
  id: { $var: 'id' },
  properties: {
    id: 'id',
    location: 'location',
    tiv: 'tiv',
  },
};

export function PolicyPopup(props) {
  const { coordinates, policyFeatures, onClose } = props;
  const [selectedPolicyIdx, setSelectedPolicyIdx] = useState(0);
  const selectedPolicy = policyFeatures[selectedPolicyIdx];

  const { data, loading } = useQuery(query, {
    vars: {
      id: selectedPolicy.properties.policy_uuid,
    },
  });

  if (loading) return null;

  return (
    <Popup
      coordinates={coordinates}
      onClose={() => {
        setSelectedPolicyIdx(0);
        onClose();
      }}
    >
      <div className="component policy-popup">
        {loading ? (
          <div className="loading" />
        ) : (
          <>
            <h3>
              {policyFeatures.length > 1 && (
                <button
                  type="button"
                  className="prev-button"
                  aria-label="previous policy"
                  id="prev-policy-button"
                  onClick={() => {
                    setSelectedPolicyIdx(
                      selectedPolicyIdx === 0
                        ? policyFeatures.length - 1
                        : selectedPolicyIdx - 1,
                    );
                  }}
                >
                  &lt;
                </button>
              )}
              <div className="title">
                Policy {selectedPolicyIdx + 1} of {policyFeatures.length}
              </div>
              {policyFeatures.length > 1 && (
                <button
                  type="button"
                  className="next-button"
                  aria-label="next policy"
                  id="next-policy-button"
                  onClick={() => {
                    setSelectedPolicyIdx(
                      (selectedPolicyIdx + 1) % policyFeatures.length,
                    );
                  }}
                >
                  &gt;
                </button>
              )}
            </h3>
            <table>
              <tbody>
                <tr>
                  <td>Location</td>
                  <td>{data.location}</td>
                </tr>
                <tr>
                  <td>TIV</td>
                  <td>{data.tiv}</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    </Popup>
  );
}
