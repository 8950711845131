import { Layer, Source } from '@redzone/map';
import { groupBy, mapValues, pick } from 'lodash';
import { useState } from 'react';
import { useDashboardState } from '../../context/DashboardContext.jsx';
import { useUser } from '../../context/UserContext.jsx';
import { pipeThru } from '../../utils.js';
import { PolicyPopup } from './PolicyPopup.jsx';

export function PolicyLayers() {
  const [selectedLayers] = useDashboardState('selectedLayers');
  const { policyLayers: allPolicyLayers } = useUser();
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [selectedPolicyFeatures, setSelectedPolicyFeatures] = useState([]);
  const selectedPolicyFeature = selectedPolicyFeatures[0];

  const policyLayers = pipeThru(allPolicyLayers, [
    (layers) => groupBy(layers, (layer) => layer.layerId),
    (layers) => pick(layers, selectedLayers.policy),
    (layerObjs) =>
      mapValues(layerObjs, (layers) =>
        layers.map((layer) => ({ ...layer, id: layer.layerId })),
      ),
  ]);

  return (
    <>
      {Object.entries(policyLayers).map(([sourceId, layers]) => (
        <Source
          key={sourceId}
          id={sourceId}
          {...layers[0].mapboxSource}
          passive
        >
          {layers.map((layer) => (
            <Layer
              key={layer.id}
              id={layer.id}
              mapboxLayer={layer.mapboxLayer}
              stopEventPropagation
              onClick={(e) => {
                setSelectedCoordinates([e.lngLat.lng, e.lngLat.lat]);
                setSelectedPolicyFeatures(e.features);
              }}
              onClose={() => {
                setSelectedCoordinates(null);
                setSelectedPolicyFeatures([]);
              }}
            />
          ))}
        </Source>
      ))}
      {selectedPolicyFeature && (
        <PolicyPopup
          policyFeatures={selectedPolicyFeatures}
          coordinates={selectedCoordinates}
          onClose={() => {
            setSelectedCoordinates(null);
            setSelectedPolicyFeatures([]);
          }}
        />
      )}
    </>
  );
}
