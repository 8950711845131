import { NavLink, useMatches, useNavigate } from 'react-router-dom';
import { useDashboard } from '../../context/DashboardContext.jsx';
import { getExposureCssColors } from '../../utils/exposure-colors.js';
import { CloseButtonIcon } from '../icons/CloseButtonIcon.jsx';
import './PerilTabs.scss';

export function PerilTabs() {
  const { perilTabs, removePerilTab } = useDashboard();
  const matches = useMatches();
  const navigate = useNavigate();

  const closeTab =
    ({ perilType, perilId }) =>
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      const shouldRedirect = matches.some(
        (match) => match.params?.perilId === perilId,
      );
      removePerilTab(perilTabs.find((pt) => pt.peril.incidentId === perilId));

      if (shouldRedirect) {
        navigate(`./${perilType}`, { relative: true });
      }
    };

  const perilType =
    matches.find((match) => match?.handle?.perilType)?.handle?.perilType ?? '';

  return (
    <div className="peril-tabs">
      <div
        className={`pinned-perils${
          perilType ? ` active-peril-${perilType}` : ''
        }`}
      >
        <NavLink end to={perilType} className="peril-list peril-tab">
          {perilType}
        </NavLink>
        {perilTabs.map(({ exposure, path, peril }) => (
          <NavLink
            key={peril.incidentId}
            to={`${path}/${peril.incidentId}`}
            className={`peril peril-tab ${path}`}
            style={exposure ? getExposureCssColors(exposure) : {}}
          >
            <div className="peril-name"> {peril.incidentName}</div>
            <button
              type="button"
              className="redwall unstyled close-button"
              onClick={closeTab({
                perilType: path,
                perilId: peril.incidentId,
              })}
            >
              <CloseButtonIcon />
            </button>
          </NavLink>
        ))}
      </div>
    </div>
  );
}
