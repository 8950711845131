import { FormattedMessage } from 'react-intl';
import { MAP_STYLES, useMap } from '@redzone/map';
import './BaseLayer.scss';
import { MAPBOX_TOKEN } from '../../../constants/map-constants.js';
import { useDashboardState } from '../../../context/DashboardContext.jsx';
import { classStr } from '../../../helpers/helpers.js';

const getImageSource = (layer, { lat, lng, zoom }) =>
  `https://api.mapbox.com/styles/v1/mapbox/${layer}/static/${lng},${lat},${zoom}/155x110?access_token=${MAPBOX_TOKEN}`;

export function BaseLayer(props) {
  const [baseLayer, setBaseLayer] = useDashboardState('baseLayer');
  const { setMapStyle } = useMap();

  return (
    <div className="base-layer">
      {Object.entries(MAP_STYLES).map(([id, { layer }]) => {
        const imageSource = getImageSource(layer, {
          baseLayer: MAP_STYLES[layer],
          lat: 40,
          lng: -114,
          zoom: 4,
        });

        return (
          <div key={id} className="card">
            <button
              className={classStr({
                standard: true,
                'card-button': true,
                selected: baseLayer === id,
              })}
              onClick={() => {
                setBaseLayer(id);
                setMapStyle(MAP_STYLES[id].url);
              }}
              type="button"
            >
              <div className="card-title">
                <FormattedMessage
                  id={`tool_drawers.layer_drawer.map_styles.${id}`}
                />
              </div>
              <div className="card-image-container">
                <FormattedMessage
                  id={`tool_drawers.layer_drawer.map_styles.${id}`}
                >
                  {(msg) => <img src={imageSource} alt={msg} />}
                </FormattedMessage>
              </div>
            </button>
          </div>
        );
      })}
    </div>
  );
}

BaseLayer.defaultProps = {
  basemaps: null,
};

// import { FormattedMessage } from 'react-intl';
// import { MAP_STYLES, useMap } from '@redzone/map';
// import './BaseLayers.scss';
// import { MAPBOX_TOKEN } from '../../../constants/map-constants.js';

// const getImageSource = (layer, { lat, lng, zoom }) =>
//   `https://api.mapbox.com/styles/v1/mapbox/${layer}/static/${lng},${lat},${zoom}/155x110?access_token=${MAPBOX_TOKEN}`;

// export function BaseLayers(props) {
//   const { map, mapStyle, setMapStyle } = useMap();

//   return (
//     <div className="base-layers">
//       {Object.entries(MAP_STYLES).map(([id, { imageSlug }]) => {
//         const imageSource = getImageSource(imageSlug, {
//           ...map.getCenter(),
//           zoom: map.getZoom(),
//         });

//         return (
//           <div key={id} className="card">
//             <button
//               className={`standard card-button${
//                 mapStyle === id ? ' selected' : ''
//               }`}
//               onClick={() => {
//                 setMapStyle(id);
//               }}
//               type="button"
//             >
//               <div className="card-title">
//                 <FormattedMessage
//                   id={`tool_drawers.layer_drawer.map_styles.${id}`}
//                 />
//               </div>
//               <div className="card-image-container">
//                 <FormattedMessage
//                   id={`tool_drawers.layer_drawer.map_styles.${id}`}
//                 >
//                   {(msg) => <img src={imageSource} alt={msg} />}
//                 </FormattedMessage>
//               </div>
//             </button>
//           </div>
//         );
//       })}
//     </div>
//   );
// }
