import { FormattedMessage } from 'react-intl';
import { useRef, useState } from 'react';
import { pick } from 'lodash';
import { useMatches, useNavigate } from 'react-router-dom';
import { Dropdown } from '@redzone/shared';
import { useUser } from '../../context/UserContext.jsx';
import { StaticImage } from '../shared/StaticImage.jsx';
import './Header.scss';

// permission -> value
const perilOptions = {
  'rz:alertdashboard:fires:view': { value: 'fires', label: 'Wildfires' },
  'rz:alertdashboard:hurricanes:view': {
    value: 'hurricanes',
    label: 'Hurricanes',
  },
  'rz:alertdashboard:earthquakes:view': {
    value: 'earthquakes',
    label: 'Earthquakes',
  },
};

export function Header() {
  const { currentUser, logout } = useUser();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState();
  const menuRef = useRef();
  const matches = useMatches();
  const navigate = useNavigate();

  const perilType =
    matches.find((match) => match?.handle?.perilType)?.handle?.perilType ?? '';

  const availablePerils = Object.values(
    pick(perilOptions, currentUser.permissions),
  );

  const closeFn = (ev) => {
    if (menuRef.current && !menuRef.current.contains(ev.target)) {
      setIsUserMenuOpen(false);
      document.removeEventListener('mousedown', closeFn);
    }
  };

  const toggleMenu = () => {
    if (isUserMenuOpen) {
      setIsUserMenuOpen(false);
    } else {
      setIsUserMenuOpen(true);
      document.addEventListener('mousedown', closeFn);
    }
  };

  const navigatePerilType = (value) => {
    navigate(`./${value}`, { relative: true });
  };

  return (
    <header className="header-container dark-theme">
      <StaticImage
        className="redzone-logo"
        src="/images/logos/rz_logo_200_wt.png"
        alt="RedZone Logo"
      />
      <div className="peril-type-container">
        {availablePerils.length > 1 ? (
          <Dropdown
            namespace="perils"
            onChange={navigatePerilType}
            value={perilType}
            options={availablePerils}
            wide
          />
        ) : (
          <h2>{availablePerils?.[0]?.label}</h2>
        )}
      </div>
      <div
        className={`user-menu-container ${isUserMenuOpen ? 'open' : 'closed'}`}
        ref={menuRef}
      >
        <button
          type="button"
          className="standard current-user"
          onClick={toggleMenu}
        >
          {currentUser.email} ▼
        </button>
        <div className="user-menu-contents-container">
          <div className="user-menu">
            <button
              type="button"
              className="standard logout-button"
              onClick={logout}
            >
              <FormattedMessage id="header.logout" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
