import { useOutletContext } from 'react-router-dom';
import { shortCompactLocalizeNumber } from '../../../../../utils/formatter.js';
import { EnvelopeIcon } from '../../../../icons/EnvelopeIcon.jsx';
import { DownloadIcon } from '../../../../icons/DownloadIcon.jsx';
import './HurricaneOverview.scss';

export function HurricaneOverview() {
  const { hurricane } = useOutletContext();
  /* NOTE(Baad): defensive coding since the data is not coming in right.
  will fix when the API shows up. */
  const exposedPolicies = hurricane.portfolioExposures.flatMap((e) =>
    e.policyExposures.map((pe) => pe.policy),
  );
  const tivTotal = exposedPolicies.reduce((x, y) => x + (y.tiv ?? 0), 0);
  const maxTiv = exposedPolicies.reduce(
    (max, p) => Math.max(max, p.tiv ?? 0),
    0,
  );
  const minTiv = exposedPolicies.reduce(
    (min, p) => Math.min(min, p.tiv ?? 0),
    0,
  );

  return (
    <div className="hurricane-summary">
      <div className="hurricane-header">
        <button
          className="standard hurricane-overview-button"
          type="button"
          onClick={() => alert('send email!')}
        >
          <EnvelopeIcon /> <span>email report</span>
        </button>
      </div>

      <ul className="standard summary-container">
        <li className="cat">
          <div className="stat">{hurricane.category}</div>
          <div className="label">category</div>
        </li>
        <li className="depth">
          <div className="stat">{hurricane.currentWindSpeed ?? '-'}</div>
          <div className="label">wind speed</div>
        </li>
        <li className="tsunami">
          <div className="stat">{hurricane.zone ?? '-'}</div>
          <div className="label">Zone ??</div>
        </li>
        <li className="policyCount">
          <div className="stat">{exposedPolicies.length}</div>
          <div className="label">policy count</div>
        </li>
        <li className="tiv">
          <div className="stat">${shortCompactLocalizeNumber(tivTotal)}</div>
          <div className="label">tiv</div>
        </li>
        <li className="tiv-range">
          <div className="stat">
            ${shortCompactLocalizeNumber(minTiv)} - $
            {shortCompactLocalizeNumber(maxTiv)}
          </div>
          <div className="label">TIV range</div>
        </li>
      </ul>

      <div className="earthquake-downloads">
        <button
          className="standard eathquake-overview-button"
          type="button"
          onClick={() => alert('download Tariff')}
        >
          <DownloadIcon /> <span>Tariff</span>
        </button>
        <button
          className="standard eathquake-overview-button"
          type="button"
          onClick={() => alert('download Exposure')}
        >
          <DownloadIcon /> <span>Exposure</span>
        </button>
      </div>
    </div>
  );
}
