import { Outlet, useLoaderData } from 'react-router-dom';
import { queryLoader } from '../../../../../hooks/use-query.js';
import { Tab, TabLabels } from '../../../../shared/Tabs.jsx';
import './FireTriage.scss';

export const LABELS = {
  org: 'Organization',
  activeAssignmentsCount: 'Active Assignments',
  assignedPoliciesCount: 'Assigned Policies',
  visitationsCount: 'visitations',
  damagesCount: 'Damages',
};
const query = {
  type: 'fires',
  id: { $var: 'id' },
  properties: {
    center: 'center',
    assignments: {
      properties: {
        id: 'id',
        createdAt: 'createdAt',
        engine: {
          properties: {
            engineName: 'engineName',
          },
        },
        status: 'status',
        assignmentArea: 'assignmentArea',
        visitations: {
          properties: {
            id: 'id',
            location: 'location',
            createdAt: 'createdAt',
            visitCount: 'visitCount',
            record: 'record',
            engine: {
              properties: {
                id: 'id',
                engineName: 'engineName',
              },
            },
            policy: {
              properties: {
                id: 'id',
                location: 'location',
                latitude: 'latitude',
                longitude: 'longitude',
                tiv: 'tiv',
              },
            },
          },
        },
        organization: {
          properties: {
            id: 'id',
            displayName: 'displayName',
          },
        },
      },
    },
  },
};

export async function loader({ params }) {
  const vars = { id: params.perilId };
  return queryLoader(query, { vars });
}

export function FireTriage() {
  const fire = useLoaderData();

  return (
    <>
      <TabLabels className="secondary">
        <Tab label="Summary" path="summary" />
        <Tab label="Assignments" path="assignments" />
        <Tab label="Engines" path="engines" />
        <Tab label="Visitations" path="visitations" />
        <Tab label="Policies" path="policies" />
      </TabLabels>
      <div className="triage-tab stacked">
        <Outlet context={{ fire }} />
      </div>
    </>
  );
}
