import { createContext, useContext, useMemo } from 'react';
import { HoverTooltip } from '@redzone/shared';
import { formatSchemaProperty } from '../../utils.js';
import './ResourceTable.scss';

const ResourceTableContext = createContext({});

function FormatUrl({ val }) {
  return (
    <a href={val} target="_blank" rel="noreferrer">
      {val}
    </a>
  );
}

export function ResourceRow(props) {
  const { property, value } = props;
  const { resource, schema } = useContext(ResourceTableContext);
  const { description, format, title, type } = schema.properties[property];
  const valueTypeClass = format ?? type ?? 'string';

  const calculatedValue =
    value ??
    (resource[property]
      ? formatSchemaProperty(schema, property, resource[property])
      : schema.properties[property].default) ??
    null;

  return (
    <tr>
      <td className="title">{title ?? property}</td>
      <td className={`value ${valueTypeClass}`}>
        {typeof calculatedValue === 'object' &&
        calculatedValue?.type === 'url' ? (
          <FormatUrl val={calculatedValue.value} />
        ) : (
          calculatedValue
        )}
      </td>
      <td className="description">
        {description && <HoverTooltip title="ⓘ">{description}</HoverTooltip>}
      </td>
    </tr>
  );
}

export function ResourceTable(props) {
  const { children, resource, schema } = props;
  const value = useMemo(() => ({ resource, schema }), [resource, schema]);

  return (
    <ResourceTableContext.Provider value={value}>
      <table className="standard resource-table">
        <tbody>{children}</tbody>
      </table>
    </ResourceTableContext.Provider>
  );
}
