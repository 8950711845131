import { useEffect, useState } from 'react';
import { store } from '../stores/store.js';

export function useQuery(query, options = {}) {
  const { vars = {} } = options;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const compiledQuery = store.compileQuery(query);

  const runQuery = async () => {
    setData(null);
    setError(null);
    setLoading(true);

    try {
      const result = await compiledQuery(vars);
      setData(result);
    } catch (err) {
      console.error('error!', err);
      setError(
        err?.response?.data?.errors?.map((e) => e.message) ??
          err?.message ??
          err,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    runQuery({});
  }, [JSON.stringify(options)]);

  return { data, error, loading, runQuery };
}

export async function queryLoader(query, options = {}) {
  const { vars = {} } = options;
  const compiledQuery = store.compileQuery(query);

  return compiledQuery(vars);
}
