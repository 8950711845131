const transformKeyToCamelCase = (k) =>
  k
    .split(' ')
    .map((item, index) => {
      if (index % 2 === 0) return item.toLowerCase();
      return `${item.charAt(0).toUpperCase()}${item
        .substring(1)
        .toLowerCase()}`;
    })
    .join('');

export function transformObjCamelCase(obj) {
  const newObj = {};
  Object.keys(obj).forEach((k) => {
    newObj[transformKeyToCamelCase(k)] = obj[k];
  });

  return newObj;
}
