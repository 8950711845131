import { orderBy } from 'lodash';
import { useState } from 'react';
import { classStr } from '../../../helpers/helpers.js';
import { ThreeDotsIcon } from '../../icons/ThreeDotsIcon.jsx';
import { SortArrowsIcon } from '../../icons/SortArrowsIcon.jsx';
import { ActionMenu } from '../ActionMenu.jsx';
import './DataTable.scss';

function SortColumnHeader(props) {
  const { column, sort, setSort } = props;
  const { dataType, id, title } = column;

  if (!id) {
    throw new Error('DataTable columns must include an id');
  }

  return (
    <th className={dataType}>
      <button
        type="button"
        className={classStr({
          standard: true,
          'sort-button': true,
          sorting: sort.property === id,
          asc: sort.direction === 'asc',
          desc: sort.direction === 'desc',
        })}
        onClick={() => {
          setSort(
            sort.property === column.id
              ? sort.direction === 'asc'
                ? { property: id, direction: 'desc' }
                : {}
              : { property: id, direction: 'asc' },
          );
        }}
      >
        <div className="title">{title}</div>
        <SortArrowsIcon className="sort-arrows" />
      </button>
    </th>
  );
}

export function DataTable(props) {
  const {
    className,
    columns,
    data: rawData,
    rowActions,
    rowKey,
    rowProps,
  } = props;

  const [sort, setSort] = useState([{}]);
  const getKey = rowKey ?? ((row) => row.key ?? row.id);
  const getRowProps = rowProps ?? (() => ({}));

  const sortFn = sort?.property
    ? (data) => orderBy(data, [columns[sort.property].value], [sort.direction])
    : (data) => data;

  const data = sortFn(rawData);

  return (
    <div className="data-table-container table-positioner scroll">
      <table className={className ? `${className} data-table` : 'data-table'}>
        <thead>
          <tr>
            {Object.entries(columns).map(([colKey, column]) =>
              column.sortable ? (
                <SortColumnHeader
                  column={{ id: colKey, ...column }}
                  sort={sort}
                  setSort={setSort}
                  key={column.key ?? column.title}
                />
              ) : (
                <th
                  className={column.dataType}
                  key={column.key ?? column.title}
                >
                  {column.title}
                </th>
              ),
            )}
            {rowActions?.length > 0 && <th className="actions">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((datum, idx) => (
            <tr key={getKey(datum)} {...getRowProps(datum, idx)}>
              {Object.entries(columns).map(([key, column]) => (
                <td key={key} className={column.dataType}>
                  {column.format
                    ? column.format(column.value(datum))
                    : column.value(datum)}
                </td>
              ))}
              {rowActions?.length > 0 && (
                <td className="actions">
                  <ActionMenu title={<ThreeDotsIcon />} actions={rowActions} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
