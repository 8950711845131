import axios from 'axios';
import { MAPBOX_TOKEN } from '../../constants/map-constants.js';

export const reverseGeocode = async ({ lng, lat }) => {
  const countries = ['US']; // TODO
  // const language = intl.locale === 'ja' ? 'ja' : 'en';
  const language = 'en';

  const response = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`,
    {
      params: {
        access_token: MAPBOX_TOKEN,
        country: countries.join(','),
        language,
        limit: 1,
        types: 'region,district,place,locality',
      },
    },
  );

  const feature = response?.data?.features?.[0];
  if (!feature) return {};

  const placeName = feature.placeName ?? feature.place_name;
  const getLocationTypeValue = (type) =>
    feature.context?.find((item) => item.id?.split('.')?.[0] === type)?.text ??
    '';

  const city = placeName?.split(',')?.[0];
  const county =
    getLocationTypeValue('district')?.replace(/ County$/, '') ?? '';
  const state = getLocationTypeValue('region') ?? '';

  return {
    ...feature,
    city,
    county,
    state,
  };
};
