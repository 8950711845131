import { keyBy, omit } from 'lodash';
import { cloneElement, Children, useState } from 'react';
import { classStr } from '../../../helpers/helpers.js';
import { ThreeDotsIcon } from '../../icons/ThreeDotsIcon.jsx';
import { SortArrowsIcon } from '../../icons/SortArrowsIcon.jsx';
import { ActionMenu } from '../ActionMenu.jsx';
import { DataCollection } from './DataCollection.jsx';
import './DataTable.scss';

const columnProps = [
  'dataType',
  'datum',
  'element',
  'filterable',
  'formattedValue',
  'rowProps',
  'sortable',
  'title',
  'value',
];

export function Column(props) {
  const { datum, element, formattedValue, title, value, ...rest } = props;

  return (
    <td {...omit(rest, columnProps)}>
      {element
        ? cloneElement(element, { datum })
        : (formattedValue ?? value)(datum)}
    </td>
  );
}

function SortColumnHeader(props) {
  const { column, sort, setSort } = props;
  const { id, title } = column.props;

  const columnSort = sort.length === 1 ? sort[0] : {};

  return (
    <th {...omit(column.props, columnProps)}>
      <button
        type="button"
        className={classStr({
          standard: true,
          'sort-button': true,
          sorting: columnSort.property === id,
          asc: columnSort.direction === 'asc',
          desc: columnSort.direction === 'desc',
        })}
        onClick={() => {
          setSort(
            columnSort.property === column.props.id
              ? columnSort.direction === 'asc'
                ? [{ property: id, direction: 'desc' }]
                : []
              : [{ property: id, direction: 'asc' }],
          );
        }}
      >
        <div className="title">{title}</div>
        <SortArrowsIcon className="sort-arrows" />
      </button>
    </th>
  );
}

export function DataCollectionTable(props) {
  const { className, rowActions, rowKey, rowProps, ...rest } = props;
  const { children } = props;

  const [sort, setSort] = useState([]);

  const columns = Children.toArray(children);
  if (!columns.every((column) => column.type === Column)) {
    throw new Error('All children of `DataTable` must be of type `Column`.');
  }

  const columnPropsById = keyBy(
    columns.map((c) => c.props),
    (c) => c.id,
  );

  const getKey = rowKey ?? ((row) => row.key ?? row.id);
  const getRowProps = rowProps ?? (() => ({}));

  return (
    <div className="data-table-container stacked">
      <DataCollection
        {...rest}
        columns={columnPropsById}
        sort={sort}
        setSort={setSort}
      >
        {(data) => (
          <div className="table-positioner stacked">
            <table
              className={className ? `${className} data-table` : 'data-table'}
            >
              <thead>
                <tr>
                  {columns.map((column) =>
                    column.props.sortable ? (
                      <SortColumnHeader
                        column={column}
                        sort={sort}
                        setSort={setSort}
                        key={column.props.key ?? column.props.title}
                      />
                    ) : (
                      <th
                        {...omit(column.props, columnProps)}
                        key={column.props.key ?? column.props.title}
                      >
                        {column.props.title}
                      </th>
                    ),
                  )}
                  {rowActions?.length > 0 && (
                    <th className="actions">Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.map((datum, idx) => (
                  <tr key={getKey(datum)} {...getRowProps(datum, idx)}>
                    {columns.map((column) =>
                      cloneElement(column, {
                        ...omit(column.props, columnProps),
                        datum,
                        key: column.props.key ?? column.props.title,
                      }),
                    )}
                    {rowActions?.length > 0 && (
                      <td className="actions">
                        <ActionMenu
                          title={<ThreeDotsIcon />}
                          actions={rowActions}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </DataCollection>
    </div>
  );
}
