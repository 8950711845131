export function ThreeDotsIcon({ orientation = 'vertical' }) {
  const rotation = orientation === 'horizontal' ? 'rotate(90 8 8)' : '';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
        transform={rotation}
      />
    </svg>
  );
}
