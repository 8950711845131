import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { sortBy } from 'lodash';
import { useStateList } from '../hooks/use-state-list.js';
import { useUser } from './UserContext.jsx';

const DashboardContext = createContext();

export function useDashboard() {
  return useContext(DashboardContext);
}

export function useDashboardState(id) {
  const { dashboardState, dashboardStateRef, setDashboardState } =
    useContext(DashboardContext);

  const setState = (nextVal) => {
    const nextRootVal = { ...dashboardStateRef.current, [id]: nextVal };
    setDashboardState(nextRootVal);
    dashboardStateRef.current = nextRootVal;
  };

  if (!(id in dashboardStateRef.current)) {
    throw new Error(
      `the provided state key (${id}) is not known to dashboard -- please check the dashboard context and create the key or check your spelling`,
    );
  }

  return [dashboardState[id], setState];
}

// this is kind of a "grab bag" of state that gets used acrosss the dashboard.
// it is not meant to hold any state other than UI stuff. use it to open and
// close tool drawers, add tabs to the navigation, or the like. things that
// come and go during a single session. do NOT use it for any data entered by
// the user, or that will need to be saved.
export function DashboardProvider(props) {
  const { children } = props;
  const { currentUser } = useUser();

  const sortedOrgs = sortBy(
    currentUser.organizations.filter((org) => org.policyLayers.length > 0),
    (org) => org.displayName,
  );

  const INITIAL_DASHBOARD_STATE = {
    baseLayer: 'standard',
    firesFirstLoad: true,
    importedLayers: [],
    selectedLayers: {
      imported: [],
      policy: sortedOrgs.flatMap((layer) =>
        layer.policyLayers.flatMap((pLayer) => pLayer.layerId),
      ),
      public: ['wf_perimeter'],
    },
  };

  const [perilTabs, { add: addPerilTab, remove: removePerilTab }] =
    useStateList([], ({ peril }) => peril.incidentId);
  const [isContentDrawerOpen, setIsContentDrawerOpen] = useState(true);
  const [isToolDrawerOpen, setIsToolDrawerOpen] = useState(false);
  const [visiblePublicLayers, setVisiblePublicLayers] = useState([]);

  const [dashboardState, setDashboardState] = useState(INITIAL_DASHBOARD_STATE);
  const dashboardStateRef = useRef();
  dashboardStateRef.current = dashboardState;

  const value = useMemo(
    () => ({
      addPerilTab,
      isContentDrawerOpen,
      perilTabs,
      removePerilTab,
      setIsContentDrawerOpen,
      isToolDrawerOpen,
      setIsToolDrawerOpen,
      dashboardState,
      dashboardStateRef,
      setDashboardState,
      visiblePublicLayers,
      setVisiblePublicLayers,
    }),
    [
      addPerilTab,
      isContentDrawerOpen,
      perilTabs,
      removePerilTab,
      setIsContentDrawerOpen,
      isToolDrawerOpen,
      dashboardState,
      dashboardStateRef,
      setDashboardState,
      visiblePublicLayers,
      setVisiblePublicLayers,
    ],
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}
