import { useEffect } from 'react';
import { useMap } from '@redzone/map';
import { useLoaderData } from 'react-router-dom';
import { RouteTabs, Tab } from '../../../shared/Tabs.jsx';
import { getExposureCssColors } from '../../../../utils/exposure-colors.js';
import { queryLoader } from '../../../../hooks/use-query.js';
import '../Peril.scss';
import { useDashboard } from '../../../../context/DashboardContext.jsx';

const query = {
  type: 'hurricanes',
  id: { $var: 'id' },
  properties: {
    incidentId: 'incidentId',
    incidentName: 'incidentName',
    lastScrape: 'lastScrape',
    category: 'category',
    currentLocation: 'currentLocation',
    currentDirection: 'currentDirection',
    currentMovementSpeed: 'currentMovementSpeed',
    currentWindSpeed: 'currentWindSpeed',
    directionAbbreviation: 'directionAbbreviation',
    portfolioExposures: {
      properties: {
        rating: 'rating',
        policyExposures: {
          properties: {
            policy: { properties: { tiv: 'tiv' } },
          },
        },
      },
    },
    longitude: 'longitude',
    latitude: 'latitude',
  },
};

export async function loader({ params }) {
  const vars = { id: params.perilId };
  return queryLoader(query, { vars });
}

export function Hurricane() {
  const data = useLoaderData();
  const { addPerilTab } = useDashboard();
  const { map } = useMap();

  const hurricane = {
    ...data,
  };

  const rating = hurricane.portfolioExposures.reduce(
    (max, e) => Math.max(max, e.rating),
    0,
  );

  useEffect(() => {
    addPerilTab({ path: 'hurricanes', peril: hurricane, exposure: rating });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hurricane.incidentId]);

  useEffect(() => {
    const { longitude, latitude } = hurricane ?? {};
    const offset = [200, 0];

    if (latitude && longitude && map) {
      map.flyTo({ center: [longitude, latitude], offset, zoom: 7 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hurricane.incidentId, map]);

  return (
    <div
      className="peril-details hurricane-details drawer"
      style={rating > 0 ? getExposureCssColors(rating) : {}}
    >
      <header>
        <h1 className="title hurricane-name">{hurricane.incidentName}</h1>
      </header>
      <RouteTabs className="dark-theme" context={{ hurricane }}>
        <Tab label="Feed" path="feed" />
        <Tab label="Overview" path="overview" />
        <Tab label="Metrics" path="metrics" />
      </RouteTabs>
    </div>
  );
}
