import { FormattedMessage } from 'react-intl';
import { Link, useLoaderData } from 'react-router-dom';
import { schemaLabel } from '../../../../schema-utils.js';
import { getExposureCssColors } from '../../../../utils/exposure-colors.js';
import { PerilBadges, PerilList } from '../PerilList.jsx';
import { queryLoader } from '../../../../hooks/use-query.js';
import './HurricaneList.scss';
import { dashboardSchema } from '../../../../stores/store.js';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

const badgeDefinitions = {
  exposed: {
    test: (hurricane) => hurricane.portfolioExposures.some((e) => e.rating > 0),
    text: 'exposed',
  },
};

const columnDefinitions = {
  incidentId: { value: (hurricane) => hurricane.incidentId },
  incidentName: {
    title: 'Incident Name',
    searchable: true,
    sortable: true,
    value: (hurricane) => hurricane.incidentName,
  },
  lastScrapeTs: {
    title: 'Last Updated',
    dataType: 'date-time',
    sortable: true,
    filterable: true,
    value: (hurricane) => hurricane.lastScrapeTs,
  },
  category: {
    title: 'Category',
    dataType: 'number',
    sortable: true,
    filterable: true,
    value: (hurricane) => hurricane.category,
  },
  maxExposure: {
    title: 'Exposure',
    dataType: 'number',
    sortable: true,
    filterable: true,
    value: (hurricane) =>
      hurricane.portfolioExposures.reduce(
        (max, e) => Math.max(max, e.rating),
        0,
      ),
  },
};

const query = {
  type: 'hurricanes',
  properties: {
    incidentId: 'incidentId',
    incidentName: 'incidentName',
    lastScrape: 'lastScrape',
    category: 'category',
    currentLocation: 'currentLocation',
    currentDirection: 'currentDirection',
    currentMovementSpeed: 'currentMovementSpeed',
    currentWindSpeed: 'currentWindSpeed',
    directionAbbreviation: 'directionAbbreviation',
    portfolioExposures: {
      properties: {
        rating: 'rating',
      },
    },
  },
};

export function loader() {
  return queryLoader(query);
}

export function Hurricane(props) {
  const { peril: hurricane } = props;

  return (
    <li
      key={hurricane.incidentId}
      className="peril hurricane"
      style={
        hurricane.maxExposure > 0
          ? getExposureCssColors(hurricane.maxExposure)
          : {}
      }
    >
      <div className="peril-name-container hurricane-name-container hurricane-row">
        <h1 className="peril-name hurricane-name">
          <Link to={hurricane.incidentId} className="standard">
            {hurricane.incidentName}
          </Link>
        </h1>
        <PerilBadges peril={hurricane} badgeDefinitions={badgeDefinitions} />
      </div>
      <div className="stats">
        <div className="updated">
          <div className="label">
            <FormattedMessage id="dashboard.perils.hurricanes.updated" />{' '}
          </div>
          <div className="data">
            {dateFormatter.format(hurricane.lastScrapeTs)}
          </div>
        </div>
        <div className="cat">
          <div className="label">
            {schemaLabel(dashboardSchema.resources.hurricanes, 'category')}
          </div>
          <div className="data">{hurricane.category}</div>
        </div>
      </div>
    </li>
  );
}

export function HurricaneList() {
  const data = useLoaderData();

  return (
    <PerilList
      badgeDefinitions={badgeDefinitions}
      columnDefinitions={columnDefinitions}
      perilElement={<Hurricane />}
      perilType="hurricane"
      perils={data}
    />
  );
}
