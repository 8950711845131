/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from 'react';
import { classStr, toggle } from '../../../../helpers/helpers.js';
import { ClickOutside } from '../../ClickOutside.jsx';
import { MultiSelect } from '../../MultiSelect.jsx';
import './DataTableFilterCollector.scss';
import { DateTimeFilterCollector } from './DateTimeFilterCollector.jsx';
import { NumberRangeFilterCollector } from './NumberRangeFilterCollector.jsx';

const listFormatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'disjunction',
});

function BooleanCollector(props) {
  const { id, onChange, open, setOpen, value = ['yes', 'no'], ...rest } = props;

  return ['yes', 'no'].map((option) => (
    <div className="checkbox-group" key={option}>
      <input
        type="checkbox"
        {...rest}
        id={`${id}-${option}`}
        value={option}
        checked={value.includes(option)}
        onChange={() => onChange({ target: { value: toggle(value, option) } })}
      />
      <label htmlFor={`${id}-${option}`}>{option}</label>
    </div>
  ));
}

function StringCollector(props) {
  const { open, setOpen, value = '', ...rest } = props;
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return <input ref={inputRef} type="search" {...rest} value={value} />;
}

function EnumCollector(props) {
  const { id, onChange, open, options, ...rest } = props;
  const { value } = props;

  return open ? (
    <MultiSelect
      namespace={id}
      alwaysOpen
      options={options.map((v) => ({ value: v, label: v }))}
      onChange={(v) => onChange({ target: { value: v } })}
      {...rest}
    />
  ) : (
    <div className="summary">
      {(value ?? []).length === 0 ? null : listFormatter.format(value)}
    </div>
  );
}

const collectorsByDataType = {
  boolean: BooleanCollector,
  'date-time': DateTimeFilterCollector,
  enum: EnumCollector,
  number: NumberRangeFilterCollector,
  string: StringCollector,
};

export function FilterCollector(props) {
  const { dataType, ...rest } = props;
  const { id, title } = props;

  const [open, setOpen] = useState(false);
  const collectorRef = useRef();

  const Collector = collectorsByDataType[dataType];

  ClickOutside(collectorRef, () => setOpen(false));

  return (
    <div
      ref={collectorRef}
      className={classStr({
        'filter-collector-container': true,
        [dataType]: true,
        open,
      })}
    >
      <div className="filter-status">
        <div className="toggle-box">
          <button
            htmlFor={`filter-collector-${id}`}
            type="button"
            className="standard filter-collector-button"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <div className="title">{title}</div>{' '}
            <div className="toggle-arrow">{open ? '▲' : '▼'}</div>
          </button>
        </div>
        <Collector {...rest} open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}
