import { useState } from 'react';
import { PolygonCollector, ToolDrawerHeader } from '@redzone/shared';
import getCenter from '@turf/center';
import { FormattedMessage } from 'react-intl';
import { reverseGeocode } from '../map/geocode.js';
import './SelectPolicies.scss';
import { useDashboardState } from '../../context/DashboardContext.jsx';
import { exportCsv } from '../shared/tables/table-helpers.js';

export function SelectPolicies(props) {
  const { setOpen, close } = props;
  const [selectedLayers] = useDashboardState('selectedLayers');
  const selectedPolicyLayers = selectedLayers.policy;
  const [nearestLocation, setNearestLocation] = useState(null);
  const [userPolygon, setUserPolygon] = useState(null);
  const [policies, setPolicies] = useState(null);

  const handleDownloadPolicies = (data) => {
    alert('download policies', data);
  };

  const handleCsvDownloadPolicies = (data) => {
    exportCsv(
      data,
      [
        {
          id: 'org_key',
          title: 'Organization',
          value: (row) => row.properties.org_key,
        },
        {
          id: 'name',
          title: 'Policy',
          value: (row) => row.properties.name,
        },
        {
          id: 'geometry',
          title: 'Coordinates',
          value: (row) =>
            `${row.geometry.coordinates[0]}, ${row.geometry.coordinates[1]}`,
        },
      ],
      'Selected Policies',
    );
  };

  const handleClearForm = () => {
    setUserPolygon(null);
    setNearestLocation(null);
    setPolicies(null);
  };

  const handlePolygon = async (polygon, features) => {
    setUserPolygon(polygon);
    setOpen(true);
    if (!polygon || polygon.features[0].geometry.coordinates[0].length < 3) {
      return;
    }

    const center = getCenter(polygon).geometry.coordinates;
    const location = await reverseGeocode({
      lng: center[0],
      lat: center[1],
    });

    setNearestLocation(location.place_name);
    setPolicies(features);
  };

  return (
    <div>
      <ToolDrawerHeader
        title={<FormattedMessage id="tool_drawers.select_policies.title" />}
        close={close}
      />
      <div className="select-policies-wrapper">
        <p>Draw a shape to locate policies within it.</p>
        <PolygonCollector
          key={Math.random()} // force re-render, this is a hack and should be updated in the future
          id="select-policies-polygon-collector"
          value={userPolygon}
          onChange={handlePolygon}
          featureLayers={selectedPolicyLayers}
        />
        <h4 className="location">{nearestLocation}</h4>
        {policies && (
          <>
            <h4 className="policy-count">
              {policies.length} Policies Selected
            </h4>
            <div className="action-buttons-container">
              <button
                disabled={policies.length < 1}
                className="download-policies-button"
                type="button"
                onClick={() => handleDownloadPolicies(policies)}
              >
                Download Policies
              </button>
              <button
                disabled={policies.length < 1}
                className="download-policies-csv-button"
                type="button"
                onClick={() => handleCsvDownloadPolicies(policies)}
              >
                Download CSV
              </button>
              <button
                className="reset-button"
                type="button"
                onClick={() => handleClearForm()}
              >
                Clear Selection
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
