import { useMapEffect, useMap, Source, Layer } from '@redzone/map';
import { useState, useEffect, useMemo } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

export function LineCollector(props) {
  const { onChange, value, showValue, ...rest } = props;
  const [collecting, setCollecting] = useState(false);
  const { map } = useMap();

  const draw = useMemo(
    () =>
      new MapboxDraw({
        controls: {
          line_string: true,
          point: false,
          polygon: false,
          trash: true,
          combine_features: false,
          uncombine_features: true,
        },
        defaultMode: 'draw_line_string',
      }),
    [],
  );

  useMapEffect(
    (readyMap) => {
      if (collecting) readyMap.addControl(draw);
    },
    [collecting],
  );

  const handleFinish = () => {
    const polygon = draw.getAll();

    onChange(polygon);
    setCollecting(false);
  };

  useEffect(
    () => () => {
      if (collecting) {
        map.removeControl(draw);
      }
    },
    [collecting, draw, map],
  );

  return collecting ? (
    <button {...rest} type="button" onClick={handleFinish}>
      Finish
    </button>
  ) : (
    <>
      <button
        type="button"
        {...rest}
        onClick={() => {
          setCollecting(true);
        }}
      />
      <Source id="collector-src" type="geojson" data={value}>
        <Layer
          id="measure-lines"
          mapboxLayer={{
            id: 'measure-lines',
            type: 'line',
            source: 'collector-src',
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
            paint: {
              'line-color': '#300',
              'line-width': 8,
            },
          }}
        />
      </Source>
    </>
  );
}
