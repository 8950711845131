import { useState, useRef, useEffect } from 'react';
import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import { ClickOutside } from './ClickOutside.jsx';
import './Dropdown.scss';

const registerOpenDropdownHandlers = ({
  activeIndex,
  setActiveIndex,
  onChange,
  options,
  setOpen,
}) => {
  const keyDownCallback = (e) => {
    switch (e.key) {
      case 'Up': // IE/Edge specific value
        e.preventDefault();
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1);
        break;
      case 'Down': // IE/Edge specific value
        if (e.srcElement.id === 'button-selected') break;
        e.preventDefault();
        setActiveIndex(
          activeIndex + 1 === options.length ? 0 : activeIndex + 1,
        );
        break;
      case 'ArrowDown':
        if (e.srcElement.id === 'button-selected') break;
        e.preventDefault();
        setActiveIndex(
          activeIndex + 1 === options.length ? 0 : activeIndex + 1,
        );
        break;
      case 'Enter':
        e.preventDefault();
        onChange(options[activeIndex].value);
        setOpen(false);
        break;
      case ' ': // Space
        e.preventDefault();
        // TODO(Baad): there is a bug where this case is closing the the dropdown when we try to open it.
        // e.stopPropagation is disabling this whole case statement.
        if (e.srcElement.id === 'button-selected') break;
        onChange(options[activeIndex].value);
        setOpen(false);
        break;
      case 'Esc': // IE/Edge specific value
        e.preventDefault();
        setOpen(false);
        break;
      case 'Escape':
        e.preventDefault();
        setOpen(false);
        break;
      case 'PageUp': // IE/Edge specific value
        e.preventDefault();
        setActiveIndex(0);
        break;
      case 'Home':
        e.preventDefault();
        setActiveIndex(0);
        break;
      case 'PageDown': // IE/Edge specific value
        e.preventDefault();
        setActiveIndex(options.length - 1);
        break;
      case 'End':
        e.preventDefault();
        setActiveIndex(options.length - 1);
        break;
      case 'Tab':
        setOpen(false);
        break;
      default:
    }
  };
  document.addEventListener('keydown', keyDownCallback);

  return () => {
    document.removeEventListener('keydown', keyDownCallback);
  };
};

/*
  __ Props___
  namespace: a unique string to identify this select item
  options: list is expected to {value: xx, label: xx}
  onChange: fn to execute on change. args will be a string of the new value
  value: current value of the select
  header?: string to pass if the use want a header label for the select
  withBorder?: boolean. add border to the selected item and the list
  wide?: boolean. wide select btn - can be imporved.
  shouldFloat?: should the dropdown float is the options content is too long
*/
export function Dropdown(props) {
  const {
    options: userOptions,
    onChange,
    value,
    header,
    withBorder,
    wide,
    namespace,
    shouldFloat,
    hasBlank,
  } = props;
  const options = hasBlank
    ? [{ label: '', value: '' }, ...userOptions]
    : userOptions;
  const ref = useRef('select');
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(
    options.findIndex((o) => o.value === value),
  );
  const [align, setAlignment] = useState('');
  const selectedValueLabel = options.filter((i) => i.value === value);

  ClickOutside(ref, () => setOpen(false));

  const isElementFullyVisible = (element) => {
    const bound = element.getBoundingClientRect();
    const containerWidth = document
      .getElementsByClassName('drawer-wrapper')[0]
      .getBoundingClientRect().width;
    return containerWidth > bound.left + bound.width;
  };

  useEffect(() => {
    if (open) {
      const activeElementId = document.activeElement.id;
      if (activeElementId === 'button-selected') {
        document.getElementById('button-selected').blur();
        document.querySelector(`#${namespace}_0`).focus();
      } else {
        document.querySelector(`#${namespace}_${activeIndex}`).focus();
      }

      return registerOpenDropdownHandlers({
        activeIndex,
        setActiveIndex,
        options,
        onChange,
        setOpen,
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, activeIndex]);

  useEffect(() => {
    if (open && shouldFloat) {
      const list = document.getElementById(`${namespace}_dropdown`);
      if (isElementFullyVisible(list)) {
        setAlignment('');
      } else {
        setAlignment('left-align');
      }
    }
    return () => {
      setAlignment('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const buttonKeyDownHandler = (e) => {
    switch (e.key) {
      case 'Down': // IE/Edge specific value
        e.preventDefault();
        setOpen(true);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setOpen(true);
        break;
      case ' ':
        setOpen(true);
        break;
      case 'Enter':
        e.preventDefault();
        break;
      default:
    }
  };

  return (
    <div
      className={`component select, dropdown ${wide ? 'wide' : ''} ${
        open ? 'open' : ''
      }`.trim()}
      ref={ref}
    >
      {header && <label htmlFor={header}>{header}</label>}
      <button
        id="button-selected"
        className={`standard button-selected ${
          withBorder ? 'with-border' : ''
        }`}
        type="button"
        onClick={() => setOpen(!open)}
        onKeyDown={buttonKeyDownHandler}
        role="combobox"
        aria-haspopup="listbox"
        aria-controls={`${namespace}_dropdown`}
        aria-labelledby={`${namespace}_label`}
        aria-expanded={open}
        aria-activedescendant={`${namespace}_element_${value}`}
      >
        {selectedValueLabel[0]?.label}
        <ChevronIcon orientation="down" className="icon" />
      </button>

      <div className="options-container">
        <ul
          className={`standard options ${open ? '' : 'hidden'} ${
            withBorder ? 'with-border' : ''
          } ${align}`}
          role="listbox"
          id={`${namespace}_dropdown`}
          tabIndex={-1}
          aria-multiselectable={false}
        >
          {open &&
            options.map((item, index) => (
              <li
                key={item.value ?? null}
                id={`${namespace}_${index}`}
                role="option"
                aria-selected={index === activeIndex}
                tabIndex="0"
                className={`list-item ${
                  value === item.value ? 'selected' : ''
                }`}
                aria-controls={`${namespace}_dropdown`}
                aria-labelledby={`${namespace}_label`}
                onClick={() => {
                  setOpen(!open);
                  onChange(item.value);
                }}
                onMouseOver={() => setActiveIndex(index)}
                onKeyDown={() => setActiveIndex(index)}
                onFocus={() => setActiveIndex(index)}
              >
                <span>{item.label}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
