import React from 'react';
import { Dropdown, PointCollector } from '@redzone/shared';
import { Spinner } from '../../icons/Spinner.jsx';
import '../layer-drawer/ImportedLayers.scss';
import './AddSource.scss';
import sources from './sources.json';

export function SourcesForm(props) {
  const {
    formValues,
    setFormValues,
    pin,
    setPin,
    onCancel,
    onSubmit,
    isEditing,
    error,
    loading,
  } = props;

  const onFormChange = (field) => (e) => {
    setFormValues({ ...formValues, [field]: e });
  };

  const editPinHelpText = "Drop a new pin to edit the source's location.";
  const dropPinHelpText =
    "Drop a pin on the source's location and then fill out the form.";

  return (
    <div className="imported-layers drawer-body">
      <div className="new-import">
        {isEditing ? (
          <p>{editPinHelpText}</p>
        ) : (
          !pin && <p>{dropPinHelpText}</p>
        )}
        <PointCollector
          value={pin}
          onChange={(e) => {
            setPin(e);
          }}
          className="standard bordered"
          showValue
        >
          Drop Pin
        </PointCollector>

        <form onSubmit={onSubmit}>
          <div className="input-group">
            <label htmlFor="trusted-source-name">Name</label>
            <input
              id="trusted-source-name"
              type="text"
              required
              value={formValues.name}
              onChange={(e) => onFormChange('name')(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="trusted-source-url">URL</label>
            <input
              id="trusted-source-url"
              type="text"
              required
              value={formValues.url}
              onChange={(e) => onFormChange('url')(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="trusted-source-alt-url">Alt URL</label>
            <input
              id="trusted-source-alt-url"
              type="text"
              value={formValues.altUrl}
              onChange={(e) => onFormChange('altUrl')(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="trusted-source-region">Region</label>
            <input
              id="trusted-source-region"
              type="text"
              value={formValues.region}
              onChange={(e) => onFormChange('region')(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="trusted-source-state">State</label>
            <input
              id="trusted-source-state"
              type="text"
              value={formValues.state}
              onChange={(e) => onFormChange('state')(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="trusted-source-county">County</label>
            <input
              id="trusted-source-county"
              type="text"
              value={formValues.county}
              onChange={(e) => onFormChange('county')(e.target.value)}
            />
          </div>

          <Dropdown
            hasBlank
            withBorder
            namespace="type"
            header="Type"
            options={sources.types.map((type) => ({
              value: type,
              label: type,
            }))}
            onChange={(e) => {
              onFormChange('type')(e);
            }}
            value={formValues.type}
          />

          <div className="input-group">
            <label htmlFor="trusted-source-notes">Notes</label>
            <textarea
              id="trusted-source-notes"
              type="text"
              value={formValues.notes}
              onChange={(e) => onFormChange('notes')(e.target.value)}
            />
          </div>

          <div className="input-group button-group">
            <div className="input-row">
              <button type="submit" disabled={loading}>
                Submit
              </button>
              <button type="button" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
      {loading && (
        <span className="imported-layer-loading">
          <Spinner />
        </span>
      )}
      {error && (
        <div className="imported-layer-error">
          Sorry, something went wrong. Please try again.
        </div>
      )}
    </div>
  );
}
