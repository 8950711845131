/* eslint-disable import/no-cycle */

import {
  useUser as useAuth0User,
  UserAuth0Provider,
} from './UserAuth0Context.jsx';
import {
  useUser as useLocalUser,
  UserLocalProvider,
} from './UserLocalContext.jsx';

export const useUser =
  process.env.REACT_APP_USE_LOCAL_DATA === 'true' ? useLocalUser : useAuth0User;

export const UserProvider =
  process.env.REACT_APP_USE_LOCAL_DATA === 'true'
    ? UserLocalProvider
    : UserAuth0Provider;
