import './TrustedSources.scss';
import '../layer-drawer/ImportedLayers.scss';
import { SourcesList } from './SourcesList.jsx';

export function TrustedSources(props) {
  const { sources, checkedTypes, setCheckedTypes, onEdit, onDelete } = props;

  const handleCheckboxChange = (type) => {
    setCheckedTypes((prevCheckedTypes) => ({
      ...prevCheckedTypes,
      [type]: !prevCheckedTypes[type],
    }));
  };

  return (
    <div>
      <div>
        <ul className="standard sources-list">
          {sources.types.map((type) => (
            <li key={type}>
              <button
                className="standard layer-row input-row"
                type="button"
                onClick={() => handleCheckboxChange(type)}
              >
                <div className="layer-name">{type}</div>
                <input
                  className="layer-checkbox"
                  type="checkbox"
                  readOnly
                  checked={checkedTypes[type]}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <SourcesList
          sources={sources}
          checkedTypes={checkedTypes}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
}
