import { keyBy } from 'lodash';
import DateTimePicker from 'react-datetime-picker';

const dateTimePickerOptions = {
  calendarIcon: null,
  maxDate: new Date(),
};

const dateTimeFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'short',
});

function Collector(props) {
  const { id, onChange, value = {}, ...rest } = props;
  const timeframes = [
    {
      label: 'Last 24 Hours',
      value: { start: new Date(new Date() - 1000 * 86400) },
    },
    {
      label: 'Last 3 Days',
      value: {
        start: new Date(new Date() - 1000 * 86400 * 3),
      },
    },
    {
      label: 'Last Week',
      value: {
        start: new Date(new Date() - 1000 * 86400 * 7),
      },
    },
  ];

  const timeframesByLabel = keyBy(timeframes, (t) => t.label);
  const handleTimeframeChange = (ev) => {
    const key = ev.target.value;
    const timeframeDef = timeframesByLabel[key];

    onChange({ target: { value: { ...timeframeDef.value, formValue: key } } });
  };

  return (
    <div className="radio-group">
      <div className="form-row">
        <input
          id={`${id}-radio-any`}
          type="radio"
          name={`${id}-radio`}
          onChange={() => {
            onChange({ target: { value: { formValue: 'any' } } });
          }}
          value=""
          defaultChecked
        />
        <label htmlFor={`${id}-radio-any`}>any</label>
      </div>
      {timeframes.map((timeframe) => (
        <div key={timeframe.label} className="form-row">
          <input
            id={`${id}-radio-${timeframe.label}`}
            type="radio"
            name={`${id}-radio`}
            onChange={handleTimeframeChange}
            value={timeframe.label}
            checked={value.formValue === timeframe.label}
          />
          <label htmlFor={`${id}-radio-${timeframe.label}`}>
            {timeframe.label}
          </label>
        </div>
      ))}
      <div className="form-row">
        <input
          id={`${id}-radio-custom`}
          type="radio"
          name={`${id}-radio`}
          value="custom"
          onChange={() =>
            onChange({ target: { value: { formValue: 'custom' } } })
          }
          checked={value.formValue === 'custom'}
        />
        <label htmlFor={`${id}-radio-custom`}>Custom</label>
        {value.formValue === 'custom' && (
          <div>
            <div className="date-time-collector">
              <label htmlFor="date-time-start">From</label>
              <DateTimePicker
                {...rest}
                id="datetime-start"
                onChange={(val) => {
                  onChange({ target: { value: { ...value, start: val } } });
                }}
                value={value?.start}
                {...dateTimePickerOptions}
              />
            </div>

            <div className="date-time-collector">
              <label htmlFor="date-time-end">To</label>
              <DateTimePicker
                {...rest}
                id="datetime-end"
                onChange={(val) => {
                  if (!value || !value.end) val.setHours(23, 59, 59, 999);

                  onChange({ target: { value: { ...value, end: val } } });
                }}
                value={value?.end}
                {...dateTimePickerOptions}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Summary(props) {
  const { value = {} } = props;

  if (value.formValue && value.formValue !== 'custom') {
    return value.formValue;
  }

  if (!value.start && !value.end) return null;
  if (value.start && value.end) {
    return `${dateTimeFormatter.format(
      value.start,
    )} to ${dateTimeFormatter.format(value.end)}`;
  }
  if (value.start) {
    return `after ${dateTimeFormatter.format(value.start)}`;
  }

  return `before ${dateTimeFormatter.format(value.end)}`;
}

export function DateTimeFilterCollector(props) {
  const { open } = props;

  return open ? (
    <Collector {...props} />
  ) : (
    <div className="summary">
      <Summary {...props} />
    </div>
  );
}
