import { useEffect, useRef, useState } from 'react';

function Collector(props) {
  const { onChange, open, setOpen, value = {}, ...rest } = props;
  const { id } = props;

  const [initValue, setInitValue] = useState('');
  const [minVal, setMinVal] = useState(
    value.min == null ? '' : String(value.min),
  );
  const [maxVal, setMaxVal] = useState(
    value.max == null ? '' : String(value.max),
  );

  const inputRef = useRef();

  useEffect(() => {
    if (open) {
      inputRef.current.focus();
      setInitValue(structuredClone(value));
    }
  }, [open]);

  const keyDownHandler = (ev) => {
    // numbers and decimal point only
    const numberRegex = /^([\d.])+$/;

    switch (ev.key) {
      // allow these keys
      case 'Backspace':
      case 'Delete':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Tab':
      case 'Shift':
      case 'Control':
      case 'Alt':
      case 'Meta':
        break;
      // special cases
      case 'Enter':
        ev.preventDefault();
        ev.stopPropagation();
        setOpen(false);
        break;
      case 'Escape':
        ev.preventDefault();
        ev.stopPropagation();
        onChange({ target: { value: initValue } });
        setOpen(false);
        break;
      // numbers and decimal point only
      default:
        if (!numberRegex.test(ev.key)) {
          ev.preventDefault();
          ev.stopPropagation();
        }
    }
  };

  return (
    <div className="number-range">
      <input
        ref={inputRef}
        type="number"
        placeholder="min"
        {...rest}
        value={minVal}
        onChange={(ev) => {
          setMinVal(ev.target.value);
          if (ev.target.value !== '' && Number(ev.target.value) != null) {
            onChange({
              target: { value: { ...value, min: Number(ev.target.value) } },
            });
          } else {
            const { min, ...nextValue } = value;
            onChange({
              target: { value: nextValue },
            });
          }
        }}
        onKeyDown={keyDownHandler}
      />
      <span> to </span>
      <input
        type="number"
        placeholder="max"
        {...rest}
        id={`${id}-max`}
        value={maxVal}
        onInput={(ev) => {
          setMaxVal(ev.target.value);
          if (ev.target.value !== '' && Number(ev.target.value) != null) {
            onChange({
              target: { value: { ...value, max: Number(ev.target.value) } },
            });
          } else {
            const { max, ...nextValue } = value;
            onChange({
              target: { value: nextValue },
            });
          }
        }}
        onKeyDown={keyDownHandler}
      />
    </div>
  );
}

function Summary(props) {
  const { value = {} } = props;
  const { min, max } = value;
  const hasMin = min != null && !Number.isNaN(min);
  const hasMax = max != null && !Number.isNaN(max);

  if (!hasMin && !hasMax) return null;
  if (hasMin && hasMax) {
    return `${min} to ${max}`;
  }

  if (hasMin) {
    return `at least ${min}`;
  }

  return `at most ${max}`;
}

export function NumberRangeFilterCollector(props) {
  const { open, value } = props;
  return open ? (
    <Collector {...props} />
  ) : (
    <div className="summary">
      <Summary value={value} />
    </div>
  );
}
