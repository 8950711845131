import { pick } from 'lodash';
import { historicalFiresPerimeterLayer } from '@redzone/shared';
import { redFlagWarningLayer } from './red-flag-warning-layer.js';
import {
  modisLayer,
  viirsNoaaLayer,
  viirsSnppLayer,
} from './modis-viirs-layer.js';

export const PUBLIC_LAYERS = {
  wf_point: {
    perilType: 'fire',
    icons: [
      {
        id: 'fire',
        sdf: true,
        url: 'https://static-assets.redzone.co/images/icons/fire_red.png',
      },
    ],
    id: 'wf_point',
    layerId: 'wf_point',
    legend: {
      subItems: [
        {
          id: 'fire_low_exposure',
          url: 'https://static-assets.redzone.co/images/icons/fire_green.png',
          type: 'url',
        },
        {
          id: 'fire_medium_exposure',
          url: 'https://static-assets.redzone.co/images/icons/fire_yellow.png',
          type: 'url',
        },
        {
          id: 'fire_high_exposure',
          url: 'https://static-assets.redzone.co/images/icons/fire_orange.png',
          type: 'url',
        },
        {
          id: 'fire_severe_exposure',
          url: 'https://static-assets.redzone.co/images/icons/fire_red.png',
          type: 'url',
        },
      ],
    },
    mapboxLayer: {
      id: 'wf_point',
      type: 'symbol',
      paint: {
        'icon-color': [
          'case',
          ['==', ['get', 'maxExposure'], 0],
          '#aaaaaa',
          ['<', ['get', 'maxExposure'], 25],
          '#e9ff8c',
          ['<', ['get', 'maxExposure'], 50],
          '#ffda4c',
          ['<', ['get', 'maxExposure'], 75],
          '#ff6d0d',
          '#cc0000',
        ],
        'icon-translate': [0, -6],
        'text-translate': [0, 3],
        'text-halo-color': '#FFF',
        'text-halo-width': 1,
      },
      layout: {
        // logarithmic growth (1+ is noticeable, 100k+ is enormous)
        'icon-size': [
          'max',
          0.2,
          [
            '/',
            [
              'ln',
              [
                '+',
                10,
                [
                  'min', // cap fires at 10,000 acres for icon sizing
                  10000,
                  [
                    'to-number',
                    [
                      'case',
                      ['==', ['get', 'acreage'], ['literal', 'unknown']],
                      0.01,
                      ['get', 'acreage'],
                    ],
                  ],
                ],
              ],
            ],
            ['ln', 100000], // logarithm base is 100,000
          ],
        ],
        'text-size': 12,
        'icon-image': 'fire',
        'text-field': ['get', 'incidentName'],
        'text-anchor': 'top',
        'text-optional': true,
        'icon-allow-overlap': true,
      },
      source: 'wf_src',
    },
    source: 'wf_src',
    zValue: 700,
  },

  wf_perimeter: {
    // icons: [
    //   {
    //     id: 'wf_perimeter_legend_icon',
    //     url: 'https://static-assets.redzone.co/images/icons/wf_perimeter.png',
    //   },
    // ],
    id: 'wf_perimeter',
    layerId: 'wf_perimeter',
    mapboxLayer: {
      id: 'wildfires:perimeter',
      type: 'fill',
      'source-layer': 'wildfires:perimeter',
      paint: {
        'fill-color': '#B61B0F',
        'fill-opacity': 0.6,
        'fill-outline-color': '#B61B0F',
      },
      source: 'wf_perimeter_src',
    },
    source: {
      id: 'wf_perimeter_src',
      type: 'vector',
      volatile: true,
      scheme: 'xyz',
      tiles: [
        `${process.env.REACT_APP_TILE_DOMAIN}/maps/WF_PERIL/{z}/{x}/{y}.pbf`,
      ],
    },
  },

  // eq_epicenter: {
  //   id: 'eq_epicenter',
  //   icons: [
  //     {
  //       id: 'eq_epicenter',
  //       url: 'https://static-assets.redzone.co/images/icons/epicenter.png',
  //     },
  //   ],
  //   layerId: 'eq_epicenter',
  //   mapboxLayer: {
  //     id: 'eq_epicenter',
  //     type: 'circle',
  //     source: 'eq_epicenter_src',
  //     paint: {
  //       'circle-radius': 10,
  //       'circle-color': '#ff3b53',
  //       'circle-stroke-color': 'white',
  //       'circle-stroke-width': 1,
  //       'circle-opacity': 0.5,
  //     },
  //   },
  //   source: PUBLIC_SOURCES.eq_epicenter,
  // },
  historical_fires_perimeter: historicalFiresPerimeterLayer,
  modis: modisLayer,
  viirs_NOAA_20: viirsNoaaLayer,
  viirs_S_NPP: viirsSnppLayer,
  red_flag_warning: redFlagWarningLayer,
};

export const PUBLIC_FIRE_LAYERS = pick(PUBLIC_LAYERS, [
  'wf_perimeter',
  'historical_fires_perimeter',
  'modis',
  'viirs_NOAA_20',
  'viirs_S_NPP',
  'red_flag_warning',
]);

export const PUBLIC_EARTHQUAKE_LAYERS = pick(PUBLIC_LAYERS, []);

export const PUBLIC_HURRICANE_LAYERS = pick(PUBLIC_LAYERS, []);

export const PUBLIC_LAYERS_BY_PERIL_TYPE = {
  earthquakes: PUBLIC_EARTHQUAKE_LAYERS,
  fires: PUBLIC_FIRE_LAYERS,
  hurricanes: PUBLIC_HURRICANE_LAYERS,
};
