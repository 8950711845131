import { Link, useLoaderData } from 'react-router-dom';
import { getExposureCssColors } from '../../../../utils/exposure-colors.js';
import { PerilBadges, PerilList } from '../PerilList.jsx';
import { queryLoader } from '../../../../hooks/use-query.js';
import './FireList.scss';
import { FireLayers } from './FireLayers.jsx';
import { shortCompactLocalizeNumber } from '../../../../utils/formatter.js';

const badgeDefinitions = {
  exposed: {
    element: (fire) => <Link to={`${fire.incidentId}/exposure`}>exposure</Link>,
    title: 'Exposure',
    test: (fire) => fire.portfolioExposures.some((e) => e.rating > 0),
    text: 'exposure',
  },
  prescribed: {
    test: (fire) => fire.prescribed,
    text: 'RX',
    title: 'Prescribed',
  },
  new: {
    test: (fire) =>
      Number(fire.fireDiscoveryDateTime) > Date.now() - 86400 * 1000,
    text: 'new',
    title: 'New',
  },
  deployed: {
    element: (fire) => <Link to={`${fire.incidentId}/triage`}>deployed</Link>,
    test: (fire) => fire.assignments.length > 0,
    text: 'deployed',
    title: 'Deployed',
  },
};

const columnDefinitions = {
  incidentId: { value: (fire) => fire.incidentId },
  incidentName: {
    title: 'Incident Name',
    searchable: true,
    sortable: true,
    value: (fire) => fire.incidentName,
  },
  county: {
    title: 'County',
    searchable: true,
    value: (fire) => fire.county,
  },
  state: {
    title: 'State',
    searchable: true,
    value: (fire) => fire.state,
  },
  acreage: {
    title: 'Acreage',
    dataType: 'number',
    filterable: true,
    sortable: true,
    sortValue: (fire) =>
      fire.acreage === 'unknown' ? -Infinity : fire.acreage,
    value: (fire) => shortCompactLocalizeNumber(fire.acreage),
  },
  containment: {
    title: 'Containment',
    dataType: 'number',
    sortable: true,
    filterable: true,
    sortValue: (fire) =>
      fire.containment === 'unknown' ? -Infinity : fire.containment,
    value: (fire) => fire.containment,
  },
  discoveryTime: {
    title: 'Discovery Time',
    dataType: 'date-time',
    sortable: true,
    filterable: true,
    value: (fire) => fire.fireDiscoveryDateTime,
  },
};

const query = {
  type: 'fires',
  properties: {
    center: 'center',
    incidentId: 'incidentId',
    incidentName: 'incidentName',
    prescribed: 'prescribed',
    fireDiscoveryDateTime: 'fireDiscoveryDateTime',
    county: 'county',
    state: 'state',
    acreage: 'acreage',
    containment: 'containment',
    portfolioExposures: { properties: { rating: 'rating' } },
    assignments: { properties: { id: 'id' } },
  },
};

export function loader() {
  return queryLoader(query);
}

export function Fire(props) {
  const { peril: fire } = props;

  return (
    <li
      key={fire.incidentId}
      className="peril fire"
      style={fire.maxExposure > 0 ? getExposureCssColors(fire.maxExposure) : {}}
      data-exposure={fire.maxExposure}
    >
      <div className="peril-name-container fire-name-container fire-row">
        <h1 className="peril-name fire-name">
          <Link to={fire.incidentId}>
            {fire.incidentName ?? `<Unnamed Fire in ${fire.county} County>`}
          </Link>
        </h1>
        <PerilBadges peril={fire} badgeDefinitions={badgeDefinitions} />
      </div>
      <div className="information">
        <div className="facts">
          <div className="county">{fire.county && `${fire.county} County`}</div>
          <div className="state">{fire.state}</div>
        </div>

        <div className="stats-grid">
          <div className="nw stat-label">Acreage</div>
          <div className="ne stat" data-acreage={fire.acreage}>
            {fire.acreage ? shortCompactLocalizeNumber(fire.acreage) : '?'}
          </div>
          <div className="sw stat-label">Contained</div>
          <div className="se stat">
            {fire.containment === 'unknown' || !fire.containment
              ? '?'
              : `${fire.containment}%`}
          </div>
        </div>
      </div>
    </li>
  );
}

export function FireList() {
  const data = useLoaderData();

  return (
    <PerilList
      badgeDefinitions={badgeDefinitions}
      columnDefinitions={columnDefinitions}
      layersElement={<FireLayers />}
      perilElement={<Fire />}
      perilType="fire"
      perils={data}
    />
  );
}
