import React, { useState } from 'react';
import { Navigate, NavLink, Outlet, Route, Routes } from 'react-router-dom';
import './Tabs.scss';

export function Tab(props) {
  return props.children;
}

export function RouteTabs(props) {
  const { children, className, context } = props;
  const childArray = React.Children.toArray(children);

  if (!childArray.every((child) => child.type === Tab)) {
    throw new Error('All children of `RouteTabs` must be of type `Tab`.');
  }

  const fullLabelClassName = ['tab-labels-container', className]
    .filter(Boolean)
    .join(' ');

  const labels = childArray.map((child) => {
    const { label, path } = child.props;

    return (
      <li className="tab-label" key={path}>
        <NavLink
          to={path}
          className={({ isActive }) =>
            isActive
              ? 'standard tab-label-button selected'
              : 'standard tab-label-button'
          }
        >
          {label}
        </NavLink>
      </li>
    );
  });

  return (
    <>
      <div className={fullLabelClassName}>
        <ul className="standard tab-labels-list">{labels}</ul>
      </div>
      <Outlet context={context} />
    </>
  );
}

export function TabLabels(props) {
  const { children, className } = props;
  const childArray = React.Children.toArray(children);

  if (!childArray.every((child) => child.type === Tab)) {
    throw new Error('All children of `RouteTabs` must be of type `Tab`.');
  }

  const fullLabelClassName = ['tab-labels-container', className]
    .filter(Boolean)
    .join(' ');

  const labels = childArray.map((child) => {
    const { label, path } = child.props;

    return (
      <li className="tab-label" key={path}>
        <NavLink
          to={path}
          className={({ isActive }) =>
            isActive
              ? 'standard tab-label-button selected'
              : 'standard tab-label-button'
          }
        >
          {label}
        </NavLink>
      </li>
    );
  });

  return (
    <div className={fullLabelClassName}>
      <ul className="standard tab-labels-list">{labels}</ul>
    </div>
  );
}

export function LinkTabs(props) {
  const { children, labelContainerClassName } = props;
  const childArray = React.Children.toArray(children);

  if (!childArray.every((child) => child.type === Tab)) {
    throw new Error('All children of `LinkTabs` must be of type `Tab`.');
  }

  const fullLabelClassName = ['tab-labels-container', labelContainerClassName]
    .filter(Boolean)
    .join(' ');

  const labels = childArray.map((child) => {
    const { label, path } = child.props;

    return (
      <li className="tab-label" key={path}>
        <NavLink
          to={path}
          className={({ isActive }) =>
            isActive
              ? 'standard tab-label-button selected'
              : 'standard tab-label-button'
          }
        >
          {label}
        </NavLink>
      </li>
    );
  });

  const tabRoutes = childArray.map((child) => {
    const { children: grandchildren, path } = child.props;
    return <Route key={path} path={path} element={grandchildren} />;
  });

  const defaultRoute =
    childArray.find((child) => child.props.default) ?? childArray[0];
  const defaultRedirect = defaultRoute && (
    <Route
      path=""
      element={<Navigate replace to={defaultRoute.props.path} />}
    />
  );

  return (
    <>
      <div className={fullLabelClassName}>
        <ul className="standard tab-labels-list">{labels}</ul>
      </div>
      <Routes>
        {defaultRedirect ?? null}
        {tabRoutes}
      </Routes>
    </>
  );
}

export function MemoryTabs(props) {
  const {
    children,
    className,
    selected: selectedTab,
    setSelected: setSelectedTab,
  } = props;
  const childArray = React.Children.toArray(children);
  const tabChildren = childArray.filter((child) => child.type === Tab);

  const defaultTab = tabChildren.find((child) => child.props.default);

  const [internalSelected, setInternalSelected] = useState(
    defaultTab?.props?.id ?? null,
  );

  const [selected, setSelected] = selectedTab
    ? [selectedTab, setSelectedTab]
    : [internalSelected, setInternalSelected];

  const fullLabelClassName = ['tab-labels-container', className]
    .filter(Boolean)
    .join(' ');

  const selectTab = (nextTab) => {
    setSelected(nextTab === selected ? null : nextTab);
  };

  const labels = childArray.map((child) => {
    const { id, label } = child.props;

    const tabButtonClassName = [
      'tab-label-button',
      id === selected ? 'selected' : null,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <li className="tab-label" key={id}>
        {child.type === Tab ? (
          <button
            type="button"
            className={`standard ${tabButtonClassName}`}
            onClick={() => selectTab(id)}
          >
            {label}
          </button>
        ) : (
          child
        )}
      </li>
    );
  });

  const selectedChild =
    tabChildren.find((child) => child.props.id === selected) ?? null;

  return (
    <>
      <div className={fullLabelClassName}>
        <ul className="standard tab-labels-list">{labels}</ul>
      </div>
      {selectedChild}
    </>
  );
}
