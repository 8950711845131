import { useEffect, useState } from 'react';
import { Popup } from '@redzone/map';
import { transformObjCamelCase } from '../../../utils/transform-obj-camel-case.js';
import './RedFlagWarningPopup.scss';

export function RedFlagWarningPopup(props) {
  const { features, center, data } = props;
  const [selectedFeatureIdx, setSelectedFeatureIdx] = useState(0);
  const selectedFeature = features[selectedFeatureIdx];

  const formatData = data.map((d) => transformObjCamelCase(d));
  const dataForSelectedFeature = formatData.filter((d) =>
    d.targetArea.includes(selectedFeature.properties.NAME),
  )[selectedFeatureIdx];

  useEffect(() => {
    setSelectedFeatureIdx(0);
  }, [features]);

  if (!data || !selectedFeature) return null;

  return (
    <Popup coordinates={center.coordinates}>
      <div className="popup standard red-flag-warning-popup">
        <h3>
          {dataForSelectedFeature.length > 1 && (
            <>
              <button
                type="button"
                className="prev-button"
                aria-label="previous source"
                id="prev-source-button"
                onClick={() => {
                  setSelectedFeatureIdx(
                    selectedFeatureIdx === 0
                      ? dataForSelectedFeature.length - 1
                      : selectedFeatureIdx - 1,
                  );
                }}
              >
                &lt;
              </button>
              <div className="title">
                Source {selectedFeatureIdx + 1} of{' '}
                {dataForSelectedFeature.length}
              </div>
              <button
                type="button"
                className="next-button"
                aria-label="next source"
                id="next-source-button"
                onClick={() => {
                  setSelectedFeatureIdx(
                    (selectedFeatureIdx + 1) % dataForSelectedFeature.length,
                  );
                }}
              >
                &gt;
              </button>
            </>
          )}
        </h3>
        <h3>{dataForSelectedFeature.event}</h3>
        <p>Effective {dataForSelectedFeature.effective}</p>
        <p>Expires {dataForSelectedFeature.expires}</p>
        <label htmlFor="alert">Alert:</label>
        <p>{dataForSelectedFeature.alert}</p>

        <label htmlFor="Instructions">Instructions:</label>
        <p>{dataForSelectedFeature.instructions}</p>

        <label htmlFor="Target Area">Target Area:</label>
        <p>{dataForSelectedFeature.targetArea.join(', ')}</p>

        <label htmlFor="Forecast Office">Forecast Office:</label>
        <p>{dataForSelectedFeature.forecastOffice.join(', ')}</p>

        <label htmlFor="Message">Source:</label>
        <p>{dataForSelectedFeature.message}</p>
      </div>
    </Popup>
  );
}
