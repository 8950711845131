import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Source, Layer, Popup } from '@redzone/map';
import { ToolDrawerHeader } from '@redzone/shared';
import './SourcesDrawer.scss';
import { MemoryTabs, Tab } from '../../shared/Tabs.jsx';
import { TrustedSources } from './TrustedSources.jsx';
import { AddSource } from './AddSource.jsx';
import { EditSource } from './EditSource.jsx';
import trustedSources from './sources.json';

export function SourcesDrawerInner(props) {
  const { close } = props;
  const [popupFeature, setPopupFeature] = useState(null);
  const [sources, setSources] = useState(trustedSources);
  const [checkedTypes, setCheckedTypes] = useState(
    sources.types.reduce((acc, curr) => {
      acc[curr] = { checked: true };
      return acc;
    }, {}),
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editFormValues, setEditFormValues] = useState(null);
  const [selectedTab, setSelectedTab] = useState('trusted');

  const updateSources = async (obj) => {
    setSources(obj);

    // try {
    //   // post the giant json
    //   const response = await axios.post('/api/trusted/sources/data', obj);
    //   return response;
    // } catch (e) {
    //   setError(true);
    //   throw new Error(e);
    // } finally {
    //   setLoading(false);
    // }
  };

  // These hendlers catch events from the Popup component
  const editHandler = (e) => {
    setIsEditing((prevIsEditing) => !prevIsEditing);
    setEditFormValues(popupFeature);
    setSelectedTab('edit');
  };

  const deleteHandler = (e) => {
    setPopupFeature(null);
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this source?')) {
      sources.data.features = sources.data.features.filter(
        (item) => item.properties.id !== e.properties.id,
      );
      const preppedSourceObj = {
        ...sources,
        data: {
          ...sources.data,
          features: [
            ...sources.data.features.filter(
              (item) => item.properties.id !== e.properties.id,
            ),
          ],
        },
      };
      updateSources(preppedSourceObj);
      // try {
      //   // post the giant json
      //   const response = await axios.post('/api/trusted/sources/data', data);
      //   return response;
      // } catch (e) {
      //   setError(true);
      //   throw new Error(e);
      // } finally {
      //   setLoading(false);
      // }
    }
  };

  sources.id = 'trusted-sources';

  const filter = [
    'in',
    ['get', 'type'],
    [
      'literal',
      sources.data.features
        .filter((f) => checkedTypes[f.properties.type])
        .map((f) => f.properties.type),
    ],
  ];

  const mapboxSource = {
    id: sources.id,
    type: sources.type,
    data: sources.data,
  };

  return (
    <div className="sources-drawer stacked">
      <ToolDrawerHeader
        title={<FormattedMessage id="tool_drawers.sources_drawer.title" />}
        close={close}
      />
      <MemoryTabs selected={selectedTab} setSelected={setSelectedTab}>
        <Tab
          id="trusted"
          label={
            <FormattedMessage id="tool_drawers.sources_drawer.tabs.sources" />
          }
        >
          <main className="drawer-body">
            <TrustedSources
              sources={sources}
              checkedTypes={checkedTypes}
              setCheckedTypes={setCheckedTypes}
              onEdit={editHandler}
              onDelete={deleteHandler}
            />
          </main>
        </Tab>
        <Tab
          id="add"
          label={
            <FormattedMessage id="tool_drawers.sources_drawer.tabs.add_source" />
          }
        >
          <AddSource
            sources={sources}
            setSources={updateSources}
            setSelectedTab={setSelectedTab}
          />
        </Tab>
        {isEditing && (
          <Tab id="edit" label="Edit Source">
            <EditSource
              isEditing
              setIsEditing={setIsEditing}
              editFormValues={editFormValues}
              sources={sources}
              setSources={updateSources}
              setSelectedTab={setSelectedTab}
            />
          </Tab>
        )}
      </MemoryTabs>
      {sources && (
        <Source {...mapboxSource} icons={sources.icons}>
          <Layer
            id="trusted-sources"
            mapboxLayer={sources.layers[0]}
            filter={filter}
            onClick={(e) => {
              e.features[0].lngLat = e.lngLat;
              setPopupFeature(e.features[0]);
            }}
          />
        </Source>
      )}
      {popupFeature && (
        <Popup
          coordinates={popupFeature.lngLat}
          onClose={() => {
            setPopupFeature(null);
          }}
        >
          <div>
            <h1>{popupFeature.properties.name}</h1>
            <table>
              <tbody>
                <tr>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={popupFeature.properties.url}
                  >
                    {popupFeature.properties.url.trim()}
                  </a>
                </tr>
                {popupFeature.properties.notes && (
                  <tr>
                    <div>{popupFeature.properties.notes}</div>
                  </tr>
                )}
                <tr>
                  <div>
                    <button
                      className="standard"
                      type="button"
                      id="edit-trusted-source"
                      onClick={editHandler}
                    >
                      Edit
                    </button>
                    <button
                      className="standard"
                      type="button"
                      id="delete-trusted-source"
                      onClick={deleteHandler}
                    >
                      Delete
                    </button>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        </Popup>
      )}
    </div>
  );
}

export function SourcesDrawer(props) {
  return <SourcesDrawerInner {...props} />;
}
