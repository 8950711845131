import { HistoricalFiresPopup } from '../popups/HistoricalFiresPopup.jsx';

export const nifcHistoricalFiresLayer = {
  id: 'nifc_historical_fires',
  layerId: 'nifc_historical_fires',
  label: 'NIFC- Historical Fires',
  source: {
    id: 'redzonesoftware.nifc-historical-fires-09-2023',
    type: 'vector',
    url: 'mapbox://redzonesoftware.nifc-historical-fires-09-2023',
  },
  mapboxLayer: {
    id: 'redzonesoftware.nifc-historical-fires-09-2023',
    type: 'circle',
    source: 'redzonesoftware.nifc-historical-fires-09-2023',
    'source-layer': 'fire-points',
    paint: {
      'circle-radius': 4,
      'circle-color': '#B61B0F',
    },
  },
  onClick: (e, fn) =>
    fn('nifc_historical_fires', {
      center: {
        coordinates: [
          e.features[0].properties.InitialLongitude,
          e.features[0].properties.InitialLatitude,
        ],
      },
      features: e.features,
    }),

  popup: HistoricalFiresPopup,
};
