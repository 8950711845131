import { uniq } from 'lodash';
import { useLoaderData, useParams } from 'react-router-dom';
import { queryLoader } from '../../../../../../hooks/use-query.js';
import {
  Column,
  DataCollectionTable,
} from '../../../../../shared/tables/DataCollectionTable.jsx';
import './Triage.scss';
import { useUser } from '../../../../../../context/UserContext.jsx';

const engineQuery = {
  type: 'engines',
  properties: {
    engineName: 'engineName',
    assignments: {
      properties: {
        peril: {
          properties: {
            id: 'id',
          },
        },
        status: 'status',
        visitations: {
          properties: { id: 'id' },
        },
      },
    },
    organization: {
      properties: {
        id: 'id',
        shortName: 'shortName',
      },
    },
  },
};

export async function loader() {
  return queryLoader(engineQuery);
}

export function TriageEngines() {
  const engines = useLoaderData();
  const { perilId } = useParams();
  const { currentUser } = useUser();

  const tableData = engines.map((engine) => ({
    engineName: engine.engineName,
    organization: engine.organization?.shortName,
    perilAssignmentsCount: engine.assignments.filter(
      (a) => a.peril.id === perilId,
    ).length,
    assignmentsCount: engine.assignments.length,
    policyCount: engine.assignments.reduce(
      (sum, a) => sum + a.visitations.length,
      0,
    ),
  }));

  return (
    <DataCollectionTable
      data={tableData}
      rowKey={(row) => `${row.engineName}/${row.organization}`}
      title="engines"
    >
      <Column
        id="engineName"
        title="Engine"
        dataType="enum"
        options={uniq(tableData.map((row) => row.engineName))}
        filterable
        sortable
        value={(row) => row.engineName}
      />
      {currentUser.organizations.length > 1 && (
        <Column
          id="organization"
          title="Organization"
          dataType="enum"
          options={uniq(tableData.map((row) => row.organization))}
          filterable
          sortable
          value={(row) => row.organization}
        />
      )}
      <Column
        id="assignmentsCount"
        title="Total Assignments"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.assignmentsCount}
      />
      <Column
        id="perilAssignmentsCount"
        title="Assignments On This Fire"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.perilAssignmentsCount}
      />
      <Column
        id="policyCount"
        title="Policy Count"
        dataType="number"
        filterable
        sortable
        className="number"
        value={(row) => row.policyCount}
      />
    </DataCollectionTable>
  );
}
