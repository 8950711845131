import { startCase } from 'lodash';
import { formatDistance } from 'date-fns';
import { useOutletContext } from 'react-router-dom';
import { Accordion, AccordionSection } from '@redzone/shared';
import {
  ResourceRow,
  ResourceTable,
} from '../../../../shared/ResourceTable.jsx';
import { MemoryTabs, Tab } from '../../../../shared/Tabs.jsx';
import { shortCompactLocalizeNumber } from '../../../../../utils/formatter.js';
import './FireOverview.scss';
import { dashboardSchema } from '../../../../../stores/store.js';
import { FireOverviewFeed } from './overview/FireOverviewFeed.jsx';

const fireSchema = dashboardSchema.resources.fires;

const summaryFeedProps = [];
const detailsProps = {
  location: ['county', 'state'],
  behavior: [
    'fireBehaviorGeneral',
    'fireBehaviorDescription',
    'weatherConcerns',
  ],
  response: [
    'analystCommentary',
    'evacuationStatus',
    'fireMgmtComplexity',
    'totalIncidentPersonnel',
    'significantEvents',
    'projectedIncidentActivity',
    'plannedActions',
    'estimatedContainmentDate',
  ],
  damage: [
    'residencesThreatened',
    'residencesDestroyed',
    'otherStructuresThreatened',
    'otherStructuresDestroyed',
  ],
};
const linksProps = [
  'newsLink',
  'incidentLink',
  'radioLink',
  'socialLink',
  'webcamLink',
  'xLink',
];
const tabProps = [
  ...summaryFeedProps,
  ...Object.values(detailsProps).flat(),
  ...linksProps,
].reduce((acc, prop) => ({ ...acc, [prop]: prop }), {});

export const query = {
  type: 'fires',
  id: { $var: 'id' },
  properties: {
    fireDiscoveryDateTime: 'fireDiscoveryDateTime',
    acreage: 'acreage',
    containment: 'containment',
    ...tabProps,
  },
};

export function FireOverview() {
  const { fire } = useOutletContext();

  const policyExposures = fire.portfolioExposures.flatMap(
    (e) => e.policyExposures,
  );
  const exposedPolicies = policyExposures.map((ep) => ep.policy);

  const sinceStart = formatDistance(
    new Date(fire.fireDiscoveryDateTime),
    new Date(),
  );
  const containmentStr =
    fire.containment === 'unknown' ? '-' : `${fire.containment}%`;
  const tivTotal = exposedPolicies.reduce((sum, p) => sum + p.tiv, 0);
  const maxTiv = exposedPolicies.reduce(
    (max, p) => Math.max(max, p.tiv ?? 0),
    0,
  );

  const minTivCalc = exposedPolicies.reduce(
    (min, p) => (p.tiv ? Math.min(min, p.tiv) : min),
    Infinity,
  );
  const minTiv = minTivCalc === Infinity ? 0 : minTivCalc;

  const closestDistance = policyExposures.reduce(
    (min, p) => Math.min(min, p.distance ?? 0),
    0,
  );
  const policyCount = exposedPolicies.length;

  return (
    <div className="fire-overview stacked">
      {/* Hide email report until implemented */}
      {/* <div className="fire-header">
        <button
          className="standard fire-overview-button"
          type="button"
          onClick={() => alert('send email!')}
        >
          <EnvelopeIcon /> <span>Email Report</span>
        </button>
      </div> */}

      <ul className="standard summary-container">
        <li className="acreage">
          <div className="stat">{shortCompactLocalizeNumber(fire.acreage)}</div>
          <div className="label">acres burned</div>
        </li>
        <li className="containment">
          <div className="stat">{containmentStr}</div>
          <div className="label">contained</div>
        </li>
        <li className="age">
          <div className="stat">{sinceStart}</div>
          <div className="label">since fire began</div>
        </li>
        <li className="fire-behavior">
          <div className="stat">{fire.fireBehaviorGeneral ?? '-'}</div>
          <div className="label">fire behavior</div>
        </li>
        <li className="tiv">
          <div className="stat">${shortCompactLocalizeNumber(tivTotal)}</div>
          <div className="label">TIV</div>
        </li>
        <li className="closest-policy">
          <div className="stat">{closestDistance}</div>
          <div className="label">closest policy</div>
        </li>
        <li className="policy-exposed">
          <div className="stat">{shortCompactLocalizeNumber(policyCount)}</div>
          <div className="label">policies exposed</div>
        </li>
        <li className="tiv-range">
          <div className="stat">
            ${shortCompactLocalizeNumber(minTiv)} - $
            {shortCompactLocalizeNumber(maxTiv)}
          </div>
          <div className="label">Policy Value Range</div>
        </li>
      </ul>

      <MemoryTabs className="secondary">
        <Tab label="Details" id="details" default>
          <main>
            {Object.keys(detailsProps).map((category, index) => (
              <Accordion key={category}>
                <AccordionSection
                  label={`${startCase(category)}`}
                  id={`${category}-${index}`}
                >
                  <ResourceTable schema={fireSchema} resource={fire}>
                    {detailsProps[category].map((prop) => (
                      <ResourceRow key={prop} property={prop} />
                    ))}
                  </ResourceTable>
                </AccordionSection>
              </Accordion>
            ))}
          </main>
        </Tab>
        <Tab label="Summary Feed" id="summary">
          <FireOverviewFeed reports={fire.reports} />
        </Tab>
        <Tab label="Links" id="links">
          <main>
            <ResourceTable
              className="stacked"
              schema={fireSchema}
              resource={fire}
            >
              {linksProps.map((prop) => (
                <ResourceRow key={prop} property={prop} />
              ))}
            </ResourceTable>
          </main>
        </Tab>
      </MemoryTabs>
    </div>
  );
}
