// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./hurricane-icon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".peril-list-container.hurricane-list-container .hurricane .stats{display:flex;justify-content:space-between;align-items:flex-start;text-transform:capitalize}.peril-list-container.hurricane-list-container .hurricane .stats .label{color:var(--secondary-text-color)}.peril-list-container.hurricane-list-container .hurricane .stats .cat .data{font-size:1.3rem;background:center/contain no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:50px;width:50px;text-align:center;line-height:50px;color:var(--primary-button-background-color)}", "",{"version":3,"sources":["webpack://./src/components/dashboard/perils/hurricanes/HurricaneList.scss"],"names":[],"mappings":"AAEI,iEACE,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,yBAAA,CAEA,wEACE,iCAAA,CAGF,4EACE,gBAAA,CACA,2EAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,4CAAA","sourcesContent":[".peril-list-container.hurricane-list-container {\n  .hurricane {\n    .stats {\n      display: flex;\n      justify-content: space-between;\n      align-items: flex-start;\n      text-transform: capitalize;\n\n      .label {\n        color: var(--secondary-text-color);\n      }\n\n      .cat .data {\n        font-size: 1.3rem;\n        background: center / contain no-repeat url(\"./hurricane-icon.png\");\n        height: 50px;\n        width: 50px;\n        text-align: center;\n        line-height: 50px;\n        color: var(--primary-button-background-color);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
