import { orderBy } from 'lodash';
import { useState } from 'react';
import { filterWithCollectors } from '../../../helpers/filter-helpers.js';
import { classStr } from '../../../helpers/helpers.js';
import { CsvIcon } from '../../icons/CsvIcon.jsx';
import { FilterIcon } from '../../icons/FilterIcon.jsx';
import { ThreeDotsIcon } from '../../icons/ThreeDotsIcon.jsx';
import { SortUpIcon } from '../../icons/SortUpIcon.jsx';
import { ActionMenu } from '../ActionMenu.jsx';
import { MemoryTabs, Tab } from '../Tabs.jsx';
import './DataCollection.scss';
import { FilterCollector } from './filter-collectors/DataTableFilterCollector.jsx';
import { pipeThru } from '../../../utils.js';
import { exportCsv } from './table-helpers.js';
import { SortCollector } from './SortCollector.jsx';

/**
 *
 * This component creates filters and sorting around a collection of data. It
 * takes in several parameters; most importantly `data`. The `children` param
 * is a function that receives the filtered and sorted data items. This isn't
 * exactly standard and could be refactored if there's another way.
 *
 * Usage can be gleaned from finding examples of use throughout the code.
 */

export function DataCollection(props) {
  const {
    actions,
    children,
    className,
    columns,
    data: rawData,
    noControls,
    onChange,
    onFiltersChange = () => {},
    title,
    sort: controlledSort,
    setSort: setControlledSort,
    ...rest
  } = props;

  const [filters, _setFilters] = useState({});
  const [internalSort, setInternalSort] = useState([]);

  const [sort, setSort] = controlledSort
    ? [controlledSort, setControlledSort]
    : [internalSort, setInternalSort];

  const setFilters = (nextVal) => {
    onFiltersChange(nextVal);
    _setFilters(nextVal);
  };

  const sortPropertyFns = sort.map(
    (s) => columns[s.property].sortValue ?? s.property,
  );

  const sortFn =
    sort.length > 0
      ? (data) =>
          orderBy(
            data,
            sortPropertyFns,
            sort.map((s) => s.direction),
          )
      : (data) => data;

  const data = pipeThru(rawData, [
    (data) => filterWithCollectors(data, filters, columns),
    sortFn,
  ]);

  const allActions = [
    ...(actions ?? []),
    {
      icon: <CsvIcon />,
      label: 'Download CSV',
      handler: () => exportCsv(data, Object.values(columns)),
    },
  ];

  return (
    <div
      {...rest}
      className={classStr({
        'data-collection-container': true,
        stacked: true,
        ...(className ? { [className]: true } : {}),
      })}
    >
      {!noControls && (
        <div className="control-panel">
          <MemoryTabs className="controls">
            {Object.values(columns).some((c) => c.filterable) && (
              <Tab id="filters" label={<FilterIcon />} className="control">
                <div className="control-body filter-controls">
                  {Object.entries(columns).map(([id, column]) => {
                    const {
                      dataType,
                      filterable,
                      key: propsKey,
                      options,
                      title: colTitle,
                    } = column;

                    if (!filterable) return null;

                    const key = propsKey ?? id;

                    return (
                      <FilterCollector
                        dataType={dataType ?? 'string'}
                        onChange={(ev) => {
                          setFilters({
                            ...filters,
                            [id]: {
                              type: dataType ?? 'string',
                              params: ev.target.value,
                            },
                          });
                        }}
                        id={`filter-collector-${key}`}
                        key={key}
                        options={options}
                        title={colTitle ?? id}
                        value={filters[id]?.params}
                      />
                    );
                  })}
                </div>
              </Tab>
            )}
            {Object.values(columns).some((c) => c.sortable) && (
              <Tab id="sort" label={<SortUpIcon />}>
                <SortCollector
                  columns={columns}
                  sort={sort}
                  setSort={setSort}
                />
              </Tab>
            )}
          </MemoryTabs>
          <ActionMenu
            title={<ThreeDotsIcon orientation="horizontal" />}
            actions={allActions}
          />
        </div>
      )}

      {children(data)}
    </div>
  );
}
