export function FirePointPopup(props) {
  const { features } = props;
  const fire = features[0]?.properties;

  if (!fire) return null;

  return (
    <div>
      <h1>{fire.incidentName}</h1>
      <table className="standard">
        <tbody>
          <tr>
            <td>Acreage</td>
            <td>{fire.acreage}</td>
          </tr>
          <tr>
            <td>Containment</td>
            <td>{fire.containment}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
