import { useState } from 'react';
import { keyBy, pick } from 'lodash';
import { Layer, Popup, Source, useMapEffect } from '@redzone/map';
import { hashString } from '../../../../utils.js';
import { PUBLIC_LAYERS } from '../../../map/public-layers.js';

export function FireLayers(props) {
  const { perils: fires } = props;
  const firesByIncidentId = keyBy(fires, (f) => f.incidentId);
  const featureStates = {
    selected: fires.map((f) => hashString(f.incidentId)),
  };
  const [popupFeature, setPopupFeature] = useState(null);

  const sourceId = 'wf_src';
  const layerId = 'wf_point';

  const firePointLayer = PUBLIC_LAYERS[layerId];

  useMapEffect(
    (map) => {
      fires.forEach((fire) => {
        map.setFeatureState(
          { source: sourceId, id: hashString(fire.incidentId) },
          { rating: fire.maxExposure },
        );
      });
    },
    () => {},
    [],
  );

  const filter = [
    'in',
    ['get', 'incidentId'],
    ['literal', fires.map((f) => f.incidentId)],
  ];

  const mapboxSource = {
    id: 'wf_src',
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: fires.map((fire) => ({
        type: 'Feature',
        geometry: {
          ...fire.center,
        },
        id: hashString(fire.incidentId), // mapbox requires a number for an ID
        properties: pick(fire, [
          'acreage',
          'containment',
          'incidentId',
          'incidentName',
          'maxExposure',
        ]),
      })),
    },
  };

  return (
    <>
      <Source
        {...mapboxSource}
        icons={firePointLayer.icons}
        featureStates={featureStates}
      >
        <Layer
          id={firePointLayer.id}
          mapboxLayer={firePointLayer.mapboxLayer}
          stopEventPropagation
          filter={filter}
          onClick={(e) => {
            setPopupFeature(
              firesByIncidentId[e.features[0].properties.incidentId],
            );
          }}
        />
      </Source>
      {popupFeature && (
        <Popup
          coordinates={popupFeature.center.coordinates}
          onClose={() => {
            setPopupFeature(null);
          }}
        >
          <div>
            <h1>{popupFeature.incidentName}</h1>
            <table>
              <tbody>
                <tr>
                  <td>County:</td>
                  <td>{popupFeature.county}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{popupFeature.state}</td>
                </tr>
                <tr>
                  <td>Acres:</td>
                  <td>
                    {popupFeature.acreage
                      ? Intl.NumberFormat('en').format(popupFeature.acreage)
                      : 'unknown'}
                  </td>{' '}
                </tr>
                <tr>
                  <td>Ignition Time:</td>
                  <td>
                    {new Date(
                      popupFeature.fireDiscoveryDateTime,
                    ).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>Containment:</td>
                  <td>
                    {typeof popupFeature.containment === 'number'
                      ? `${popupFeature.containment}%`
                      : 'Unknown'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Popup>
      )}
    </>
  );
}
