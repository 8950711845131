import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Login } from '../components/app/Login.jsx'; // eslint-disable-line
import { Spinner } from '../components/icons/Spinner.jsx';
import { queryLoader } from '../hooks/use-query.js';

const User = createContext({});

export function useUser() {
  return useContext(User);
}

const cachedUser = localStorage.getItem('currentUser') ?? null;

const userQuery = {
  type: 'users',
  id: { $var: 'id' },
  properties: {
    organizations: {
      properties: {
        id: 'id',
        dispatch: 'dispatch',
        displayName: 'displayName',
        shortName: 'shortName',
        policyLayers: {
          properties: {
            layerId: 'layerId',
            mapboxLayer: 'mapboxLayer',
            mapboxSource: 'mapboxSource',
            perilType: 'perilType',
          },
        },
      },
    },
    email: 'email',
    permissions: 'permissions',
    username: 'username',
  },
};

function loadUserData({ username }) {
  if (!username) return null;

  const vars = { id: username };
  return queryLoader(userQuery, { vars });
}

export function UserLocalProvider({ children }) {
  const [userError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(!!cachedUser);
  const navigate = useNavigate();

  const loadUser = async (username) => {
    setLoading(true);
    const user = await loadUserData({ username });
    setLoading(false);

    if (user) {
      setCurrentUser(user);
    }
  };

  const login = async (username) => {
    localStorage.setItem('currentUser', username);
    navigate(0);
  };

  const policyLayers = currentUser
    ? currentUser.organizations?.flatMap((o) => o.policyLayers) ?? []
    : [];

  const logout = () => {
    localStorage.removeItem('currentUser');
    navigate(0);
  };

  const hasPermission = (permission) =>
    currentUser && currentUser.permissions.includes(permission);

  useEffect(() => {
    loadUser(cachedUser);
  }, []);

  const value = useMemo(() => ({
    currentUser,
    hasPermission,
    loading,
    login,
    logout,
    policyLayers,
    userError,
  }));

  return (
    <User.Provider value={value}>
      {loading ? <Spinner /> : !currentUser ? <Login /> : children}
    </User.Provider>
  );
}
