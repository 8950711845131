import { format } from 'date-fns';

function downloadCsv(data, fileName) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.download = fileName ?? `download-${window.location}`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const exportCsv = (data, columns, options = {}) => {
  const { title } = options;

  const downloadColumns = columns.filter((column) => column.value);
  const needsToBeEscaped = (val) => /[\n",]/.test(val);
  const escape = (val) => `"${val.replaceAll('"', '""')}"`;
  const formatCsvValue = (val) => (needsToBeEscaped(val) ? escape(val) : val);

  const csvHeaders = downloadColumns.map((column) =>
    formatCsvValue(column.title),
  );
  const csvBodyData = data.map((datum) =>
    downloadColumns
      .map((column) =>
        formatCsvValue(
          (column.formattedValue ?? column.value)(datum),
        ),
      )
      .join(','),
  );

  const csvData = [csvHeaders, ...csvBodyData].join('\n');

  downloadCsv(
    csvData,
    `${title ?? 'data'}-${format(new Date(), 'yyyyMMddhhmmss')}`,
  );
};
