import { createContext, useContext, useMemo, useState } from 'react';
import { PolygonCollector } from '@redzone/shared';

const RedwallFormContext = createContext({});

const propertyTypeToInputType = { integer: 'number', string: 'text' };

export function RedwallCheckbox(props) {
  const { id, property, ...rest } = props;
  const { resource, setProperty } = useContext(RedwallFormContext);

  const value = resource[property] ?? false;

  return (
    <input
      {...rest}
      type="checkbox"
      name={property}
      id={id}
      onChange={() => setProperty(property, !value)}
      checked={value}
    />
  );
}

export function RedwallCheckboxRow(props) {
  const { id, label, property, ...rest } = props;
  const { resourceSchema } = useContext(RedwallFormContext);

  const usedId = id ?? property;
  const shownLabel =
    label ?? resourceSchema?.properties?.[property]?.title ?? property;

  return (
    <div className="input-row">
      <RedwallCheckbox {...rest} id={usedId} property={property} />
      <label htmlFor={usedId}>{shownLabel}</label>
    </div>
  );
}

export function RedwallPolygonCollector(props) {
  const { id, property, ...rest } = props;
  const { resource, setProperty } = useContext(RedwallFormContext);

  const value = resource[property];

  return (
    <PolygonCollector
      {...rest}
      id={id}
      value={value}
      onChange={(newPolygon) => setProperty(property, newPolygon)}
    />
  );
}

export function RedwallPolygonCollectorRow(props) {
  const { label, property, ...rest } = props;
  const { resourceSchema } = useContext(RedwallFormContext);

  const shownLabel =
    label ?? resourceSchema?.properties?.[property]?.title ?? property;

  return (
    <div className="input-row">
      <label htmlFor={property}>{shownLabel}</label>
      <RedwallPolygonCollector {...rest} property={property} />
    </div>
  );
}

export function RedwallInput(props) {
  const { property, ...rest } = props;
  const { resource, setProperty } = useContext(RedwallFormContext);

  const value = resource[property] ?? '';

  return (
    <input
      {...rest}
      value={value}
      onChange={(e) => setProperty(property, e.target.value)}
    />
  );
}

export function RedwallInputRow(props) {
  const { label, property, ...rest } = props;
  const { resourceSchema } = useContext(RedwallFormContext);

  const propertySchema = resourceSchema?.properties?.[property];
  const shownLabel = label ?? propertySchema?.title ?? property;

  const schemaProps = {};
  if (propertySchema) {
    if (propertySchema.maximum !== undefined)
      schemaProps.max = propertySchema.maximum;
    if (propertySchema.minimum !== undefined)
      schemaProps.min = propertySchema.minimum;
    if (propertySchema.type)
      schemaProps.type =
        propertyTypeToInputType[propertySchema.type] ?? propertySchema.type;
  }

  const inputProps = { ...schemaProps, ...rest };

  return (
    <div className="input-row">
      <label htmlFor={property}>{shownLabel}</label>
      <RedwallInput {...inputProps} property={property} />
    </div>
  );
}

export function RedwallRadioGroup(props) {
  const { options, property, ...rest } = props;
  const { resource, resourceSchema, setProperty } =
    useContext(RedwallFormContext);

  const propertySchema = resourceSchema?.properties?.[property];
  const usedOptions =
    options ?? propertySchema.enum.map((e) => ({ label: e, value: e }));

  const value = resource[property] ?? '';

  return (
    <div className="radio-group">
      <ul>
        {usedOptions.map((option) => {
          const id = `${property}--${option.value}`;

          return (
            <li key={option.value}>
              <input
                {...rest}
                type="radio"
                name={property}
                id={id}
                onChange={() => setProperty(property, option.value)}
                checked={value === option.value}
              />
              <label htmlFor={id}>{option.label}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export function RedwallRadioGroupRow(props) {
  const { label, property, ...rest } = props;
  const { resourceSchema } = useContext(RedwallFormContext);

  const shownLabel =
    label ?? resourceSchema?.properties?.[property]?.title ?? property;

  return (
    <div className="input-row">
      <label htmlFor={property}>{shownLabel}</label>
      <RedwallRadioGroup {...rest} property={property} />
    </div>
  );
}

export function RedwallTextarea(props) {
  const { property, ...rest } = props;
  const { resource, setProperty } = useContext(RedwallFormContext);

  const value = resource[property] ?? '';

  return (
    <textarea
      {...rest}
      value={value}
      onChange={(e) => setProperty(property, e.target.value)}
    />
  );
}

export function RedwallTextareaRow(props) {
  const { label, property, ...rest } = props;
  const { resourceSchema } = useContext(RedwallFormContext);

  const shownLabel =
    label ?? resourceSchema?.properties?.[property]?.title ?? property;

  return (
    <div className="input-row">
      <label htmlFor={property}>{shownLabel}</label>
      <RedwallTextarea {...rest} property={property} />
    </div>
  );
}

export function RedwallForm(props) {
  const { children, onSubmit, resource, setResource, resourceSchema, ...rest } =
    props;
  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  const setProperty = (property, newValue) =>
    setResource({ ...resource, [property]: newValue });

  const handleInvalid = (ev) => {
    if (!wasFormSubmitted) setWasFormSubmitted(true);
    ev.target
      ?.closest('form')
      ?.querySelector('input:invalid')
      ?.closest('.input-row')
      ?.scrollIntoView();
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setFormErrors([]);
    onSubmit(resource);
  };

  const value = useMemo(() => ({
    formErrors,
    resource,
    resourceSchema,
    setProperty,
  }));

  return (
    <RedwallFormContext.Provider value={value}>
      <form onInvalid={handleInvalid} onSubmit={handleSubmit} {...rest}>
        {children}
      </form>
    </RedwallFormContext.Provider>
  );
}
