import { sortBy } from 'lodash';
import './PolicyLayers.scss';
import { useUser } from '../../../context/UserContext.jsx';
import { useDashboardState } from '../../../context/DashboardContext.jsx';
import { toggle } from '../../../helpers/helpers.js';

export function PolicyLayers(props) {
  const { currentUser } = useUser();
  const [selectedLayers, setSelectedLayers] =
    useDashboardState('selectedLayers');
  const selectedPolicyLayers = selectedLayers.policy;
  const togglePolicyLayer = (layerId) =>
    setSelectedLayers({
      ...selectedLayers,
      policy: toggle(selectedPolicyLayers, layerId),
    });

  const sortedOrgs = sortBy(
    currentUser.organizations.filter((org) => org.policyLayers.length > 0),
    (org) => org.displayName,
  );

  return (
    <div className="policy-layers">
      {sortedOrgs.map((org) => {
        const layers = sortBy(
          org.policyLayers.filter(
            (layer) => !layer.perilType && layer.layerId !== 'zipcodes',
          ),
          (l) => l.layerId,
        );

        return (
          <div key={org.displayName}>
            <h1>{org.displayName}</h1>
            <ul className="standard">
              {layers.map((layer) => (
                <li key={layer.layerId}>
                  <button
                    className="standard layer-row input-row"
                    onClick={() => togglePolicyLayer(layer.layerId)}
                    type="button"
                  >
                    <div className="layer-name">{layer.layerId}</div>
                    <input
                      className="layer-checkbox"
                      style={{
                        accentColor: layer.mapboxLayer.paint['circle-color'],
                      }}
                      type="checkbox"
                      tabIndex={-1}
                      checked={selectedPolicyLayers.includes(layer.layerId)}
                      readOnly
                    />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
