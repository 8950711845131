import { useMatches } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './PublicLayers.scss';
import { PUBLIC_LAYERS_BY_PERIL_TYPE } from '../../map/public-layers.js';
import { toggle } from '../../../helpers/helpers.js';
import { useDashboardState } from '../../../context/DashboardContext.jsx';

export function PublicLayers() {
  const matches = useMatches();
  const [selectedLayers, setSelectedLayers] =
    useDashboardState('selectedLayers');
  const selectedPublicLayers = selectedLayers.public;
  const togglePublicLayer = (layerId) =>
    setSelectedLayers({
      ...selectedLayers,
      public: toggle(selectedPublicLayers, layerId),
    });
  const perilType =
    matches?.find((match) => match?.handle?.perilType)?.handle?.perilType ?? '';
  const publicPerilLayers = PUBLIC_LAYERS_BY_PERIL_TYPE[perilType];

  return (
    <ul className="standard public-layers">
      {Object.keys(publicPerilLayers).map((layerId) => (
        <li key={layerId}>
          <button
            className="standard layer-row input-row"
            onClick={() => {
              togglePublicLayer(layerId);
            }}
            type="button"
          >
            <div className="layer-name">
              <FormattedMessage id={`map_layer.${layerId}`} />
            </div>
            <input
              className="layer-checkbox"
              type="checkbox"
              tabIndex={-1}
              checked={selectedPublicLayers.includes(layerId)}
              readOnly
            />
          </button>
        </li>
      ))}
    </ul>
  );
}
