import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { dashboardSchema } from '../../../../../stores/store.js';
import { queryLoader } from '../../../../../hooks/use-query.js';
import { useUser } from '../../../../../context/UserContext.jsx';
import {
  RedwallCheckboxRow,
  RedwallForm,
  RedwallInputRow,
  RedwallPolygonCollectorRow,
  RedwallRadioGroupRow,
  RedwallTextareaRow,
} from '../../../../shared/RedwallForm.jsx';
import './FireRoc.scss';

const baseQuery = {
  type: 'fires',
  properties: {
    perimeter: 'perimeter',
    incidentName: 'incidentName',
    acreage: 'acreage',
    potentialAcreage: 'potentialAcreage',
    containment: 'containment',
    rateOfSpread: 'rateOfSpread',
    residencesDestroyed: 'residencesDestroyed',
    residencesThreatened: 'residencesThreatened',
    otherStructuresDestroyed: 'otherStructuresDestroyed',
    otherStructuresThreatened: 'otherStructuresThreatened',
    evacuationStatus: 'evacuationStatus',
    evacuationLink: 'evacuationLink',
    evacuationComments: 'evacuationComments',
    newsLink: 'newsLink',
    incidentLink: 'incidentLink',
    radioLink: 'radioLink',
    webcamLink: 'webcamLink',
    xLink: 'xLink',
    prescribed: 'prescribed',
    analystCommentary: 'analystCommentary',
  },
};

export async function loader({ params }) {
  const query = { ...baseQuery, id: params.perilId };
  return queryLoader(query);
}

export function FireRoc() {
  const { hasPermission } = useUser();
  const navigate = useNavigate();
  const initData = useLoaderData();
  const [roc, setRoc] = useState(initData);

  useEffect(() => {
    if (!hasPermission('rz:alertdashboard:fires:update')) {
      navigate('/dashboard', { replace: true });
    }
  });

  return (
    <div className="component fire-roc scroll">
      <RedwallForm
        resource={roc}
        setResource={setRoc}
        resourceSchema={dashboardSchema.resources.fires}
      >
        <fieldset>
          <legend>Location</legend>
          <RedwallPolygonCollectorRow property="perimeter" />
        </fieldset>

        <fieldset>
          <legend>Fire Identification</legend>
          <RedwallInputRow property="incidentName" required />
        </fieldset>

        <fieldset>
          <legend>Acreage</legend>
          <RedwallInputRow property="acreage" />
          <RedwallInputRow property="potentialAcreage" />
        </fieldset>

        <fieldset>
          <legend>Containment</legend>
          <RedwallInputRow property="containment" />
          <RedwallRadioGroupRow property="rateOfSpread" />
        </fieldset>

        <fieldset>
          <legend>Structures</legend>
          <RedwallInputRow property="residencesDestroyed" />
          <RedwallInputRow property="residencesThreatened" />
          <RedwallInputRow property="otherStructuresDestroyed" />
          <RedwallInputRow property="otherStructuresThreatened" />
        </fieldset>

        <fieldset>
          <legend>Evacuations</legend>
          <RedwallRadioGroupRow property="evacuationStatus" />
          <RedwallInputRow property="evacuationLink" />
          <RedwallTextareaRow property="evacuationComments" />
        </fieldset>

        <fieldset>
          <legend>Links</legend>
          <RedwallInputRow property="newsLink" />
          <RedwallInputRow property="incidentLink" />
          <RedwallInputRow property="radioLink" />
          <RedwallInputRow property="webcamLink" />
          <RedwallInputRow property="xLink" />
        </fieldset>

        <fieldset>
          <legend>Miscellaneous</legend>
          <RedwallCheckboxRow property="prescribed" />
          <RedwallTextareaRow property="analystCommentary" />
        </fieldset>

        <div className="input-row submit-container">
          <input type="submit" value="Submit ROC" />
        </div>
      </RedwallForm>
    </div>
  );
}
