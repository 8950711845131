import { useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import { useMap } from '../contexts/MapContext.jsx';

export function useMapEffect(fn, cleanup, dependencies, rerunOnStyleLoad) {
  const { onMapLoad, offMapLoad } = useMap();
  const [cleanupFn, deps, rerun] = Array.isArray(cleanup)
    ? [() => {}, cleanup, rerunOnStyleLoad]
    : [cleanup, dependencies, rerunOnStyleLoad];

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    if (rerun) fn.rerunOnStyleLoad = true;

    onMapLoad(fn);

    return () => {
      offMapLoad(fn, cleanupFn ?? (() => {}));
    };
  }, deps);
}
