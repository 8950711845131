export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || '';
export const BASE_LAYER_IMAGE_URL = 'https://api.mapbox.com/styles/v1/mapbox';
export const BASE_LAYER_URL = 'mapbox://styles/mapbox';

/*
 * a gallery of different standard layers which can be selected from for the primary map view
 * @returns {*}
 * @constructor
 */
export const AVAILABLE_BASE_LAYERS = {
  standard: {
    name: 'layers.standard',
    layer: 'streets-v11',
  },
  satellite: {
    name: 'layers.satellite',
    layer: 'satellite-v9',
  },
  hybrid: {
    name: 'layers.hybrid',
    layer: 'satellite-streets-v11',
  },
  light: {
    name: 'layers.light',
    layer: 'light-v10',
  },
};
