export function sortByAll(items, fns) {
  const copy = [...items];
  const fnsLen = fns.length;

  const sortFn = (left, right) => {
    for (let i = 0; i < fnsLen; i += 1) {
      const [leftVal, rightVal] = [fns[i](left), fns[i](right)];

      if (leftVal < rightVal) return -1;
      if (leftVal > rightVal) return 1;
    }

    return 0;
  };

  copy.sort(sortFn);
  return copy;
}
