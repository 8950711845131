export function EnvelopeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      className="standard"
    >
      <path d="M30.5 29h-29a.5.5 0 0 1-.5-.5v-16a.5.5 0 0 1 1 0V28h28V8.5a.5.5 0 0 1 1 0v20a.5.5 0 0 1-.5.5z" />
      <path d="M1.5 27a.498.498 0 0 1-.301-.898l8.884-6.74a.5.5 0 0 1 .603.797l-8.884 6.74A.501.501 0 0 1 1.5 27zm29 0a.496.496 0 0 1-.302-.102l-8.935-6.779a.499.499 0 1 1 .603-.797l8.935 6.779A.499.499 0 0 1 30.5 27zM16 21.253a2.915 2.915 0 0 1-1.859-.643L2.723 11.16C1.757 10.362 1 8.974 1 8V6.5a.5.5 0 0 1 .5-.5h29a.5.5 0 0 1 .5.5V8c0 .974-.757 2.362-1.723 3.161l-11.418 9.448a2.91 2.91 0 0 1-1.859.644zM2 7v1c0 .675.636 1.791 1.359 2.39l11.418 9.45c.645.531 1.801.532 2.445-.001l11.418-9.448C29.364 9.791 30 8.675 30 8V7H2z" />
      <path d="M16 21.253a2.915 2.915 0 0 1-1.859-.643L2.723 11.16C1.757 10.362 1 8.974 1 8V6.5a.5.5 0 0 1 .5-.5h29a.5.5 0 0 1 .5.5V8c0 .974-.757 2.362-1.723 3.161l-11.418 9.448a2.91 2.91 0 0 1-1.859.644zM2 7v1c0 .675.636 1.791 1.359 2.39l11.418 9.45c.645.531 1.801.532 2.445-.001l11.418-9.448C29.364 9.791 30 8.675 30 8V7H2z" />
    </svg>
  );
}
