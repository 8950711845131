export function filterObj(obj, filterFn) {
  const out = {};
  Object.entries(obj).forEach(([k, v]) => {
    if (filterFn(v, k)) {
      out[k] = v;
    }
  });

  return out;
}
