import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { useMap } from '../contexts/MapContext.jsx';
import { MAPBOX_TOKEN } from '../constants/map-constants.js';
import './Map.scss';

const defaultOptions = {
  accessToken: MAPBOX_TOKEN,
  attributionControl: false,
  center: [-116, 40],
  container: 'map',
  preserveDrawingBuffer: true,
  style: 'mapbox://styles/mapbox/streets-v12',
  zoom: 4.5,
};

export function Map(props) {
  const { map, setMap } = useMap();

  const options = { ...defaultOptions, ...props };

  useEffect(() => {
    if (!map) {
      mapboxgl.accessToken = options.accessToken;
      const mapObj = new mapboxgl.Map(options);
      setMap(mapObj);
    }
  }, []);

  return <div id="map" className="map" />;
}
