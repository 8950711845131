/*
  Relies on a div with id="portal-root" in the index.html file
*/

import { useEffect } from 'react';
import { createPortal } from 'react-dom';

export function Portal({ theme, children }) {
  const mount = document.getElementById('portal-root');

  const el = document.createElement('div');
  el.classList.add(theme ?? 'light-theme');

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(children, el);
}
