import React, { useState } from 'react';
import uuid from 'react-uuid';
import '../layer-drawer/ImportedLayers.scss';
import './AddSource.scss';
import { SourcesForm } from './SourcesForm.jsx';

const sourceObj = {
  type: 'Feature',
  properties: '', // fill with form data
  geometry: {
    type: 'Point',
    coordinates: '', // fill with pin data
  },
};

const initialFormValues = {
  id: uuid(),
  name: '',
  url: '',
  altUrl: '',
  region: '',
  state: '',
  county: '',
  type: 'link', // default to link
  notes: '',
};

export function AddSource(props) {
  const { sources, setSources, setSelectedTab } = props;
  const [formValues, setFormValues] = useState(initialFormValues);

  const [pin, setPin] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setFormValues(initialFormValues);
    setPin(null);
    setError(false);
    setLoading(false);
    setSelectedTab('trusted');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    const preppedSourceObj = {
      ...sourceObj,
      properties: { ...formValues },
      geometry: {
        ...sourceObj.geometry,
        coordinates: pin?.features[0]?.geometry?.coordinates || [],
      },
    };

    setError(false);
    setLoading(true);

    // fake loading for now
    setTimeout(() => {
      setLoading(false);
      setSources({
        ...sources,
        data: {
          ...sources.data,
          features: [...sources.data.features, preppedSourceObj],
        },
      }); // uses updateSources from SourcesDrawer.jsx
      setSelectedTab('trusted');
    }, 2000);
  };

  return (
    <SourcesForm
      formValues={formValues}
      setFormValues={setFormValues}
      pin={pin}
      setPin={setPin}
      error={error}
      loading={loading}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
}
