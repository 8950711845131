export function ExpandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 54 54"
      xmlSpace="preserve"
      className="standard"
    >
      <g>
        <path d="M0,0v54h54V0H0z M52,52H2V2h50V52z" />
        <path d="M7,8.414l15.293,15.293C22.488,23.902,22.744,24,23,24s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L8.414,7   h11.729V5H5v15.143h2V8.414z" />
        <path d="M45.586,47H33.857v2H49V33.857h-2v11.729L31.707,30.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L45.586,47z   " />
      </g>
    </svg>
  );
}
