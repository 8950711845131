import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { router } from './components/app/Router.jsx';
import 'normalize.css';

const root =
  process.env.REACT_APP_USE_LOCAL_DATA === 'true' ? (
    <RouterProvider router={router} />
  ) : (
    <Auth0Provider
      domain={process.env.REACT_APP_API_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_API_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
      }}
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  );

ReactDOM.createRoot(document.getElementById('root')).render(root);
