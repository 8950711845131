import React, { useState } from 'react';
import axios from 'axios';
import { Spinner } from '../../icons/Spinner.jsx';
import { toggle } from '../../../helpers/helpers.js';
import './ImportedLayers.scss';
import { useDashboardState } from '../../../context/DashboardContext.jsx';
import { getColorFromString } from '../../../../scripts/helpers/field-helpers.mjs';

// this should probably be moved when multiple import modes become available
const importArcGisFeatureLayer = async (rawUrl) => {
  const url = new URL(`${rawUrl}/query`);
  url.searchParams.set('where', '1=1');
  url.searchParams.set('outSR', '4326');
  url.searchParams.set('f', 'geojson');
  url.searchParams.set('outFields', '*');

  const { data } = await axios.get(url);
  return data;
};

// Query Feature Service: https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/SPC_firewx/MapServer/query?f=json
// Query Feature Layer: https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/SPC_firewx/MapServer/1/query?where=1%3D1&outSR=4326&f=geojson&returnGeometry=true

export function ImportedLayers() {
  const [selectedLayers, setSelectedLayers] =
    useDashboardState('selectedLayers');
  const [importedLayers, setImportedLayers] =
    useDashboardState('importedLayers');
  const selectedImportedLayers = selectedLayers.imported;
  const toggleImportedLayer = (layerId) =>
    setSelectedLayers({
      ...selectedLayers,
      imported: toggle(selectedImportedLayers, layerId),
    });

  const [layerName, setLayerName] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importArgs, setImportArgs] = useState({
    color: getColorFromString(Date()),
  });

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setError(false);
    setLoading(true);

    try {
      const { url } = importArgs;
      const importedSource = await importArcGisFeatureLayer(url);

      setImportedLayers([
        ...importedLayers,
        {
          source: 'arcgis',
          args: importArgs,
          value: importedSource,
          id: layerName,
          name: layerName,
          selected: true,
        },
      ]);
      setSelectedLayers({
        ...selectedLayers,
        imported: [...selectedImportedLayers, layerName],
      });
      setLayerName('');
      setImportArgs({ color: getColorFromString(Date()) });
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="imported-layers drawer-body">
      <div className="existing-imported-layers">
        {importedLayers.map((layer) => (
          <button
            key={layer.id}
            className="standard layer-row"
            onClick={() => {
              toggleImportedLayer(layer.id);
            }}
            type="button"
          >
            <div className="layer-name">{layer.name}</div>
            <input
              className="layer-checkbox"
              type="checkbox"
              tabIndex={-1}
              checked={selectedImportedLayers.includes(layer.id)}
              readOnly
            />
          </button>
        ))}
      </div>

      <div className="new-import">
        <h2>Import New Source Layer</h2>
        <div className="input-group">
          <label htmlFor="import-layer-url">Import Method</label>
          <div className="input-row">
            <select value="arcgis" onChange={() => {}}>
              <option key="arcgis" value="arcgis">
                ArcGIS
              </option>
            </select>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="import-imported-layer-name">Layer Name</label>
            <input
              type="text"
              required
              value={layerName}
              onChange={(e) => setLayerName(e.target.value)}
            />
          </div>

          <div className="input-group">
            <label htmlFor="import-arg-url">ArcGIS Feature Layer URL</label>
            <input
              id="import-arg-url"
              type="text"
              required
              onChange={(ev) =>
                setImportArgs({ ...importArgs, url: ev.target.value })
              }
              value={importArgs.url ?? ''}
            />
          </div>

          <div className="input-group">
            <label htmlFor="import-arg-url">Feature Color</label>
            <input
              id="import-arg-url"
              type="color"
              required
              onChange={(ev) =>
                setImportArgs({ ...importArgs, color: ev.target.value })
              }
              value={importArgs.color ?? ''}
            />
          </div>

          <div className="input-group">
            <div className="input-row">
              <button type="submit">
                {loading ? (
                  <span className="imported-layer-loading">
                    <Spinner />
                  </span>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      {error && (
        <div className="imported-layer-error">
          Sorry, something went wrong and we can not load this layer
        </div>
      )}
    </div>
  );
}
